import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsSpecialDatesFormComponent } from '../settings-special-dates-form/settings-special-dates-form.component';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-settings-special-dates',
  templateUrl: './settings-special-dates.component.html',
  styleUrls: ['./settings-special-dates.component.scss']
})
export class SettingsSpecialDatesComponent implements OnInit {
  items;

  constructor(
    private modalService: NgbModal,
    private app: AppService,
    private api: ApiConnectionService
  ) { }

  ngOnInit(): void {
    let self = this;
    self.all();
  }

  all ()
  {
    let self = this;

    this.api.read('specialDates').then((response) => {		
        self.items = response;
    });
  }
  
  confirmDelete(projectHour) {
    let self = this;

    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message = 'Weet je zeker dat je dit element wilt verwijderen?';
    deleteModal.result.then((result) => {
    if (result) {
      this.delete(projectHour);
    }
    });
  }
  
  delete (projectHour) {
    let self = this;
    self.app.setLoaderStatus(true);	
    this.api.delete('specialDates/delete/'+projectHour.id+'').then((response) => {
      self.all();
      self.app.setLoaderStatus(false);			
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageText('Medewerker verwijderd');
			self.app.setSystemMessageType('DANGER');
    }).catch (function (error) {
      self.app.setLoaderStatus(false);	
    });	
  }

  form (object)
  {
	  let self = this;
	
	  const filterModal = this.modalService.open(SettingsSpecialDatesFormComponent, {
      windowClass: 'modal-right',
      backdrop: 'static'
	  });
	  
	  filterModal.componentInstance.object = object;
	  filterModal.result.then((result) => {
      self.all();
	  })
	  .catch((error) => {
      self.all();
	  });
  }

}
