<section class="system-message message-loader animate__animated"
	[ngClass]="{'animate__slideInDown': loader, 'animate__slideOutUp': !loader}">
	<div class="message-container"><i class="bi bi-arrow-repeat rotating"></i> Bezig met laden</div>
</section>
<section class="system-message animate__animated" [ngClass]="{
		'animate__slideInDown': systemMessageLoader, 

		'message-success': systemMessageType == 'SUCCESS', 
		'message-danger': systemMessageType == 'DANGER', 
		
		'animate__slideOutUp': !systemMessageLoader
	}">
	<div class="message-container">
		<i class="bi bi-check-lg " *ngIf="systemMessageType == 'SUCCESS'"
			[ngClass]="{'animate__bounce': systemMessageLoader, 'animate__animated': !systemMessageLoader}"></i>
		<i class="bi bi-exclamation-triangle " *ngIf="systemMessageType == 'DANGER'"
			[ngClass]="{'animate__bounce': systemMessageLoader, 'animate__animated': !systemMessageLoader}"></i>
		{{systemMessageText}}
	</div>
</section>

<section class="app bg-light" *ngIf="currentUser" [ngClass]="{
	'role-sitemanager': currentUser['role'] == 'MANAGER' || currentUser['role'] == 'USER',
	'role-user': currentUser['role'] == 'USER'
}">
	<div class="row m-0" style="height: 100%;">
		<div class="col-md-2 col-12 bg-white sticky-mobile-nav">
			<div class="navbar-mobile d-block d-lg-none">
				<div class="mobile-header">
					<h1 class="logo">
						<a href="/" class="navbar-brand font-weight-bold">
							<img src="/assets/images/logo-dark.png" alt="" *ngIf="darkMode">
							<img src="/assets/images/logo.jpg" alt="" *ngIf="!darkMode">
						</a>
					</h1>
					<i (click)="showMobileMenu()" id="hamburger" class="bi bi-list"></i>
				</div>
				<!-- <div class="mobile-menu" [ngClass]="mobileMenuVisible ? '' : 'd-none'">
					<div class="top-bar">
						<h1 class="logo">
							<a href="/" class="navbar-brand font-weight-bold">
								<img src="/assets/images/logo-dark.png" alt="" *ngIf="darkMode">
								<img src="/assets/images/logo.jpg" alt="" *ngIf="!darkMode">
							</a>
							<span class="page-title text-muted">Dashboard</span>
						</h1>
						<button class="close btn" (click)="closeMobileMenu()">
							<span></span><span></span>
						</button>
					</div>
					<ul class="main-navigation" (click)="closeMobileMenu()">
						<li class="navigation-item" [ngClass]="navKey == 'dashboard' ? 'active' : '' ">
							<span routerLink="/index">
								<i class="bi bi-house-door"></i> Dashboard
							</span>
						</li>
						<li class="navigation-item only-for-admin" [ngClass]="navKey == 'quotes' ? 'active' : '' ">
							<span routerLink="/quotes">
								<i class="bi bi-file-earmark-text"></i> Offertes
							</span>
						</li>
						<li class="navigation-item" [ngClass]="navKey == 'projects' ? 'active' : '' ">
							<span routerLink="/projects">
								<i class="bi bi-text-left"></i> Projecten
							</span>
						</li>
						<li class="navigation-item visible-for-manager" *ngIf="currentUser['role'] != 'USER'"
							[ngClass]="navKey == 'calendar' ? 'active' : '' ">
							<span routerLink="/calendar">
								<i class="bi bi-calendar-check"></i> Planning
							</span>
						</li>
						<li class="navigation-item only-for-admin"
							[ngClass]="navKey == 'organisations' ? 'active' : '' ">
							<span routerLink="/organisations">
								<i class="bi bi-people"></i> Klanten
							</span>
						</li>
						<li class="navigation-item" [ngClass]="navKey == 'users' ? 'active' : '' ">
							<span routerLink="/users">
								<i class="bi bi-person"></i> Medewerkers
							</span>
						</li>
						<li class="navigation-item visible-for-manager" [ngClass]="navKey == 'hours' ? 'active' : '' ">
							<span routerLink="/hours">
								<i class="bi bi-alarm"></i> Urenregistratie
							</span>
						</li>
						<li class="navigation-item only-for-admin" [ngClass]="navKey == 'archive' ? 'active' : '' ">
							<span routerLink="/archive">
								<i class="bi bi-archive"></i> Archief
							</span>
						</li>
						<li class="navigation-item" [ngClass]="navKey == 'map' ? 'active' : '' ">
							<span routerLink="/map">
								<i class="bi bi-map"></i> Kaart
							</span>
						</li>
						<li class="navigation-item only-for-admin" [ngClass]="navKey == 'settings' ? 'active' : '' ">
							<span routerLink="/settings">
								<i class="bi bi-gear"></i> Instellingen
							</span>
						</li>
						<li class="navigation-item" [ngClass]="navKey == 'my-profile' ? 'active' : '' ">
							<span routerLink="/my-profile">
								<i class="bi bi-person"></i> Mijn profiel
							</span>
						</li>
						<li class="navigation-item" *ngIf="currentUser && currentUser['name']">
							<span (click)="logout()">
								<i class="bi bi-door-closed"></i> Uitloggen
							</span>
						</li>
					</ul>
				</div> -->
			</div>


			<div class="navbar navbar-light d-none d-lg-block">
				<h1 class="logo">
					<a href="/" class="navbar-brand font-weight-bold">
						<img src="/assets/images/logo-dark.webp" alt="" *ngIf="darkMode">
						<img src="/assets/images/logo.jpg" alt="" *ngIf="!darkMode">
					</a>
					<span class="page-title text-muted">Dashboard</span>
				</h1>
				<ul class="main-navigation">
					<li class="navigation-item" [ngClass]="navKey == 'dashboard' ? 'active' : '' ">
						<span routerLink="/index">
							<i class="bi bi-house-door"></i> Dashboard
						</span>
					</li>
					<li class="navigation-item only-for-admin" [ngClass]="navKey == 'quotes' ? 'active' : '' ">
						<span routerLink="/quotes">
							<i class="bi bi-file-earmark-text"></i> Offertes
						</span>
					</li>
					<li class="navigation-item" [ngClass]="navKey == 'projects' ? 'active' : '' ">
						<span routerLink="/projects">
							<i class="bi bi-text-left"></i> Projecten
						</span>
					</li>
					<li class="navigation-item visible-for-manager" [ngClass]="navKey == 'calendar' ? 'active' : '' ">
						<span routerLink="/calendar">
							<i class="bi bi-calendar-check"></i> Planning
						</span>
					</li>
					<li class="navigation-item only-for-admin" [ngClass]="navKey == 'organisations' ? 'active' : '' ">
						<span routerLink="/organisations">
							<i class="bi bi-people"></i> Klanten
						</span>
					</li>
					<li class="navigation-item visible-for-manager" [ngClass]="navKey == 'users' ? 'active' : '' ">
						<span routerLink="/users">
							<i class="bi bi-person"></i> Medewerkers
						</span>
					</li>
					<li class="navigation-item visible-for-manager" [ngClass]="navKey == 'hours' ? 'active' : '' ">
						<span routerLink="/hours">
							<i class="bi bi-alarm"></i> Urenregistratie
						</span>
					</li>
					<li class="navigation-item only-for-admin" [ngClass]="navKey == 'archive' ? 'active' : '' ">
						<span routerLink="/archive">
							<i class="bi bi-archive"></i> Archief
						</span>
					</li>
					<li class="navigation-item" [ngClass]="navKey == 'map' ? 'active' : '' ">
						<span routerLink="/map">
							<i class="bi bi-map"></i> Kaart
						</span>
					</li>
					<li class="navigation-item only-for-admin" [ngClass]="navKey == 'settings' ? 'active' : '' ">
						<span routerLink="/settings">
							<i class="bi bi-gear"></i> Instellingen
						</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-12 col-md-10 ps-md-4 pe-md-4 marginTopMobile" *ngIf="currentUser && currentUser['name']">
			<div class="d-block d-md-none"><br /></div>
			<header class="container-fluid pt-5 pb-5 d-none d-md-block ">
				<div class="row">
					<div class="col-8" style="position: relative;">
						<input type="search" name="search" (input)="doSearch($event)" placeholder="Zoeken ... "
							id="global-search" class="form-control" [(ngModel)]="managerDDKeyword">
						<div class="auto-dropdown" *ngIf="managerDDVisible == true">
							<div class="options">
								<div class="option" *ngFor="let item of searchResult" (click)="clearSearch(item['id'])">
									{{item['name']}}
									<br />
									<small class="text-muted">{{item['number']}} | {{item['siteManagerName']}}</small>
								</div>
								<div class="option" *ngIf="searchResult.length == 0">
									Geen resultaten..
								</div>
							</div>
						</div>
					</div>
					<div class="col-4 text-end d-flex align-items-center justify-content-end">
						<button class="btn btn-darkmode btn-sm btn-notifications btn-icon" (click)="switchDarkMode()"
							type="button">
							<i class="bi bi-moon-fill" *ngIf="!darkMode"></i>
							<i class="bi bi-sun-fill" *ngIf="darkMode"></i>

						</button>
						<span class="username d-inline-block">
							{{currentUser['name']}}
						</span>
						<div class="userbadge">
							<button class="btn profile-button" type="button" (click)="toggleDropdown()">
								{{currentUser['letter']}}
							</button>
							<ul class="dropdown-menu" [ngClass]="{'show': profileDropdown === true}">
								<li *ngIf="currentUser['role'] == 'ADMIN' || currentUser['role'] == 'MANAGER'"><a
										href="javascript:;" (click)="userCategories()"
										class="dropdown-item">Categorie&euml;n instellen</a></li>
								<li><a class="dropdown-item" routerLink="/my-profile" href="#">Mijn profiel</a></li>
								<li><a (click)="logout()" class="dropdown-item" href="#">Uitloggen</a></li>
							</ul>
						</div>
					</div>
				</div>
			</header>
			<div class="container-fluid" [ngClass]="{
				'role-sitemanager': currentUser['role'] == 'MANAGER'
			}" *ngIf="currentUser && currentUser['name']">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
	<br />
	<br />
	<br />
	<br />
	<br />
	<br />
	<br />
	<br />
</section>
<section class="login" *ngIf="!currentUser">
	<router-outlet></router-outlet>
</section>
<div *ngIf="checkLogin"
	style="position:fixed;top:0;left:0;z-index: 3000;background:#FFFFFF;display:flex;width:100%;height: 100%;flex-direction:column;justify-content: center;align-items: center;">
	<img style="width:140px;" src="assets/images/loader.gif">
</div>

<!-- <section class="bottom-navigation d-flex d-sm-block d-md-none" *ngIf="currentUser">
	<span routerLink="/">
		<i class="bi bi-house-door"></i> Dashboard
	</span>
	<span routerLink="/projects">
		<i class="bi bi-text-left"></i> Projecten
	</span>
	<span routerLink="/my-profile">
		<i class="bi bi-person-fill"></i> Profiel
	</span>
	<span routerLink="/" (click)="logout()">
		<i class="bi bi-box-arrow-right"></i> Loguit
	</span>
</section> -->

<section (click)="closeMobileMenu()" class="sidebar-nav d-flex d-sm-block d-md-none" [ngClass]="mobileMenuVisible ? 'show-sidebar' : ''" *ngIf="currentUser">
	<span routerLink="/">
		<i class="bi bi-house-door"></i> Dashboard
	</span>
	<span routerLink="/projects">
		<i class="bi bi-text-left"></i> Projecten
	</span>
	<span routerLink="/my-profile">
		<i class="bi bi-person-fill"></i> Profiel
	</span>
	<span routerLink="/map">
		<i class="bi bi-map"></i> Kaart
	</span>
	<span class="logout" routerLink="/" (click)="logout()">
		<i class="bi bi-box-arrow-right"></i> Uitloggen
	</span>
</section>