import { Component, OnInit, Input, Output } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "src/app/services/app.service";
import { ApiConnectionService } from "src/app/services/core/api-connection.service";

@Component({
  selector: "app-calculcation-template-list",
  templateUrl: "./calculcation-template-list.component.html",
  styleUrls: ["./calculcation-template-list.component.scss"],
})
export class CalculcationTemplateListComponent implements OnInit {
  @Input() public title;
  @Input() public project;
  @Input() public button;
  @Input() public message;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  loaderOverlay = false;
  items = [];

  constructor(
    public activeModal: NgbActiveModal,
    public appService: AppService,
    public api: ApiConnectionService
  ) {}

  ngOnInit(): void {
    this.all();
  }

  all() {
    let self = this;
    this.api.read("calculationTemplates").then(function (response) {
      self.items = response["items"];
    });
  }

  confirm() {}

  select(templateId) {
    let self = this;

    self.loaderOverlay = true;

    self.appService.setLoaderStatus(true);
	
    return this.api
      .save("projects/applyCalculationTemplate", {
        projectId: self.project["id"],
        templateId: templateId,
      })
      .then((response) => {
        self.loaderOverlay = false;

        self.appService.setSystemMessageLoading(true);
        self.appService.setSystemMessageText("DEFAULT_SAVED");
        self.appService.setSystemMessageType("SUCCESS");
        self.appService.setLoaderStatus(false);
        self.loaderOverlay = false;

        self.activeModal.close(true);
      })
      .catch(function (response) {
        self.appService.setSystemMessageLoading(true);
        self.appService.setSystemMessageText("DEFAULT_ERROR");
        self.appService.setSystemMessageType("DANGER");
        self.appService.setLoaderStatus(false);
        self.loaderOverlay = false;

        self.activeModal.close(true);
      });
  }

  cancel() {
    this.activeModal.close(false);
  }

  close() {
    this.activeModal.close(false);
  }
}
