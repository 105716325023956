import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-settings-special-dates-form',
  templateUrl: './settings-special-dates-form.component.html',
  styleUrls: ['./settings-special-dates-form.component.scss']
})
export class SettingsSpecialDatesFormComponent implements OnInit {
  @Input() object = {};
  loaderOverlay = false;

  constructor(
    public activeModal: NgbActiveModal,
    public appService: AppService,
    public api: ApiConnectionService
  ) {

  }

  ngOnInit(): void {
  }

	confirm () {
		let self = this;

		self.loaderOverlay = true;

		self.appService.setLoaderStatus(true);

  
		return this.api.save('specialDates/save', {
      id: self.object['id'],
      description: self.object['description'],
      startAt: self.object['startAt'],
      endAt: self.object['endAt'],
    }).then((response) => {
			self.loaderOverlay = false;

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageType('SUCCESS');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

		  self.activeModal.close(true);

		}).catch (function (response) {
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

		  self.activeModal.close(true);
		});
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	close () {
		this.activeModal.close(false);
	}

}
