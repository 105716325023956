import { Component, OnInit, Input, Output, Renderer2 } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "src/app/services/users.service";
import { NgxDropzoneComponent } from "ngx-dropzone";
import { ApiConnectionService } from "src/app/services/core/api-connection.service";
import { OrganisationsFormComponent } from "src/app/pages/organisations-form/organisations-form.component";
import { UserEditModalComponent } from "../../users/user-edit-modal/user-edit-modal.component";
import { ConfigService } from "src/app/services/core/config.service";
import { AppService } from "src/app/services/app.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/core/authentication.service";

@Component({
  selector: "app-project-info-form",
  templateUrl: "./project-info-form.component.html",
  styleUrls: ["./project-info-form.component.scss"],
})
export class ProjectInfoFormComponent implements OnInit {
  @Input() public id;
  @Input() public organisationId;
  @Input() public roleKey;
  @Input() public title;
  @Input() public button;
  @Input() public message;
  @Input() public fullLink = false;
  @Input() public currentTab = "info";
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  preload = false;

  files: File[] = [];
  item;
  errors = {};
  successmessage;
  errormessage;
  changepassword;

  loaderOverlay = true;

  clientDDVisible = false;
  clientDDKeyword = "";

  contactDDVisible = false;
  contactDDKeyword = "";

  contact2DDVisible = false;
  contact2DDKeyword = "";

  contact3DDVisible = false;
  contact3DDKeyword = "";

  managerDDVisible = false;
  managerDDKeyword = "";

  locationDDVisible = false;
  locationDDKeyword = "";

  locationKeyword = "";

  authorDDVisible = false;
  authorDDKeyword = "";

  note = "";

  paidOptions = [
    { name: "Niet betaald", value: "NOT_PAID" },
    { name: "Betaald", value: "PAID" },
  ];

  statusOptions = [];
  permitStatusOptions = [];
  quoteAcceptedOptions = [];
  organisations: any = [];
  clientContacts: any = [];
  users: any = [];
  addresses: any = [];
  userGroups;
  categoryList;
  currentUser;

  constructor(
    public service: UsersService,
    public modalService: NgbModal,
    public app: AppService,
    private router: Router,
    public config: ConfigService,
    public apiService: ApiConnectionService,
    private authenticationService: AuthenticationService,
    public renderer: Renderer2,
    public activeModal: NgbActiveModal
  ) {
    let self = this;
    this.permitStatusOptions = this.config.get("permit_status_enum");
    this.renderer.listen("window", "click", (evt) => {
      if (
        evt.target.closest(".auto-dropdown") == null &&
        !evt.target.classList.contains("dd-input")
      ) {
        this.clientDDVisible = false;
        this.contactDDVisible = false;
        this.contact2DDVisible = false;
        this.contact3DDVisible = false;
        this.managerDDVisible = false;
        this.authorDDVisible = false;
        this.locationDDVisible = false;
      }
    });
    self.authenticationService.getCurrentUser();
    self.authenticationService.data.subscribe(data => {
      if (Object.keys(data).length) {
        self.currentUser = data;
      } else {
        self.currentUser = false;
      }
    });
  }

  ngOnInit(): void {
    let self = this;
    self.app.setLoaderStatus(true);
    setTimeout(function () {
      self.fetchItem();
    }, 100);
    this.getOrganisations();
    this.getUsers();
    this.getUserGroups();
    this.getCategories();
    this.statusOptions = this.config.get("project_status_options");
    this.quoteAcceptedOptions = this.config.get("quote_accepted_options");
  }

  ngOnChanges(changes: any) {
    this.fetchItem();
  }

  fetchItem() {
    let self = this;
    self.item = {};
    this.service.read("projects/details/" + self.id + "").then((response) => {
      self.loaderOverlay = false;
      self.app.setLoaderStatus(false);
      self.preload = true;
      self.item = response;
      self.locationDDKeyword = response["address"];
      if (response["organisation"]) {
        self.clientContacts = response["organisation"]["clientContacts"];
      }
    });
  }

  getUserGroups() {
    let self = this;
    self.item = {};
    this.service.read("userGroups&data[sort]=name").then((response) => {
      self.userGroups = response["items"];
    });
  }

  getCategories() {
    let self = this;
    self.item = {};
    this.service.read("categories/list&data[sort]=name").then((response) => {
      self.categoryList = response;
    });
  }

  searchContacts() {
    let list = [];
    for (let index = 0; index < this.clientContacts.length; ++index) {
      let contact = this.clientContacts[index];
      let string = "" + contact["name"].toLowerCase() + "";
      let result = string.includes(this.contactDDKeyword.toLowerCase());

      if (this.contactDDKeyword == "" || result) {
        list.push(contact);
      }
    }

    return list;
  }

  searchContacts2() {
    let list = [];
    for (let index = 0; index < this.clientContacts.length; ++index) {
      let contact = this.clientContacts[index];
      let string = "" + contact["name"].toLowerCase() + "";
      let result = string.includes(this.contact2DDKeyword.toLowerCase());

      if (this.contact2DDKeyword == "" || result) {
        list.push(contact);
      }
    }

    return list;
  }

  searchContacts3() {
    let list = [];
    for (let index = 0; index < this.clientContacts.length; ++index) {
      let contact = this.clientContacts[index];
      let string = "" + contact["name"].toLowerCase() + "";
      let result = string.includes(this.contact3DDKeyword.toLowerCase());

      if (this.contact3DDKeyword == "" || result) {
        list.push(contact);
      }
    }

    return list;
  }

  searchOrganisations() {
    let list = [];
    for (let index = 0; index < this.organisations.length; ++index) {
      let organisation = this.organisations[index];
      let string = "" + organisation["name"].toLowerCase() + "";
      let result = string.includes(this.clientDDKeyword.toLowerCase());

      if (this.clientDDKeyword == "" || result) {
        list.push(organisation);
      }
    }

    return list;
  }

  getOrganisations() {
    let self = this;
    return this.apiService.read("organisations/clients").then((response) => {
      self.organisations = response["items"];
    });
  }

  getUsers() {
    let self = this;
    this.apiService.read("users").then((response) => {
      self.users = response["items"];
    });
  }

  getAddresses() {
    let self = this;
    const re = / /gi;
    let keyword = encodeURI(self.locationDDKeyword);
    keyword = keyword.replace(re, "+");
    this.apiService
      .read("projects/address/" + encodeURIComponent(keyword) + "")
      .then((response) => {
        self.addresses = response["items"];
      });
  }

  searchUsers() {
    let list = [];
    for (let index = 0; index < this.users.length; ++index) {
      let user = this.users[index];
      let string = "" + user["name"].toLowerCase() + "";
      let result = string.includes(this.managerDDKeyword.toLowerCase());

      if (user['role'] !== 'MANAGER') {
        continue;
      }

      if (this.managerDDKeyword == "" || result) {
        list.push(user);
      }
    }

    return list;
  }

  searchAuthors() {
    let list = [];
    for (let index = 0; index < this.users.length; ++index) {
      let user = this.users[index];
      let string = "" + user["name"].toLowerCase() + "";
      let result = string.includes(this.authorDDKeyword.toLowerCase());

      if (this.authorDDKeyword == "" || result) {
        list.push(user);
      }
    }

    return list;
  }

  showClientDD() {
    this.clientDDVisible = true;
    this.contactDDVisible = false;
    this.contact2DDVisible = false;
    this.contact3DDVisible = false;
    this.managerDDVisible = false;
    this.locationDDVisible = false;
    this.authorDDVisible = false;
    this.clientDDKeyword = "";
  }

  showContactDD() {
    this.contactDDVisible = true;
    this.contact2DDVisible = false;
    this.contact3DDVisible = false;
    this.clientDDVisible = false;
    this.managerDDVisible = false;
    this.locationDDVisible = false;
    this.authorDDVisible = false;
  }

  showContact2DD() {
    this.contactDDVisible = false;
    this.contact2DDVisible = true;
    this.contact3DDVisible = false;
    this.clientDDVisible = false;
    this.managerDDVisible = false;
    this.locationDDVisible = false;
    this.authorDDVisible = false;
  }

  showContact3DD() {
    this.contactDDVisible = false;
    this.contact2DDVisible = false;
    this.contact3DDVisible = true;
    this.clientDDVisible = false;
    this.managerDDVisible = false;
    this.locationDDVisible = false;
    this.authorDDVisible = false;
  }

  showManagerDD() {
    this.managerDDVisible = true;
    this.clientDDVisible = false;
    this.contactDDVisible = false;
    this.contact2DDVisible = false;
    this.contact3DDVisible = false;
    this.locationDDVisible = false;
    this.authorDDVisible = false;
  }

  showAuthorDD() {
    this.managerDDVisible = false;
    this.clientDDVisible = false;
    this.contactDDVisible = false;
    this.contact2DDVisible = false;
    this.contact3DDVisible = false;
    this.locationDDVisible = false;
    this.authorDDVisible = true;
  }

  showLocationDD() {
    this.locationDDVisible = true;
    this.getAddresses();
  }

  setOrganisationId(item) {
    this.item["organisationId"] = item["id"];
    this.item["organisationName"] = item["name"];
    this.clientDDVisible = false;
    this.clientContacts = item["clientContacts"];
  }

  setAddress(item) {
    this.item["street"] = item["street"] + " " + item["number"];
    this.item["zipcode"] = item["zipcode"];
    this.item["city"] = item["city"];

    this.item["long"] = item["long"];
    this.item["lat"] = item["lat"];

    this.item["address"] =
      this.item["street"] + " " + item["zipcode"] + " " + item["city"];

    if (this.item["name"] === "" || !this.item["name"]) {
      this.item["name"] = this.item["street"] + ", " + item["city"];
    }

    this.locationDDVisible = false;
  }

  setClientContact(item) {
    this.item["contactPersonId"] = item["id"];
    this.item["contactPersonName"] = item["name"];
    this.contactDDVisible = false;
  }

  setClientContact2(item) {
    this.item["contactPerson2Id"] = item["id"];
    this.item["contactPerson2Name"] = item["name"];
    this.contact2DDVisible = false;
  }

  setClientContact3(item) {
    this.item["contactPerson3Id"] = item["id"];
    this.item["contactPerson3Name"] = item["name"];
    this.contact3DDVisible = false;
  }

  setUser(item) {
    this.item["siteManagerId"] = item["id"];
    this.item["siteManagerName"] = item["name"];
    this.managerDDVisible = false;
  }

  setAuthor(item) {
    this.item["authorId"] = item["id"];
    this.item["authorName"] = item["name"];
    this.authorDDVisible = false;
  }

  save() {
    let self = this;
    self.errors = [];

    self.loaderOverlay = true;
    self.app.setLoaderStatus(true);
    this.service
      .save("projects/save", self.item)
      .then((response) => {
        self.item = response;
        self.activeModal.close({
          id: self.item["id"],
        });
        self.loaderOverlay = false;

        //check note
        if (self.note !== "") {
          //add note
          self.app.setLoaderStatus(true);
          this.service.save('projectNotes/update/' + self.item["id"] + '', {
            "item": {
              "projectId": self.item['id'],
              "content": self.note
            }
          }).then(function (response) {
            self.app.setLoaderStatus(false);
            self.app.setSystemMessageText("Project opgeslagen");
            self.app.setSystemMessageType("SUCCESS");
            self.app.setSystemMessageLoading(true)
          });
        } else {
          self.app.setLoaderStatus(false);
          self.app.setSystemMessageText("Project opgeslagen");
          self.app.setSystemMessageType("SUCCESS");
          self.app.setSystemMessageLoading(true);
        }
      }).catch(function (response) {
        for (let error in response["error"]["errors"]) {
          self.errors[error] = response["error"]["errors"][error];
        }
        self.loaderOverlay = false;
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageType("DANGER");
        self.app.setSystemMessageText("Er is iets fout gegaan");
        self.app.setSystemMessageLoading(true);
      });
  }

  setTab(tab) {
    this.currentTab = tab;
  }

  checkChangepassword(event) {
    if (this.changepassword === true) {
      this.changepassword = false;
    } else {
      this.changepassword = true;
    }
  }

  dismiss() {
    this.activeModal.close(false);
  }

  close() {
    this.activeModal.close(false);
  }

  cancel() {
    this.activeModal.close(false);
  }

  toProject() {
    this.router.navigate(["/projects/detail/" + this.item["id"] + ""]);
    this.activeModal.close(false);
  }

  openClientForm(data) {
    let self = this;

    sessionStorage.setItem("organisation-tab", "info");

    const filterModal = this.modalService.open(OrganisationsFormComponent, {
      windowClass: "modal-right",
      size: "lg",
    });

    filterModal.componentInstance.id = data["id"];
    filterModal.componentInstance.organisationId = data["organisationId"];
    filterModal.componentInstance.roleKey = data["role"];
    filterModal.result
      .then((result) => {
        if (result !== false) {
          self.setOrganisationId(result);
        }
        self.getOrganisations();
      })
      .catch((error) => { });
  }

  openUserForm(data) {
    let self = this;

    const filterModal = this.modalService.open(UserEditModalComponent, {
      windowClass: "modal-right",
      size: "lg",
    });

    filterModal.componentInstance.id = "";
    filterModal.componentInstance.organisationId =
      "acb1a425-8bdb-11ea-9b3c-002590dfff4c";
    filterModal.componentInstance.roleKey = "MANAGER";
    filterModal.componentInstance.closeAfterSave = true;
    filterModal.result
      .then((result) => {
        self.setUser(result);
        self.getUsers();
      })
      .catch((error) => { });
  }

  openContactForm(data) {
    let self = this;
    const filterModal = this.modalService.open(UserEditModalComponent, {
      windowClass: "modal-right",
      size: "lg",
    });

    filterModal.componentInstance.id = "";
    filterModal.componentInstance.organisationId = self.item["organisationId"];
    filterModal.componentInstance.roleKey = "CONTACT";
    filterModal.componentInstance.closeAfterSave = true;
    filterModal.result
      .then((result) => {
        self.getOrganisations().then(function () {
          self.setOrganisationId({
            id: self["item"]["organisationId"],
            name: self["item"]["organisationName"],
          });
          self.service
            .read(
              "organisations/details/" + self["item"]["organisationId"] + ""
            )
            .then((response) => {
              self.clientContacts = response["clientContacts"];
            });
          self.setClientContact(result);
        });
      })
      .catch((error) => { });
  }

  openContact2Form(data) {
    let self = this;
    const filterModal = this.modalService.open(UserEditModalComponent, {
      windowClass: "modal-right",
      size: "lg",
    });

    filterModal.componentInstance.id = "";
    filterModal.componentInstance.organisationId = self.item["organisationId"];
    filterModal.componentInstance.roleKey = "CONTACT";
    filterModal.componentInstance.closeAfterSave = true;
    filterModal.result
      .then((result) => {
        self.getOrganisations().then(function () {
          self.setOrganisationId({
            id: self["item"]["organisationId"],
            name: self["item"]["organisationName"],
          });
          self.service
            .read(
              "organisations/details/" + self["item"]["organisationId"] + ""
            )
            .then((response) => {
              self.clientContacts = response["clientContacts"];
            });
          self.setClientContact2(result);
        });
      })
      .catch((error) => { });
  }

  openContact3Form(data) {
    let self = this;
    const filterModal = this.modalService.open(UserEditModalComponent, {
      windowClass: "modal-right",
      size: "lg",
    });

    filterModal.componentInstance.id = "";
    filterModal.componentInstance.organisationId = self.item["organisationId"];
    filterModal.componentInstance.roleKey = "CONTACT";
    filterModal.componentInstance.closeAfterSave = true;
    filterModal.result
      .then((result) => {
        self.getOrganisations().then(function () {
          self.setOrganisationId({
            id: self["item"]["organisationId"],
            name: self["item"]["organisationName"],
          });
          self.service
            .read(
              "organisations/details/" + self["item"]["organisationId"] + ""
            )
            .then((response) => {
              self.clientContacts = response["clientContacts"];
            });
          self.setClientContact3(result);
        });
      })
      .catch((error) => { });
  }
}