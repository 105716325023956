<section [ngClass]="{'loader-overlay': loaderOverlay}">
	<div class="modal-header">
		<h5 class="modal-title">
			Categorie&euml;n voor {{currentUser['name']}}
		</h5>
		<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
	</div>
	<div class="modal-body" id="user-form" *ngIf="currentUser">
        <div class="user-group-users pb-3" *ngIf="currentUser['role'] != 'CONTACT' && currentUser['role'] != 'MANAGER'">
            <div class="row">
                <div class="col-12"
                    style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:15px; display: flex; justify-content: space-between;">
                    <small>Categorieën</small>
                </div>
            </div>
            <div class="row" *ngFor="let cat of categories">
                <div class="col-12 option-row" [ngClass]="selectedCategories[cat['id']] ? 'active' : ''"
                    style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
                    <div (click)="toggleCat(cat['id'])" style="cursor:pointer;">
                        <i *ngIf="selectedCategories[cat['id']]" class="bi bi-check-square"
                            style="position:relative;top:2px;margin-right:5px;"></i>
                        <i *ngIf="!selectedCategories[cat['id']]" class="bi bi-square"
                            style="position:relative;top:2px;margin-right:5px;opacity:0.5;"></i>
                        {{cat['name']}}
                    </div>
                </div>
            </div>
        </div>
	</div>
	<div class="modal-footer" *ngIf="currentUser">
		<button (click)="save()" class="btn btn-success">
			<span><i class="bi bi-sd-card pe-2"></i> Opslaan</span>
		</button>
	</div>
</section>