import { Component, OnInit } from '@angular/core';
import { ApiConnectionService } from '../../services/core/api-connection.service';
import { ConfigService } from '../../services/core/config.service';
import { AuthenticationService } from '../../services/core/authentication.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CollectionManagerService } from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UsersService } from '../../services/users.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from '../../modals/modal-content/modal-content.component';
import { AppService } from 'src/app/services/app.service';

import { AddFilterComponent } from '../../components/table-guru/modals/add-filter/add-filter.component';
import { EditFilterComponent } from '../../components/table-guru/modals/edit-filter/edit-filter.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent {

  public apiUrl;
  public backupMxUrl;
  public backupWordpressUrl;

  public canShow = false;
  public currentUser: any;

  public projects = [];
  public costsProjects = [];
  public finishedProjects = [];
  public pendingQuotations = [];
  public invoicedProjects = [];

  public periodProjects = [];
  public noDocuments = [];

  public planProjects = [];
  public inProgressProjects = [];

  public currentWeek = '';
  public currentYear = '';

  public previousWeek = '';
  public previousYear = '';

  public nextWeek = '';
  public nextYear = '';

  public weekOverview1 = [
  ];
  public weekOverview2 = [
  ];

  filterOptions = [];
  filters = [];
  path = '';

  modal;

  categories;
  selectedCategories = {};

  constructor
    (
      public http: HttpClient,
      public authenticationService: AuthenticationService,
      public configService: ConfigService,
      public router: Router,
      public api: ApiConnectionService,
      public collectionManager: CollectionManagerService,
      private appService: AppService,
      private ngxLoader: NgxUiLoaderService,
      private modalService: NgbModal,
      private usersService: UsersService,
    ) {
    let self = this;

    self.authenticationService.getCurrentUser();
    self.authenticationService.data.subscribe(data => {
      if (Object.keys(data).length) {
        self.currentUser = data;
      } else {
        self.currentUser = false;
      }
    });


    self.authenticationService.authState.subscribe(data => {
      if (data === false) {
        self.router.navigate(['login']);
      }
    });
  }

  setCategory(event) {
    const idList = this.currentUser['categoryIds'];

    if (idList.includes(event)) {
      idList.splice(idList.indexOf(event), 1);
    } else {
      idList.push(event);
    }

    if (event != null) {
      this.api.save('users/save', this.currentUser).then((response) => {
        location.reload();
      });
    }
  }

  fetchCategories() {
    let self = this;
    this.api.read('categories/list').then((response) => {
      self.categories = response;
    });
  }

  addFilter() {
    let self = this;

    const filterModal = this.modalService.open(AddFilterComponent);
    filterModal.componentInstance.filterOptions = self.filterOptions;
    filterModal.result.then((result) => {
      if (result && result.length > 0) {
        self.editFilter(result);
      }
    })
      .catch((error) => {
      });
  }

  editFilter(filterKey) {
    let self = this;
    const filterModal = this.modalService.open(EditFilterComponent);
    filterModal.componentInstance.filterOptions = self.filterOptions;
    filterModal.componentInstance.filters = self.filters;
    filterModal.componentInstance.activeFilterKey = filterKey;

    filterModal.result.then((result) => {

      if (result && result['action'] === "remove") {
        this.removeFilter(result['key']);
      }
      else if (result) {
        self.pushFilter(result);
        self.getProjects();
      }
    })
      .catch((error) => {
      });
  }

  removeFilter(key) {
    let self = this;
    if (this.filters) {
      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];
        if (filter['key'] === key) {
          this.filters.splice(i, 1);
        }
      }
    }
    /*
    * TODO: handle in storage function
    */
    if (this.filters.length === 0) {
      let filterStorageItem = localStorage.getItem('filters');
      let filterStorage = {};
      if (filterStorageItem) {
        filterStorage = JSON.parse(filterStorageItem);
      }

      filterStorage[this.path] = {};

      localStorage.setItem('filters', JSON.stringify(filterStorage));
    }
    self.getProjects();
  }

  pushFilter(searchValues) {
    let key = searchValues['key'];
    let values = searchValues['values'];

    if (this.filters) {
      let filterOptions = [];
      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];
        if (filter['key'] === key) {
          this.filters[i] = {
            key: key,
            values: values,
          };

          return true;
        }
      }
    }
    this.filters.push({
      key: key,
      values: values,
    });
    return true;
  }

  ngOnInit(): void {
    let self = this;
    this.appService.setPageTitle('Dashboard');
    this.appService.setNavKey('dashboard');

    let filterStorageItem = localStorage.getItem('filters');
    let filterStorage = {};
    if (filterStorageItem) {
      filterStorage = JSON.parse(filterStorageItem);
    }


    let filterOption;

    let filterOptions = [];
    filterOptions.push('data[filters][status][values]' + 'PLAN||QUOTE_ACCEPTED||IN_PROGRESS');

    if (this.filters && this.filters.length > 0) {
      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i].values) {
          filterOptions.push('data[filters][' + this.filters[i].key + '][values]=' + encodeURIComponent(this.filters[i].values.join('||')) + '');
        }
      }
      filterOption = filterOptions.join('&');
      // url += '&' + filterOption;

      /*
      * TODO: storage function
      */
      filterStorage[this.path] = { filters: filterOption };

      localStorage.setItem('filters', JSON.stringify(filterStorage));
    }
    else if (filterStorage[this.path]) {
      let values = filterStorage[this.path]['filters'];
      filterOption = values;
    }

    this.getQuotes();
    this.getProjects();
    this.getCostsProjects();
    this.getFinishedProjects();
    this.getWeekProjects();
    this.getProjectsInPlan();
    this.getProjectsInProgress();
    this.getNoDocuments();
    this.getPeriodProjects();
    this.getInvoicedProjects();

    this.getFilterOptions();
  }

  getFilterOptions() {
    let filterStorageItem = localStorage.getItem('filters');
    let filterStorage = {};
    if (filterStorageItem) {
      filterStorage = JSON.parse(filterStorageItem);
    }

    let filterOption;

    let filterOptions = [];
    filterOptions.push('data[filters][status][values]' + 'PLAN||QUOTE_ACCEPTED||IN_PROGRESS');

    if (this.filters && this.filters.length > 0) {
      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i].values) {
          filterOptions.push('data[filters][' + this.filters[i].key + '][values]=' + encodeURIComponent(this.filters[i].values.join('||')) + '');
        }
      }
      filterOption = filterOptions.join('&');
      filterStorage[this.path] = { filters: filterOption };

      localStorage.setItem('filters', JSON.stringify(filterStorage));
    }
    else if (filterStorage[this.path]) {
      let values = filterStorage[this.path]['filters'];
      filterOption = values;
    }

    console.log(filterOption);
  }

  getQuotes() {
    let self = this;
    this.api.read('quotes&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=QUOTE_IN_PROGRESS||QUOTE_OPEN').then(function (response) {
      self.pendingQuotations = response['items'];
    });
  }

  getNoDocuments() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=PLAN||IN_PROGRESS&data[filters][isInvalidPlanned][values]=1').then(function (response) {
      self.noDocuments = response['items'];
    });
  }

  getPeriodProjects() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=PERIOD_SEND').then(function (response) {
      self.periodProjects = response['items'];
    });
  }

  getInvoicedProjects() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=INVOICE_SEND').then(function (response) {
      self.invoicedProjects = response['items'];
    });
  }

  getProjects() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=QUOTE_ACCEPTED').then(function (response) {
      self.projects = response['items'];

      self.filterOptions = response['filterOptions'];
      self.filters = response['filters'];
    });
  }

  getProjectsInProgress() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=IN_PROGRESS').then(function (response) {
      self.inProgressProjects = response['items'];
    });
  }

  getProjectsInPlan() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=PLAN').then(function (response) {
      self.planProjects = response['items'];
    });
  }

  getCostsProjects() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=COSTS_SEND').then(function (response) {
      self.costsProjects = response['items'];
    });
  }

  getFinishedProjects() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=READY_FOR_INVOICE').then(function (response) {
      self.finishedProjects = response['items'];
    });
  }

  getWeekProjects() {
    let self = this;
    this.api.read('projects/week/' + self.currentWeek + '/' + self.currentYear + '').then(function (response) {
      self.weekOverview1 = response['week1'];
      self.weekOverview2 = response['week2'];
      self.currentWeek = response['currentWeek'];
      self.currentYear = response['currentYear'];
      self.nextWeek = response['nextWeek'];
      self.nextYear = response['nextYear'];
      self.previousWeek = response['previousWeek'];
      self.previousYear = response['previousYear'];
    });
  }


  public daysPast(dateString) {
    const date = new Date(dateString);

    const milliseconds = Date.now() - date.getTime();

    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

    const past = days > 1 ? "dagen" : "dag";
    return `${Math.abs(days)} ${past} oud`;
  }

  public daysTillStart(dateString) {
    const date = new Date(dateString);

    const milliseconds = Date.now() - date.getTime();

    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

    const past = days < 1 ? "dagen" : "dag";
    return `${Math.abs(days)} ${past} tot uitvoering`;
  }

  showWarning(dateString) {
    const date = new Date(dateString);
    const milliseconds = Date.now() - date.getTime();

    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

    return days > 7;
  }

  dutchDateFormat(dateString) {
    // Split the date string into year, month, and day components
    const [year, month, day] = dateString.split("-");

    // Return the reformatted date string
    return `${day}-${month}-${year}`;
  }

  getDutchWeekday(dateString) {
    const weekdays = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    const date = new Date(dateString);
    const weekdayIndex = date.getDay();
    const dutchWeekday = weekdays[weekdayIndex];
    return dutchWeekday;
  }

  prev() {
    this.currentWeek = this.previousWeek;
    this.currentYear = this.previousYear;
    this.getWeekProjects();
  }

  next() {
    this.currentWeek = this.nextWeek;
    this.currentYear = this.nextYear;
    this.getWeekProjects();
  }
}
