<section class="card">
    <ul class="nav nav-fill mb-4" *ngIf="project">
        <li class="nav-item">
            <span class="nav-link " (click)="setTab('calculation')" [ngClass]="{active: currentTab=='calculation'}"><i class="bi bi-calculator"></i> Calculatie</span>
        </li>
        <li class="nav-item">
            <span class="nav-link" (click)="setTab('files')" [ngClass]="{active: currentTab=='files'}">
                <i class="bi bi-file-earmark-text"></i> 
                Bestand <i *ngIf="project['calculationFileCount'] > 0" style="font-style:normal;">({{project['calculationFileCount']}})</i>
            </span>
        </li>
    </ul>
    <section class="" *ngIf="currentTab == 'calculation'">
        <div class="" *ngIf="currentUser['role'] != 'ADMIN'">
            <div *ngIf="mode == 'PROJECT' && project['calculationFileCount'] > 0" class="pt-5 pb-5 text-center">
                Er zijn bestanden aan deze calculatie gekoppeld. Verwijder deze eerst om een calculatie te kunnen aanmaken.
            </div>
            <div *ngIf="mode == 'TEMPLATE' || project['calculationFileCount'] < 1">
                <section class="table-grid">
                    <section class="table-content" style="overflow:visible;">
                        <table class="table bestekspost mb-0">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Nummer</th>
                                    <th>Omschrijving</th>
                                    <th>Aantal</th>
                                    <th>Eenheid</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="!projectCalculationItems || projectCalculationItems.length < 1" style="border-left:1px solid #DDDDDD">
                                <tr>
                                    <td colspan="10" style="padding:30px 0 50px;text-align:center;">
                                        
                                    </td>
                                </tr>
                            </tbody>
                            <tbody class="table-drag-list" *ngIf="projectCalculationItems && projectCalculationItems.length > 0">
                                <ng-container *ngFor="let row of projectCalculationItems;">
                                    <tr cdkDrag cdkDragPreviewContainer="parent" class="typeData" 
                                        [ngClass]="{
                                                    'd-none': row['type'] != 'RULE' && row['type'] != 'CATEGORY'
                                                    }"
                                        > 
                                        <td width="40">
                                            <input type="text" style="background:#FFFFFF;border:0;width:100%;"  disabled [(ngModel)]="row['number']" *ngIf="row['isCalculation'] == false && row['type'] != 'MULTIPLY'"  value="{{row['number']}}">
                                        </td>

                                        <td>
                                            <span style="display:block;position:relative;">
                                                <input type="text" style="background:#FFFFFF;border:0;width:100%;"  disabled id="calculation-title-{{row['id']}}" [(ngModel)]="row['title']" value="{{row['title']}}">
                                            </span>
                                        </td>
                                        
                                        <td *ngIf="row['isCalculation'] == false" width="80">
                                            <input type="text" style="background:#FFFFFF;border:0;width:100%;"  disabled id="calculation-amount-{{row['id']}}" *ngIf="row['type'] == 'RULE'" [(ngModel)]="row['amount']" class="number-input" value="{{row['amount']}}">
                                        </td>

                                        <td *ngIf="row['isCalculation'] == false" width="100"> 
                                            <span style="display:block;position:relative;">
                                                <input type="text" style="background:#FFFFFF;border:0;width:100%;"  disabled id="calculation-unit-{{row['id']}}" [(ngModel)]="row['unit']" class="form-control" *ngIf="row['type'] == 'RULE'">
                                            
                                            </span>
                                        </td>

                                        <td *ngIf="row['type'] == 'MULTIPLY'" width="100">
                                            <select [(ngModel)]="row['formula']" *ngIf="row['type'] == 'RULE' || row['type'] == 'MULTIPLY'" class="form-control">
                                                <option value="{{itemCategory.value}}" *ngFor="let itemCategory of itemCategories">
                                                    {{itemCategory.name}}
                                                </option>
                                            </select>
                                        </td>

                                        <td *ngIf="row['isCalculation'] == true" colspan="3">
                                            <span class="input-with-icon">
                                                <i class="bi bi-calculator" title="Berekening"></i>
                                                <input type="text" disabled style="background:#FFFFFF;border:0;" [(ngModel)]="row['formula']" class="input-formula" placeholder="Formule, bijv: C1 + C2" *ngIf="row['isCalculation'] == true" (blur)="update(row)" value="{{row['formula']}}" style="width:75%">
                                            </span>                  
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </section>
                </section>
            </div>
        </div>
        
        <div class="" *ngIf="currentUser['role'] == 'ADMIN'">
            
            <div *ngIf="mode == 'PROJECT' && project['calculationFileCount'] > 0" class="pt-5 pb-5 text-center">
                Er zijn bestanden aan deze calculatie gekoppeld. Verwijder deze eerst om een calculatie te kunnen aanmaken.
            </div>
            <div *ngIf="mode == 'TEMPLATE' || project['calculationFileCount'] < 1">
                <div class="dropdown mb-3 d-inline-block" *ngIf="mode == 'PROJECT'">  
                    <button 
                        class="btn btn-dark dropdown-toggle"
                        type="button"  
                        data-bs-toggle="dropdown"
                        (click)="toggleDropdown()"
                        >
                            Acties
                    </button>
                    <ul class="dropdown-menu" [ngClass]="{
                    'show': showDropDown == true
                    }" style="margin-top:0;margin-left:0;">
                        <li class="hide-on-modal"><a class="dropdown-item" (click)="selectTemplate()" href="javascript:;">Selecteer template</a></li>
                    </ul>
                </div>
                <section class="table-grid">
                    <section class="table-content" style="overflow:visible;">
                        <table class="table bestekspost mb-0">
                            <thead class="thead-dark">
                                <tr>
                                    <th></th>
                                    <th>Type</th>
                                    <th>Categorie</th>
                                    <th>Nummer</th>
                                    <th>Omschrijving</th>
                                    <th>Aantal</th>
                                    <th>Eenheid</th>
                                    <th nowrap>Prijs per eenheid</th>
                                    <th>Totaal bedrag</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="!projectCalculationItems || projectCalculationItems.length < 1" style="border-left:1px solid #DDDDDD">
                                <tr>
                                    <td colspan="10" style="padding:30px 0 50px;text-align:center;">
                                        <button (click)="addRow(1,'C1','CATEGORY')" class="btn btn-success with-icon">Toevoegen <i class="bi bi-plus-circle-fill"></i></button> 
                                        <span class="d-inline-block px-3">of</span> 
                                        <span class="d-inline-block mt-3" *ngIf="project">
                                            <button (click)="selectTemplate()" class="btn btn-dark with-icon">Start met template <i class="bi bi-arrow-right"></i></button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody cdkDropList (cdkDropListDropped)="drop($event)" class="table-drag-list" *ngIf="projectCalculationItems && projectCalculationItems.length > 0">
                                <ng-container *ngFor="let row of projectCalculationItems;">
                                    <tr cdkDrag cdkDragPreviewContainer="parent" class="typeData" 
                                        [ngClass]="{
                                                    'row-rule': row['type'] == 'RULE' || row['type'] == 'MULTIPLY',
                                                    'row-subcategory': row['type'] == 'SUBCATEGORY', 
                                                    'row-category': row['type'] == 'CATEGORY', 
                                                    'row-calculation': row['type'] == 'CALCULATION',
                                                    'row-calculation-total': row['type'] == 'TOTAL_CALCULATION'
                                                    }"
                                        >
                                        <td style="width:30px;" cdkDragHandle class="dragHandle">
                                            <i class="bi bi-arrows-move"></i>
                                        </td>
                                        <td width="100">
                                            <select [(ngModel)]="row['type']" class="form-control" (blur)="update(row)">
                                                <option value="{{itemType.value}}" *ngFor="let itemType of itemTypes">
                                                    {{itemType.name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td width="60">
                                            <select [(ngModel)]="row['category']" *ngIf="row['type'] == 'RULE' || row['type'] == 'MULTIPLY'" class="form-control" (blur)="update(row)">
                                                <option value="{{itemCategory.value}}" *ngFor="let itemCategory of itemCategories">
                                                    {{itemCategory.name}}
                                                </option>
                                            </select>
                                        </td>
                                        <td width="40">
                                            <input type="text" [(ngModel)]="row['number']" *ngIf="row['isCalculation'] == false && row['type'] != 'MULTIPLY'" (blur)="update(row)" value="{{row['number']}}" style="width:100%;">
                                        </td>

                                        <td>
                                            <span style="display:block;position:relative;">
                                                <input type="text" id="calculation-title-{{row['id']}}" [(ngModel)]="row['title']" (blur)="update(row)" (keypress)="showNameList($event, row)" value="{{row['title']}}" style="width:100%;">
                                                <div class="row-suggestion-list" *ngIf="activeRowNameId == row['id'] && row['type'] == 'RULE'">
                                                    <div class="select-suggestion" (click)="selectName(row, searchName)" *ngFor="let searchName of searchNames(row)">
                                                        {{searchName['title']}} <small>(&euro; {{searchName['price']}} per {{searchName['unit']}})</small>
                                                    </div>
                                                </div>
                                            </span>
                                        </td>
                                        
                                        <td *ngIf="row['isCalculation'] == false" width="80">
                                            <input type="text" id="calculation-amount-{{row['id']}}" *ngIf="row['type'] == 'RULE'" (blur)="update(row)" (focus)="selectAll($event)" (keydown)="onKeyDownNumeric($event)" [(ngModel)]="row['amount']" class="number-input" value="{{row['amount']}}" style="width:100%;" >
                                        </td>

                                        <td *ngIf="row['isCalculation'] == false" width="100"> 
                                            <span style="display:block;position:relative;">
                                                <input type="text" id="calculation-unit-{{row['id']}}" [(ngModel)]="row['unit']" (focus)="showUnitList($event, row)" (blur)="hideUnitList($event, row)" class="form-control" *ngIf="row['type'] == 'RULE'">
                                                <div class="row-suggestion-list" *ngIf="activeRowTypeId == row['id']">
                                                    <div class="select-suggestion" (click)="selectUnit(row, searchUnit)" *ngFor="let searchUnit of searchUnits(row)">{{searchUnit}}</div>
                                                </div>
                                            </span>
                                        </td>

                                        <td *ngIf="row['isCalculation'] == false && row['type'] != 'MULTIPLY'" width="100">
                                            <span class="input-with-euro" *ngIf="row['type'] == 'RULE' || row['isCalculation'] == true">
                                                <i>&euro;</i>
                                                <input type="text" id="calculation-price-{{row['id']}}" (keydown)="onKeyDownNumeric($event)" (focus)="selectAll($event)" (blur)="update(row)" [(ngModel)]="row['price']" class="money-input" value="{{row['price']}}" style="width:100%;">
                                            </span>
                                        </td>

                                        <td *ngIf="row['type'] == 'MULTIPLY'" width="100">
                                            <select [(ngModel)]="row['formula']" *ngIf="row['type'] == 'RULE' || row['type'] == 'MULTIPLY'" class="form-control" (blur)="update(row)">
                                                <option value="{{itemCategory.value}}" *ngFor="let itemCategory of itemCategories">
                                                    {{itemCategory.name}}
                                                </option>
                                            </select>
                                        </td>

                                        <td *ngIf="row['isCalculation'] == true" colspan="3">
                                            <span class="input-with-icon">
                                                <i class="bi bi-calculator" title="Berekening"></i>
                                                <input type="text" [(ngModel)]="row['formula']" class="input-formula" placeholder="Formule, bijv: C1 + C2" *ngIf="row['isCalculation'] == true" (blur)="update(row)" value="{{row['formula']}}" style="width:75%">
                                            </span>                  
                                        </td>
                                        <td width="140">
                                            <span class="input-with-euro" *ngIf="row['type'] == 'RULE' || row['type'] == 'MULTIPLY' || row['isCalculation'] == true">
                                                <i>&euro;</i>
                                                <input class="money-input" type="number" readonly [(ngModel)]="row['total']"value="{{row['total']}}" style="width:100%;">
                                            </span>
                                        </td>
                                        <td width="60" nowrap>
                                            <span (click)="confirmDelete(row)" class="me-3 row-icon delete2-icon">
                                                <i class="bi bi-trash-fill"></i>
                                            </span>
                                            <span (click)="addRow(row['position'],row['category'],row['type'])">
                                                <i class="bi bi-plus-lg add2-icon row-icon"></i>
                                            </span>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </section>
                </section>
            </div>
        </div>
    </section>
    <section class="" *ngIf="currentTab == 'files' && mode == 'PROJECT'">
        <div class="file-total-container" *ngIf="currentUser['role'] == 'ADMIN'">
            <div class="d-flex">
                <div class="pe-5 ps-3 pt-2 fw-bold">Totaalbedrag</div>
                <div>
                    <span class="input-with-euro">
                        <i>&euro;</i>
                        <input type="text" (keydown)="onKeyDownNumeric($event)" [(ngModel)]="project['calculationTotal']" (blur)="updateTotal()">
                    </span>
                </div>
            </div>
        </div>
        <app-project-files [project]="project" (reloadAction)="reload($event)" target="CALCULATION"></app-project-files>
    </section>
    <section class="py-5 my-5"></section>
</section>