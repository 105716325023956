import { Component, OnInit, Input, Output, Inject, Renderer2, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';

@Component({
	selector: 'app-file-preview',
	templateUrl: './file-preview.component.html',
	styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

	@Input() public title;
	@Input() public mobile;
	@Input() public button;
	@Input() public message;
	@Input() public file;
	@Input() public fileList = [];
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	content;
	previousFile;
	nextFile;
	loaderOverlay = true;

	fullscreen = false;

	constructor(
		public activeModal: NgbActiveModal,
		public appService: AppService,
		public sanitizer: DomSanitizer,
		@Inject(DOCUMENT) private document: Document,
		private renderer: Renderer2,
	) { }

	ngOnInit(): void {
		let self = this;

		self.appService.setLoaderStatus(true);


		if(this.file.ext === "msg") {
			console.log(this.message)
			this.message = this.message.replace("iframe", `iframe id='page-container'`);
		}

		
		this.content = this.sanitizer.bypassSecurityTrustHtml(this.message);

		this.setNavIds();

		setTimeout(function () {
			self.loaderOverlay = false;
			self.appService.setLoaderStatus(false);
		}, 100);
	}

	toggleFullscreen() {
		this.fullscreen = !this.fullscreen
		if(this.fullscreen) {
			this.document.body.classList.add('fullscreen-modals');
		} else {
			this.document.body.classList.remove('fullscreen-modals');
		}
	}

	setCurrentFile(file) {
		let self = this;
		self.loaderOverlay = true;

		this.file = file;

		let link = '<iframe data="' + file['viewUrl'] + '" style="width:100%;height:600px;"></iframe>';
		if (file['isImage']) {
			link = '<img src="' + file['viewUrl'] + '" style="width:100%;height:100%;">';
			setTimeout(function () {
				self.loaderOverlay = false;
				self.appService.setLoaderStatus(false);
			}, 20);
		}
		else {
			setTimeout(function () {
				self.loaderOverlay = false;
				self.appService.setLoaderStatus(false);
			}, 2000);
		}
		this.message = link;
		this.content = this.sanitizer.bypassSecurityTrustHtml(this.message);

		self.appService.setLoaderStatus(true);
		this.setNavIds();


	}

	setNavIds() {
		let self = this;

		self.previousFile = null;
		self.nextFile = null;

		let found = false;

		if (this.fileList) {
			this.fileList.every(item => {
				if (found == true) {
					self.nextFile = item;
					return false;
				}
				else {
					if (item['id'] === self.file['id']) {
						found = true;
					}
					else {
						self.previousFile = item;
					}
				}
				return true;
			});
		}
	}

	// transform(value) {

	//   return ;
	// }

	confirm() {
		this.activeModal.close(false);
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}
}