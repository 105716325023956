<section *ngIf="item" [ngClass]="{'loader-overlay': loaderOverlay}">
	<div class="modal-header">
		<h5 class="modal-title">
			<span *ngIf="!item['id']">Ploeg aanmaken</span>
			<span *ngIf="item['id']">Ploeg wijzigen</span>
		</h5>
		<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
	</div>
	<div class="modal-body">
		<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
		<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>
		<section class="user-form" *ngIf="item">
			<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item['id']" name="id">

			<div class="form-group mb-3">
				<label for="name" class="pb-1">Naam</label>
				<input type="text" class="form-control" id="name" required [(ngModel)]="item['name']" name="name">
				<div class="error" *ngIf="errors['name']">{{errors['name']}}</div>
			</div>
			<div class="form-group mb-3">
				<label for="shortName" class="pb-1">Korte naam</label>
				<input type="text" class="form-control" id="shortName" required [(ngModel)]="item['shortName']"
					name="shortName">
				<div class="error" *ngIf="errors['shortName']">{{errors['shortName']}}</div>
			</div>
			<div class="form-group mb-3 clearfix">
				<label for="shortName" class="pb-1">Kleur</label>
				<div class="color-list">
					<div class="color-list-item float-start" *ngFor="let color of colors; let i = index">
						<input type="radio" name="color" id="color_{{i}}" [(ngModel)]="item['color']"
							value="{{color['value']}}">
						<label for="color_{{i}}" [ngStyle]="{'background-color': color['value']}">{{color['name']}}
							{{color['value']}}</label>
					</div>
				</div>
			</div>
			<div class="form-group mb-3">
				<label for="shortName" class="pb-1">Actief</label>
				<div class="form-check form-switch">
					<input type="checkbox" class="form-check-input" id="active" role="switch" name="active"
						[ngModelOptions]="{standalone: true}" [(ngModel)]="item.active">
					<label class="form-check-label" for="active"></label>
				</div>
			</div>

			<div class="user-group-users mb-5 pb-5" style="overflow-x: hidden; overflow-y: scroll; height: 400px;">
				<div class="row" style="position: sticky; top: 0; background: white; z-index: 99;">
					<div class="col-12"
						style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:15px; display: flex; justify-content: space-between; align-items: center;">
						<small>Medewerkers</small>
						<!-- (click)="tryReset()" (input)="searchNames()" -->
						<input style="display: inline; width: 150px; margin-right: 5px;" type="text"
							class="form-control ng-pristine ng-valid" placeholder="Zoeken op naam"
							[(ngModel)]="filterName">
					</div>

					<div class="row" *ngFor="let user of users">
						<div class="col-12 option-row"
							[ngClass]="{'inactive' : user['active'] == false}" *ngIf="selectedItems[user['id']]"
							style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
							<div (click)="toggleList(user['id'])">
								<i *ngIf="selectedItems[user['id']]" class="bi bi-check-square"
									style="position:relative;top:2px;margin-right:5px;"></i>
								<i *ngIf="!selectedItems[user['id']]" class="bi bi-square"
									style="position:relative;top:2px;margin-right:5px;opacity:0.5;"></i>
								{{user['name']}} <span class="text-muted">({{user['email']}})</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row" *ngFor="let user of users">
					<div class="col-12 option-row" [ngClass]="selectedItems[user['id']] ? 'active' : ''"
						[ngClass]="{'inactive' : user['active'] == false}" *ngIf="canShow(user['name']) && !selectedItems[user['id']]"
						style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
						<div (click)="toggleList(user['id'])">
							<i *ngIf="selectedItems[user['id']]" class="bi bi-check-square"
								style="position:relative;top:2px;margin-right:5px;"></i>
							<i *ngIf="!selectedItems[user['id']]" class="bi bi-square"
								style="position:relative;top:2px;margin-right:5px;opacity:0.5;"></i>
							{{user['name']}} <span class="text-muted">({{user['email']}})</span>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
	<div class="modal-footer" *ngIf="item">
		<button (click)="save()" class="btn btn-success">
			<span *ngIf="!item.id"><i class="bi bi-sd-card pe-2"></i> Toevoegen</span>
			<span *ngIf="item.id"><i class="bi bi-sd-card pe-2"></i> Opslaan</span>
		</button>
	</div>
</section>