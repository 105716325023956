import { Component, OnInit, Input, Output, Renderer2 } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { UsersService } from 'src/app/services/users.service';


@Component({
  selector: 'app-user-category',
  templateUrl: './user-category.component.html',
  styleUrls: ['./user-category.component.scss']
})
export class UserCategoryComponent implements OnInit {

	@Input() public id;
	@Input() public message;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();
	loaderOverlay = false;

	currentUser;
  categories;
  selectedCategories = [];
	
	constructor(
		public service: UsersService,
		public modalService: NgbModal,
		public appService: AppService,
		private authenticationService: AuthenticationService,
		public apiService: ApiConnectionService,
		public renderer: Renderer2,
		public config: ConfigService,
		public activeModal: NgbActiveModal
	) {
		let self = this;
		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
		  if (Object.keys(data).length) {
        self.currentUser = data;

        for(let i = 0; i < self.currentUser['categories'].length; i++) {
          self.selectedCategories[self.currentUser['categories'][i]['id']] = self.currentUser['categories'][i];
        }
		  } else {
			self.currentUser = false;
		  }
		});
	}

	ngOnInit(): void {
    this.fetchCategories();
	}
	
  fetchCategories () {	
		let self = this;
		this.apiService.read('categories/list').then((response) => {
			self.categories = response;
		});	
	}

	toggleCat(id) {
		if(this.selectedCategories[id] == undefined) {
			this.currentUser['categoryIds'].push(id);
			this.selectedCategories[id] = {id: id};
		} else {
			this.currentUser['categoryIds'].splice(this.currentUser['categoryIds'].indexOf(id), 1);
			delete this.selectedCategories[id];
		}
	}

	save() {
    this.apiService.save('users/save', this.currentUser).then((response) => {
      location.reload();
    });	
	}
  
	dismiss() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	cancel() {
		this.activeModal.close(false);
	}
}