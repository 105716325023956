import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';

import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
	selector: 'app-edit-category-order',
	templateUrl: './edit-category-order.component.html',
	styleUrls: ['./edit-category-order.component.scss']
})
export class EditCategoryOrderComponent implements OnInit {
	@Input() public project;
	@Input() public projectId;
	@Input() public target;
	@Input() public type;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	public loaderOverlay = false;
	public statusOptions;
	public selectedStatus = 'NVT';
	public currentTab = 'info';

	public categoryList = [];

	constructor(
		public activeModal: NgbActiveModal,
		public appService: AppService,
		public api: ApiConnectionService,
		public config: ConfigService
	) {

	}

	ngOnInit(): void {
		const self = this;

		this.api
			.read("projectFiles/categories/" + this.projectId)
			.then((response: any) => {
				self.categoryList = response;
			});
	}

	confirm() {
		let self = this;

		self.loaderOverlay = true;

		self.appService.setLoaderStatus(true);

		let apiData = [];
		for(let i = 0; i < this.categoryList.length; i++) {
			apiData.push(this.categoryList[i]['id']);
		}

		return this.api.save('projectFiles/categoryPositions/', {
			items: apiData
		}).then((response) => {
			self.loaderOverlay = false;

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageType('SUCCESS');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);

		}).catch(function (response) {
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);
		});
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.categoryList, event.previousIndex, event.currentIndex);
	}
}
