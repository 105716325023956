<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title ps-3" style="margin-left:-5px;">Ploeg</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1" *ngIf="item">
        <section class="quick-view-container px-4 py-4">
            <h5 class="mb-4">Informatie</h5>
            <div class="row first-border">
                <div class="col-3">Naam</div>
                <div class="col-9">{{item['name']}}</div>
            </div>
            <div class="row">
                <div class="col-3">Korte naam</div>
                <div class="col-9">{{item['shortName']}}</div>
            </div>
            <div class="row">
                <div class="col-3">Status</div>
                <div class="col-9" [innerHTML]="item['activeLabel']"></div>
            </div>
            <h5 class="mt-5 mb-4">Medewerkers</h5>
            <div class="text-muted py-3" *ngIf="!users">Nog geen medewerkers</div>
            <div  *ngIf="users">
                <div class="row" [ngClass]="{
                    'first-border': i == 0
                }" *ngFor="let user of users;let i = index;">
                    <div class="col-3">{{user['name']}}</div>
                    <div class="col-3">{{user['email']}}</div>
                    <div class="col-3">{{user['phone']}}</div>
                    <div class="col-3">{{user['function']}}</div>
                </div>
            </div>
        </section>
    </div>
    <div class="modal-footer d-block only-for-admin" *ngIf="item && currentUser['role'] == 'ADMIN'">
        <a class="btn btn-dark with-icon" (click)="openFormProject()">Wijzig ploeg <i class="bi bi-pencil"></i></a>
    </div>
</div>