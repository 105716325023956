<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title ps-3" style="margin-left:-5px;">Klant informatie</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1" *ngIf="item">
        <section class="quick-view-container px-4 py-4">
            <h5 class="mb-4">Informatie</h5>
            <div class="row first-border">
                <div class="col-3">Naam</div>
                <div class="col-9">{{item['name']}}</div>
            </div>
            <div class="row">
                <div class="col-3">Adres</div>
                <div class="col-9">{{item['street']}} {{item['zipcode']}} <span *ngIf="item['city']">, </span>{{item['city']}}</div>
            </div>
            <div class="row">
                <div class="col-3">E-mail</div>
                <div class="col-9">{{item['email']}}</div>
            </div>
            <div class="row">
                <div class="col-3">Telefoon</div>
                <div class="col-9">{{item['phone']}}</div>
            </div>
            <div class="row only-for-admin">
                <div class="col-3">KVK</div>
                <div class="col-9">{{item['cocNumber']}}</div>
            </div>
            <div class="row only-for-admin">
                <div class="col-3">BTW</div>
                <div class="col-9">{{item['vatNumber']}}</div>
            </div>
            <div class="row">
                <div class="col-3">Status</div>
                <div class="col-9" [innerHTML]="item['activeLabel']"></div>
            </div>
            <h5 class="mt-5 mb-4">Contactpersonen</h5>
            <div class="text-muted py-3" *ngIf="!item['clientContacts']">Nog geen contactpersonen</div>
            <div  *ngIf="item['clientContacts']">
                <div class="row" [ngClass]="{
                    'first-border': i == 0
                }" *ngFor="let clientContact of item['clientContacts'];let i = index;">
                    <div class="col-3">{{clientContact['name']}}</div>
                    <div class="col-3">{{clientContact['email']}}</div>
                    <div class="col-3">{{clientContact['phone']}}</div>
                    <div class="col-3">{{clientContact['function']}}</div>
                </div>
            </div>
        </section>
    </div>
    <div class="modal-footer d-block only-for-admin" *ngIf="item && currentUser['role'] == 'ADMIN'">
        <a class="btn btn-dark with-icon" (click)="openForm({})">Wijzig klant <i class="bi bi-pencil"></i></a>
    </div>
</div>