<section class="table-content" *ngIf="!item">
	<div class="text-center py-5" >
		<img style="width:140px;" src="/assets/images/loader.gif">
	</div>
</section>
<section *ngIf="item">
    <header class="page-header">
        <div class="row">
            <div class="col-8">
                <h1>Calculatietemplate / {{item['name']}}</h1>
                <h6><a routerLink="/settings">Instellingen</a> / <a routerLink="/settings/calculation-templates">Calculatietemplates</a></h6>
            </div>
            <div class="col-4 text-end pt-3">
                <button (click)="edit()" class="btn btn-success with-icon icon-left"><i class="bi bi-pencil"></i> Wijzig</button>
            </div>
        </div>
    </header>

    <app-calculation-items [template]="item"></app-calculation-items>
</section>