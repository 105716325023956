import { Component, OnInit } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-bestandsviewer',
  templateUrl: './bestandsviewer.component.html',
  styleUrls: ['./bestandsviewer.component.scss']
})
export class BestandsviewerComponent implements OnInit {

  constructor(public api: ApiConnectionService) { }

  ngOnInit(): void {
  }

}
