<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title">Wijzig KLIC notitie {{currentUser['role']}}</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1"> 
        <div class="row">
            <div class="col-12 p-5" *ngIf="currentUser['role'] == 'USER'">
                Notitie:<br/>
                <textarea class="form-control" disabled [(ngModel)]="project['klicNote']" ></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-5" *ngIf="currentUser['role'] != 'USER'">
                Notitie:<br/>
                <textarea class="form-control" placeholder="" [(ngModel)]="project['klicNote']" ></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer visible-for-manager" *ngIf="project">
        <button (click)="confirm()" class="btn btn-success with-icon" *ngIf="currentUser['role'] != 'USER'">
            Verder <i class="bi bi-arrow-right"></i>
        </button>
    </div>  
</div>