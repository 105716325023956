import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-settings-calculation-mutate-modal',
  templateUrl: './settings-calculation-mutate-modal.component.html',
  styleUrls: ['./settings-calculation-mutate-modal.component.scss']
})
export class SettingsCalculationMutateModalComponent implements OnInit {
  @Input() public item = {};
  @Input() public edit = false;

  errors = {};
  loaderOverlay = false;
  errormessage = false;

  constructor(
    private activeModal: NgbActiveModal,
    private appService: AppService,
    private apiService: ApiConnectionService
  ) { }

  ngOnInit(): void {

  }
  
	confirm () {
    let self = this;
    this.save().then(function () {
		  self.activeModal.close(self.item);
    });
	}
	
	save() {
		let self = this;
		self.errors = [];
		
		self.loaderOverlay = true;

		self.appService.setLoaderStatus(true);
		return this.apiService.save('calculationTemplates/save', self.item).then((response) => {
			self.item = response;
			self.loaderOverlay = false;

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_SAVED');
			self.appService.setSystemMessageType('SUCCESS');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

		}).catch (function (response) {
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;
			
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	close () {
		this.activeModal.close(false);
	}
}
