<section *ngIf="items" [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h5 class="modal-title">Selecteer template</h5>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body">
        <div class="alert alert-warning p-2" style="font-size:80%;">
            Door het selecteren van een template worden de huidige instellingen verwijderd.
        </div>
        <div class="row" *ngFor="let item of items">
            <div class="col-12">
                <div class="template-row" (click)="select(item['id'])">
                    <i class="bi bi-arrow-right-circle"></i> {{item['name']}}
                </div>
            </div>
        </div>
    </div>
</section>