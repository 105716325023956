import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';


@Component({
	selector: 'app-file-category',
	templateUrl: './file-category-form.component.html',
	styleUrls: ['./file-category-form.component.scss']
})
export class FileCategoryFormComponent implements OnInit {
	@Input() public targetUrl;
	@Input() public projectId;
	@Input() public name;
	@Input() public role;

	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	public loaderOverlay;

	constructor(
		public activeModal: NgbActiveModal,
		public app: AppService,
		public api: ApiConnectionService
	) {

	}

	ngOnInit(): void {
	}

	confirm() {
		let self = this;

		self.loaderOverlay = true;
		self.app.setLoaderStatus(true);







		return this.api.save(self.targetUrl, {
			projectId: this.projectId,
			name:  this.name,
			role: this.role,
		}).then((response) => {
			self.loaderOverlay = false;

			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageType('SUCCESS');
			self.app.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);

		}).catch(function (response) {
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageText('DEFAULT_ERROR');
			self.app.setSystemMessageType('DANGER');
			self.app.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);
		});



		// Make an AJAX request or use Fetch API to send the files
		// var xhr = new XMLHttpRequest();
		// xhr.open('POST', self.targetUrl, true);
		// xhr.onreadystatechange = function () {
		// 	if (xhr.readyState === 4 && xhr.status === 200) {
		// 		self.loaderOverlay = false;
		// 		self.app.setLoaderStatus(false);
		// 		self.app.setSystemMessageLoading(true);
		// 		self.app.setSystemMessageText('Categorie toegevoegd');
		// 		self.app.setSystemMessageType('SUCCESS');
		// 		self.activeModal.close(200);
		// 	}
		// };
		// xhr.send(formData);
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}
}