<header class="page-header">
	<div class="row">
		<div class="col-8">
            <h1>Calculatietemplates</h1>
			<h6><a routerLink="/settings">Instellingen</a> / Calculatietemplates</h6>
		</div>
		<div class="col-4 text-end pt-3">
			<button (click)="add()" class="btn btn-success with-icon">Toevoegen <i class="bi bi-plus-circle-fill"></i></button>
		</div>
	</div>
</header>

<app-table-guru
	 [link]="link"
	 [refresh]="refresh"
	 [mapping]="mapping"
	 [editPath]="editPath" 
	 [showEditButtons]="true" 
	 (editAction)="editRow($event)"
	 (deleteAction)="deleteRow($event)"
></app-table-guru>