import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../../services/core/config.service';
import { AuthenticationService } from '../../services/core/authentication.service';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ApiConnectionService {
	public dataSource = new BehaviorSubject({});
	public data = this.dataSource.asObservable();
	public authState = new BehaviorSubject(true);

	private publicHashTimer = Math.floor(new Date().getTime() / 1000);
	private hash = "";

	constructor(
		public http: HttpClient,
		public configService: ConfigService,
		public authenticationService: AuthenticationService,
		public router: Router,
	) {
	}

	all() {

	}

	setAccessToken(token) {
		localStorage.setItem('accessToken', token);
	}

	setRefreshToken(token) {
		localStorage.setItem('refreshToken', token);
	}

	clearAccessToken() {
		localStorage.removeItem('accessToken');
	}

	clearRefreshToken() {
		localStorage.removeItem('refreshToken');
	}

	getAccessToken() {
		return localStorage.getItem('accessToken');
	}

	getRefreshToken() {
		return localStorage.getItem('refreshToken');
	}

	handleError(error) {

	}

	delete(endpoint, retry = true) {

		if (typeof (retry) === 'undefined') {
			retry = true;
		}

		var self = this;
		let apiUrl = this.configService.get('api_url');

		let headers = new HttpHeaders().set('Authorization', 'Bearer: ' + self.getAccessToken() + '');
		let request = new Promise(function (resolve, reject) {
			self.http.get(apiUrl + '' + endpoint + '', { withCredentials: true, headers: headers })
				.subscribe(data => {
					let response: any = data;
					resolve(response);
				},
					error => {
						reject(error);
					});
		});

		return request;
	}

	logout() {
		this.clearRefreshToken();
		this.clearAccessToken();

		this.authenticationService.logout();
	}

	refreshToken() {
		let self = this;
		let apiUrl = this.configService.get('api_url');
		let request = new Promise(function (resolve, reject) {
			self.http.get(apiUrl + 'users/refreshToken/' + self.getRefreshToken() + '', { withCredentials: true })
				.subscribe(data => {
					let response: any = data;

					resolve(response);
				},
					error => {
						console.dir(error);
						reject(error);
					}
				);
		});

		return request;
	}

	getUploadBase() {
		return this.configService.get('api_url') + "projectFiles/upload/";
	}

	getAddCategory() {
		return "projectFiles/addCategory";
	}

	//every 10s the hash is refreshed and saved locally 
	shouldVerifyHash() {
		const currentTimestamp = Math.floor(new Date().getTime() / 1000);
		return currentTimestamp - this.publicHashTimer > 10;
	}

	getPublicHash() {
		this.authenticationService.getCurrentUser();
		this.authenticationService.data.subscribe(data => {
			if (Object.keys(data).length) {
				this.hash = data['publicHash'];
			} else {
				this.hash = null;
			}
		});
	}

	read(endpoint, retry = true) {
		if (typeof (retry) === 'undefined') {
			retry = true;
		}

		var self = this;

		let apiUrl = this.configService.get('api_url');
		let headers = new HttpHeaders().set('Authorization', 'Bearer: ' + self.getAccessToken() + '');

		if (this.shouldVerifyHash()) {
			this.getPublicHash();
		}

		return new Promise(function (resolve, reject) {
			self.http.get(apiUrl + '' + endpoint + '&hash=' + self.hash, { withCredentials: true, headers: headers })
				.subscribe(data => {
					let response: any = data;
					resolve(response);
				},
					error => {
						if (error.status === 401) {
							reject(error);
							self.logout();
						}
						else if (error.status === 403) {
							reject(error);
						} else {
							reject(error);
						}
					});
		});
	}

	save(endpoint, postData, retry = true) {

		if (typeof (retry) === 'undefined') {
			retry = true;
		}

		let self = this;
		let headers = new HttpHeaders()
			.set('content-type', 'application/x-www-form-urlencoded')
			.set('Authorization', 'Bearer: ' + self.getAccessToken() + '');

		let apiUrl = this.configService.get('api_url');
		let request = new Promise(function (resolve, reject) {
			self.http.post(apiUrl + '' + endpoint + '', postData, { headers: headers, withCredentials: true })
				.subscribe(data => {
					let response: any = data;
					resolve(response);
				},
					error => {
						reject(error);
					});
		});

		return request;
	}

	saveFile(endpoint, postData, retry = true) {

		if (typeof (retry) === 'undefined') {
			retry = true;
		}

		let self = this;

		let headers = new HttpHeaders().set('Authorization', 'Bearer: ' + self.getAccessToken() + '');

		let apiUrl = this.configService.get('api_url');
		let request = new Promise(function (resolve, reject) {
			self.http.post(apiUrl + '' + endpoint + '', postData, { withCredentials: true, headers: headers })
				.subscribe(data => {
					let response: any = data;
					resolve(response);
				},
					error => {
						reject(error);
					});
		});

		return request;
	}
}