<div [ngClass]="{'loader-overlay': loaderOverlay}" *ngIf="currentUser['role'] != 'ADMIN' && currentUser['role'] != 'MANAGER'">
	<div class="modal-header" *ngIf="item">
		<h5 class="modal-title" *ngIf="item['isQuote']">
			<span *ngIf="!item['id']">Offerte aanmaken</span>
			<span *ngIf="item['id']">Offerte wijzigen</span>
		</h5>
		<h5 class="modal-title" *ngIf="!item['isQuote']">
			<span *ngIf="!item['id']">Project aanmaken</span>
			<span *ngIf="item['id']">Project wijzigen</span>
		</h5>
		<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
	</div>
	<div class="modal-body p-0">
		<p class="text-muted py-5 my-5 text-center">Je hebt geen rechten om dit project te bewerken</p>
	</div>
</div>

<div [ngClass]="{'loader-overlay': loaderOverlay}" *ngIf="currentUser['role'] == 'MANAGER'">
	<div *ngIf="preload" style="background:#FCFCFC;border-top-left-radius:10px;border-bottom-left-radius:10px;">
		<div class="modal-header" *ngIf="item">
			<h5 class="modal-title" *ngIf="item['isQuote']">
				<span *ngIf="!item['id']">Offerte aanmaken</span>
				<span *ngIf="item['id']">Offerte wijzigen</span>
			</h5>
			<h5 class="modal-title" *ngIf="!item['isQuote']">
				<span *ngIf="!item['id']">Project aanmaken</span>
				<span *ngIf="item['id']">Project wijzigen</span>
			</h5>
			<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
		</div>
		<div class="modal-body p-0">
			<section class="user-form" *ngIf="item">
				<div class="py-2 px-4 project-info-form" [ngClass]="{'d-none': currentTab != 'status'}">
					<div class="row">
						<div class="col-3">
							<label for="userGroupId" class="main-label">Ploeg</label>
						</div>
						<div class="col-9">
							<select name="userGroupId" [(ngModel)]="item.userGroupId" class="form-control">
								<option value="">Geen</option>
								<option value="{{userGroup.id}}" *ngFor="let userGroup of userGroups">
									{{userGroup.name}}
								</option>
							</select>
						</div>
					</div>
				</div>

			</section>
			<br /><br /><br /><br />
		</div>
		<div class="modal-footer" *ngIf="item">

			<a *ngIf="fullLink" class="btn btn-dark ms-2 mb-2" (click)="toProject()">Naar project</a>

			<button (click)="setTab('status')" *ngIf="!item.id && currentTab=='info'" class="btn btn-success">
				<span>
					<i class="bi bi-sd-card pe-2"></i> Volgende
				</span>
			</button>

			<button (click)="save()" *ngIf="!item.id && currentTab=='status'" class="btn btn-success">
				<span>
					<i class="bi bi-sd-card pe-2"></i> Volgende
				</span>
			</button>

			<button (click)="save()" *ngIf="item.id" class="btn btn-success">
				<span>
					<i class="bi bi-sd-card pe-2"></i> Opslaan </span>
			</button>
		</div>
	</div>
</div>

<div [ngClass]="{'loader-overlay': loaderOverlay}" *ngIf="currentUser['role'] == 'ADMIN'">
	<div *ngIf="preload" style="background:#FCFCFC;border-top-left-radius:10px;border-bottom-left-radius:10px;">
		<div class="modal-header" *ngIf="item">
			<h5 class="modal-title" *ngIf="item['isQuote']">
				<span *ngIf="!item['id']">Offerte aanmaken</span>
				<span *ngIf="item['id']">Offerte wijzigen</span>
			</h5>
			<h5 class="modal-title" *ngIf="!item['isQuote']">
				<span *ngIf="!item['id']">Project aanmaken</span>
				<span *ngIf="item['id']">Project wijzigen</span>
			</h5>
			<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
		</div>
		<div class="modal-body p-0">
			<section class="user-form" *ngIf="item">
				<ul class="nav nav-fill mb-4">
					<li class="nav-item">
						<span class="nav-link " (click)="setTab('info')"
							[ngClass]="{active: currentTab=='info'}">Offerte informatie</span>
					</li>
					<li class="nav-item">
						<span class="nav-link " (click)="setTab('status')"
							[ngClass]="{active: currentTab=='status'}">Project informatie</span>
					</li>
				</ul>

				<div class="py-2 px-4 project-info-form" [ngClass]="{'d-none': currentTab != 'info'}">
					<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item['id']" name="id">
					<div style="height:0px;overflow:hidden;"><input type="text" style="opacity:0"></div>
					<div class="row">
						<div class="col-3">
							<label for="organisationId" class="main-label">Klant</label>
						</div>
						<div class="col-9">
							<div style="position:relative;">
								<input type="hidden" class="form-control" [(ngModel)]="item['organisationId']"
									name="organisationId">
								<div style="position:relative;">
									<input type="text" (focus)="showClientDD()" class="form-control dd-input"
										[ngClass]="{'dd-active': clientDDVisible == true}" required
										[(ngModel)]="clientDDKeyword"
										style="position:absolute;top:0;left:0;width:100%;">
									<input type="text" class="form-control" placeholder="Type hier de klantnaam"
										[(ngModel)]="item['organisationName']">
								</div>
								<div class="auto-dropdown" *ngIf="clientDDVisible == true">
									<div class="options">
										<div class="option" *ngFor="let item of searchOrganisations()"
											(click)="setOrganisationId(item)">
											{{item['name']}}
											<br />
											<small class="text-muted">{{item['number']}} | {{item['city']}}</small>
										</div>
									</div>
									<div class="new-item" (click)="openClientForm({})">
										<i class="bi bi-plus-lg"></i> Klant toevoegen
									</div>
								</div>
								<div class="error" *ngIf="errors['organisationId']">{{errors['organisationId']}}</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-3">
							<label for="categoryId" class="main-label">Categorie</label>
						</div>
						<div class="col-9">
							<select name="categoryId" [(ngModel)]="item.categoryId" class="form-control"
								*ngIf="item['id']">
								<ng-container *ngFor="let category of categoryList">
									<option value="{{category.id}}" *ngIf="category.id == item['categoryId']">
										{{category.name}}
									</option>
								</ng-container>
							</select>
							<select name="categoryId" [(ngModel)]="item.categoryId" class="form-control"
								*ngIf="!item['id']">
								<option value="">Geen</option>
								<ng-container *ngFor="let category of categoryList">
									<option value="{{category.id}}" *ngIf="category['active']">
										{{category.name}}
									</option>
								</ng-container>
							</select>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="contactPersonId" class="main-label">Contactpersoon</label>
						</div>
						<div class="col-9">
							<div style="position:relative;">
								<input type="hidden" class="form-control" [(ngModel)]="item['contactPersonId']"
									name="contactPersonId">
								<div style="position:relative;">
									<input type="text" (focus)="showContactDD()" class="form-control dd-input"
										[ngClass]="{'dd-active': contactDDVisible == true}" required
										[(ngModel)]="contactDDKeyword"
										style="position:absolute;top:0;left:0;width:100%;">
									<input type="text" class="form-control" placeholder="Bijvoorbeeld: Jan Jansen"
										[(ngModel)]="item['contactPersonName']">
								</div>
								<div class="auto-dropdown" *ngIf="contactDDVisible == true">
									<div class="options">
										<div class="option" *ngFor="let item of searchContacts()"
											(click)="setClientContact(item)">
											{{item['name']}} {{item['role']}}
											<br />
											<small class="text-muted">{{item['email']}} | {{item['phone']}}</small>
										</div>
									</div>
									<div class="new-item" (click)="openContactForm({})">
										<i class="bi bi-plus-lg"></i> Contact toevoegen
									</div>
								</div>
								<div class="error" *ngIf="errors['contactPersonId']">{{errors['contactPersonId']}}</div>
							</div>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="contactPersonId2" class="main-label">Contactpersoon 2</label>
						</div>
						<div class="col-9">
							<div style="position:relative;">
								<input type="hidden" class="form-control" [(ngModel)]="item['contactPerson2Id']"
									name="contactPerson2Id">
								<div style="position:relative;">
									<input type="text" (focus)="showContact2DD()" class="form-control dd-input"
										[ngClass]="{'dd-active': contact2DDVisible == true}" required
										[(ngModel)]="contact2DDKeyword"
										style="position:absolute;top:0;left:0;width:100%;">
									<input type="text" class="form-control" placeholder="Bijvoorbeeld: Jan Jansen"
										[(ngModel)]="item['contactPerson2Name']">
								</div>
								<div class="auto-dropdown" *ngIf="contact2DDVisible == true">
									<div class="options">
										<div class="option" *ngFor="let item of searchContacts2()"
											(click)="setClientContact2(item)">
											{{item['name']}} {{item['role']}}
											<br />
											<small class="text-muted">{{item['email']}} | {{item['phone']}}</small>
										</div>
									</div>
									<div class="new-item" (click)="openContact2Form({})">
										<i class="bi bi-plus-lg"></i> Contact toevoegen
									</div>
								</div>
								<div class="error" *ngIf="errors['contactPerson2Id']">{{errors['contactPerson2Id']}}
								</div>
							</div>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="contactPersonId3" class="main-label">Contactpersoon 3</label>
						</div>
						<div class="col-9">
							<div style="position:relative;">
								<input type="hidden" class="form-control" [(ngModel)]="item['contactPerson3Id']"
									name="contactPerson3Id">
								<div style="position:relative;">
									<input type="text" (focus)="showContact3DD()" class="form-control dd-input"
										[ngClass]="{'dd-active': contact3DDVisible == true}" required
										[(ngModel)]="contact3DDKeyword"
										style="position:absolute;top:0;left:0;width:100%;">
									<input type="text" class="form-control" placeholder="Bijvoorbeeld: Jan Jansen"
										[(ngModel)]="item['contactPerson3Name']">
								</div>
								<div class="auto-dropdown" *ngIf="contact3DDVisible == true">
									<div class="options">
										<div class="option" *ngFor="let item of searchContacts3()"
											(click)="setClientContact3(item)">
											{{item['name']}} {{item['role']}}
											<br />
											<small class="text-muted">{{item['email']}} | {{item['phone']}}</small>
										</div>
									</div>
									<div class="new-item" (click)="openContact3Form({})">
										<i class="bi bi-plus-lg"></i> Contact toevoegen
									</div>
								</div>
								<div class="error" *ngIf="errors['contactPerson3Id']">{{errors['contactPerson3Id']}}
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-3">
							<label for="siteManagerId" class="main-label">Locatie</label>
						</div>
						<div class="col-9">
							<div style="position:relative;">
								<div style="position:relative;">
									<input type="text" (focus)="showLocationDD()"
										placeholder="Stationsplein 1, Rotterdam" class="form-control dd-input"
										(keyup)="getAddresses()" [ngClass]="{'dd-active': locationDDVisible == true}"
										required [(ngModel)]="locationDDKeyword"
										style="position:absolute;top:0;left:0;width:100%;">
									<input type="text" class="form-control" placeholder="Stationsplein 1, Rotterdam"
										[(ngModel)]="item['address']">
								</div>
								<div class="auto-dropdown" *ngIf="locationDDVisible == true">
									<div class="options">
										<div class="option" *ngFor="let item of addresses" (click)="setAddress(item)">
											{{item['street']}} {{item['number']}}
											<br />
											<small class="text-muted">{{item['zipcode']}} | {{item['city']}}</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="street" class="main-label">Adres</label>
						</div>
						<div class="col-9">
							<div class="row">
								<div class="col-4">
									<input type="text" class="form-control" placeholder="Straat huinummer" id="street"
										required [(ngModel)]="item['street']" name="street">
									<div class="error" *ngIf="errors['street']">{{errors['street']}}</div>
								</div>
								<div class="col-4">
									<input type="text" class="form-control" id="zipcode" placeholder="Postcode" required
										[(ngModel)]="item['zipcode']" name="zipcode">
									<div class="error" *ngIf="errors['zipcode']">{{errors['zipcode']}}</div>
								</div>
								<div class="col-4">
									<input type="text" class="form-control" id="city" placeholder="Plaatsnaam" required
										[(ngModel)]="item['city']" name="city">
									<div class="error" *ngIf="errors['city']">{{errors['city']}}</div>
								</div>
							</div>
						</div>
					</div>
					<input type="hidden" class="form-control" id="long" required [(ngModel)]="item['long']">
					<input type="hidden" class="form-control" id="lat" [(ngModel)]="item['lat']">

					<div class="row">
						<div class="col-3">
							<label for="name" class="main-label">Titel</label>
						</div>
						<div class="col-9">
							<input type="text" class="form-control" id="name" placeholder="Straatnaam, Plaats" required
								[(ngModel)]="item['name']" name="name">
							<div class="error" *ngIf="errors['name']">{{errors['name']}}</div>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="longName" class="main-label">Lange titel</label>
						</div>
						<div class="col-9">
							<input type="text" class="form-control" id="longName"
								placeholder="Bijv: herstellen verharding te straatnaam" required
								[(ngModel)]="item['longName']" name="longName">
							<div class="error" *ngIf="errors['longName']">{{errors['longName']}}</div>
						</div>
					</div>

					<div class="row">
						<div class="col-3">
							<label for="externalReference" class="main-label">Externe referentie</label>
						</div>
						<div class="col-9">
							<input type="text" class="form-control" id="externalReference"
								placeholder="Klantreferentie: 000-000-000" required
								[(ngModel)]="item['externalReference']" name="externalReference">
							<div class="error" *ngIf="errors['externalReference']">{{errors['externalReference']}}</div>
						</div>
					</div>

					<div class="row">
						<div class="col-3">
							<label for="requestedAt" class="main-label">Aanvraagdatum</label>
						</div>
						<div class="col-9">
							<input type="date" class="form-control" id="requestedAt" required
								[(ngModel)]="item['requestedAt']" name="requestedAt">
							<div class="error" *ngIf="errors['requestedAt']">{{errors['requestedAt']}}</div>
						</div>
					</div>

					<div class="row" [ngClass]="{'d-none': !item['number']}">
						<div class="col-3">
							<label for="quoteSendAt" class="main-label">Offerte verzenddatum</label>
						</div>
						<div class="col-9">
							<input type="date" class="form-control" id="quoteSendAt" required
								[(ngModel)]="item['quoteSendAt']" name="quoteSendAt">
							<div class="error" *ngIf="errors['quoteSendAt']">{{errors['quoteSendAt']}}</div>
						</div>
					</div>

					<div class="row">
						<div class="col-3">
							<label for="quoteAccepted" class="main-label">Offerte geaccepteerd</label>
						</div>
						<div class="col-9">
							<select name="quoteAccepted" [(ngModel)]="item.quoteAccepted" class="form-control">
								<option value="{{option.value}}" *ngFor="let option of quoteAcceptedOptions">
									{{option.name}}
								</option>
							</select>
						</div>
					</div>

					<div class="row" [ngClass]="{'d-none': !item['number']}">
						<div class="col-3">
							<label for="quoteAcceptedAt" class="main-label">Offerte geaccepteerd op</label>
						</div>
						<div class="col-9">
							<input type="date" class="form-control" id="quoteAcceptedAt" required
								[(ngModel)]="item['quoteAcceptedAt']" name="quoteAcceptedAt">
							<div class="error" *ngIf="errors['quoteAcceptedAt']">{{errors['quoteAcceptedAt']}}</div>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="authorName" class="main-label">Aanmaker</label>
						</div>
						<div class="col-9">
							<div style="position:relative;">
								<div style="position:relative;">
									<input type="text" (focus)="showAuthorDD()" class="form-control dd-input"
										[ngClass]="{'dd-active': authorDDVisible == true}" required
										[(ngModel)]="authorDDKeyword"
										style="position:absolute;top:0;left:0;width:100%;">
									<input type="text" class="form-control" placeholder="Bijv. Jan Janssen"
										[(ngModel)]="item['authorName']">
								</div>
								<div class="auto-dropdown" *ngIf="authorDDVisible == true">
									<div class="options">
										<div class="option" *ngFor="let item of searchAuthors()"
											(click)="setAuthor(item)">
											{{item['name']}}
											<br />
											<small class="text-muted">{{item['email']}} | {{item['phone']}}</small>
										</div>
									</div>
								</div>
								<div class="error" *ngIf="errors['authorId']">{{errors['authorId']}}</div>
							</div>
						</div>
					</div>

				</div>

				<div class="py-2 px-4 project-info-form" [ngClass]="{'d-none': currentTab != 'status'}">

					<div class="row">
						<div class="col-3">
							<label for="Status" class="main-label">Status</label>
						</div>
						<div class="col-9">
							<select name="status" [(ngModel)]="item.status" class="form-control">
								<option value="{{status.value}}" *ngFor="let status of statusOptions">
									{{status.name}}
								</option>
							</select>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="verkeersplanStatus" class="main-label">Verkeersplan</label>
						</div>
						<div class="col-9">
							<select name="verkeersplanStatus" [(ngModel)]="item.verkeersplanStatus"
								class="form-control">
								<option value="{{option.value}}" *ngFor="let option of permitStatusOptions">
									{{option.name}}
								</option>
							</select>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="tvmStatus" class="main-label">TVM</label>
						</div>
						<div class="col-9">
							<select name="tvmStatus" [(ngModel)]="item.tvmStatus" class="form-control">
								<option value="{{option.value}}" *ngFor="let option of permitStatusOptions">
									{{option.name}}
								</option>
							</select>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="lisStatus" class="main-label">LIS</label>
						</div>
						<div class="col-9">
							<select name="lisStatus" [(ngModel)]="item.lisStatus" class="form-control">
								<option value="{{option.value}}" *ngFor="let option of permitStatusOptions">
									{{option.name}}
								</option>
							</select>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="klicStatus" class="main-label">KLIC melding</label>
						</div>
						<div class="col-9">
							<select name="klicStatus" [(ngModel)]="item.klicStatus" class="form-control">
								<option value="{{option.value}}" *ngFor="let option of permitStatusOptions">
									{{option.name}}
								</option>
							</select>
						</div>
					</div>


					<div class="row">
						<div class="col-3">
							<label for="siteManagerId" class="main-label">Uitvoerder</label>
						</div>
						<div class="col-9">
							<div style="position:relative;">
								<input type="hidden" class="form-control" [(ngModel)]="item['siteManagerId']"
									name="siteManagerId">
								<div style="position:relative;">
									<input type="text" (focus)="showManagerDD()" class="form-control dd-input"
										[ngClass]="{'dd-active': managerDDVisible == true}" required
										[(ngModel)]="managerDDKeyword"
										style="position:absolute;top:0;left:0;width:100%;">
									<input type="text" class="form-control" placeholder="Bijvoorbeeld: Jan Jansen"
										[(ngModel)]="item['siteManagerName']">
								</div>
								<div class="auto-dropdown" *ngIf="managerDDVisible == true">
									<div class="options">
										<div class="option" (click)="setUser({id: '', name: ''})">
											<small class="text-muted">Geen</small>
										</div>
										<div class="option" *ngFor="let item of searchUsers()" (click)="setUser(item)">
											{{item['name']}}
											<br />
											<small class="text-muted">{{item['email']}} | {{item['phone']}}</small>
										</div>
										<div class="new-item" (click)="openUserForm({})">
											<i class="bi bi-plus-lg"></i> Medewerker toevoegen
										</div>
									</div>
								</div>
								<div class="error" *ngIf="errors['siteManagerId']">{{errors['siteManagerId']}}</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-3">
							<label for="startAt" class="main-label">Aanvangdatum</label>
						</div>
						<div class="col-9">
							<input type="date" class="form-control" id="startAt" required [(ngModel)]="item['startAt']"
								name="startAt">
							<div class="error" *ngIf="errors['startAt']">{{errors['startAt']}}</div>
						</div>
					</div>

					<div class="row">
						<div class="col-3">
							<label for="endAt" class="main-label">Aantal dagen</label>
						</div>
						<div class="col-9">
							<input type="number" class="form-control" id="durationDays" required
								[(ngModel)]="item['durationDays']" name="durationDays">
							<div class="error" *ngIf="errors['durationDays']">{{errors['durationDays']}}</div>
						</div>
					</div>

					<div class="row">
						<div class="col-3">
							<label for="userGroupId" class="main-label">Ploeg</label>
						</div>
						<div class="col-9">
							<select name="userGroupId" [(ngModel)]="item.userGroupId" class="form-control">
								<option value="">Geen</option>
								<option value="{{userGroup.id}}" *ngFor="let userGroup of userGroups">
									{{userGroup.name}}
								</option>
							</select>
						</div>
					</div>

					<div class="row" *ngIf="id == undefined">
						<div class="col-3">
							<label for="userGroupId" class="main-label">Notitie</label>
						</div>
						<div class="col-9">
							<textarea class="form-control" [(ngModel)]="note"></textarea>
						</div>
					</div>
				</div>

				<div class="py-2 px-4 project-info-form" [ngClass]="{'d-none': currentTab != 'execution'}">

				</div>

			</section>
			<br /><br /><br /><br />
		</div>
		<div class="modal-footer" *ngIf="item">

			<a *ngIf="fullLink" class="btn btn-dark ms-2 mb-2" (click)="toProject()">Naar project</a>

			<button (click)="setTab('status')" *ngIf="!item.id && currentTab=='info'" class="btn btn-success">
				<span>
					<i class="bi bi-sd-card pe-2"></i> Volgende
				</span>
			</button>

			<button (click)="save()" *ngIf="!item.id && currentTab=='status'" class="btn btn-success">
				<span>
					<i class="bi bi-sd-card pe-2"></i> Volgende
				</span>
			</button>

			<button (click)="save()" *ngIf="item.id" class="btn btn-success">
				<span>
					<i class="bi bi-sd-card pe-2"></i> Opslaan </span>
			</button>
		</div>
	</div>
</div>