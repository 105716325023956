import { Component, OnInit, Input } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-abc',
  templateUrl: './abc.component.html',
  styleUrls: ['./abc.component.scss']
})
export class AbcComponent implements OnInit {
  public projectAbcItems;
  @Input() public project;
  public edit = false;

  constructor(
		private api: ApiConnectionService,
		private sanitizer: DomSanitizer,
		private modalService: NgbModal
  ) { }

  ngOnInit(): void 
  {

  }

  editForm ()
  {
    this.edit = true;
  }

  closeForm ()
  {
    this.edit = false;
  }
  
  transform(value) {
	return this.sanitizer.bypassSecurityTrustHtml(value);
 }
	
  getAbcItems () {	
    let self = this;
    let id = self.project['id'];
    self.projectAbcItems = [];
    this.api.read('projects/details/'+id+'/abc').then((response) => {		
      self.projectAbcItems = response['items'];
    });
  }
  
  ngOnChanges(changes: any) {
    this.getAbcItems();
  }

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.projectAbcItems, event.previousIndex, event.currentIndex);
	}

	deleteAbcItem (index, object)
	{
		let self = this;
	
		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je dit item wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				self.projectAbcItems.splice(index, 1);
				self.projectAbcItems = [... self.projectAbcItems]
			}
		});	
	}

	addAbcItem ()
	{
		this.projectAbcItems.push({
			status: 'NVT'
		});
	}

	saveAbcItems ()
	{
		let self = this;
		this.api.save('projectAbc/save/'+ self.project['id'] +'', {
			items: self.projectAbcItems
		}).then((response) => {		
			self.getAbcItems();
      self.edit = false;
		});
	}
}
