<section class="p-5" *ngIf="projectHours && projectHours.length < 1">
    <p class="text-center text-muted">Geen urenregistratie beschikbaar</p>
    <p class="text-center">
        <button type="button" class="btn btn-success" (click)="addWeek()">
            <i class="bi bi-plus-lg text-white me-2"></i> Week toevoegen
        </button>
    </p>
</section>
<section class="p-5" *ngIf="!projectHours">
    laden
</section>
<section class="table-grid" *ngIf="projectHours && projectHours.length > 0">
    <input type="search" name="search" placeholder="Zoeken ... "
							id="global-search" class="form-control" [(ngModel)]="managerDDKeyword">
    <section class="table-content">
        <table class="table table-vertical-align" style="margin-bottom:0;">
            <thead class="thead-dark">
                <tr>
                    <th width="140">Week</th>
                    <th>Werknemer</th>
                    <th width="100">Ma</th>
                    <th width="100">Di</th>
                    <th width="100">Wo</th>
                    <th width="100">Do</th>
                    <th width="100">Vr</th>
                    <th width="100">Za</th>
                    <th width="100">Zo</th>
                    <th width="100">Totaal</th>
                    <th width="180">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let hour of projectHours">
                    <tr class="hour-row">
                        <td>
                            <i (click)="setExpended('')" class="bi bi-chevron-up icon-pointer me-3 pt-1"
                                *ngIf="expendedKey == hour['period']"></i>
                            <i (click)="setExpended(hour['period'])" class="bi bi-chevron-down icon-pointer me-3 pt-1"
                                *ngIf="expendedKey !== hour['period']"></i>
                            <b>Week {{hour['name']}}</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>{{hour['total']}} uur</b></td>
                        <td>
                            <a href="https://api.kloens.app/projectHours/pdf/{{project['id']}}/{{hour['period']}}"
                                target="_blank" class="me-3 d-inline-block only-for-admin"><i
                                    class="bi bi-file-earmark-pdf"></i></a>
                            <span (click)="confirmDeleteWeek(project, hour['period'])"
                                class="me-3 row-icon delete2-icon only-for-admin">
                                <i class="bi bi-trash-fill"></i>
                            </span>
                            <span (click)="addWeek()" class="me-3">
                                <i class="bi bi-plus-lg add2-icon row-icon"></i>
                            </span>
                        </td>
                    </tr>

                    <ng-container  *ngFor="let userHour of hour['hours']; let index = index; let last = last;">
                        <tr *ngIf="userIncluded(userHour['userName'])"
                        class="user-hour-row" [ngClass]="{'d-none': expendedKey !== hour['period'],  last: last}">
                        <td></td>
                        <td>{{userHour['userName']}}</td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-monday"
                                (focus)="selectAll($event)" (keyup.enter)="nextRow(hour, userHour, index, 'monday')"
                                (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                [(ngModel)]="userHour['monday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-tuesday"
                                (focus)="selectAll($event)" (keyup.enter)="nextRow(hour, userHour, index, 'tuesday')"
                                (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                [(ngModel)]="userHour['tuesday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-wednesday"
                                (focus)="selectAll($event)" (keyup.enter)="nextRow(hour, userHour, index, 'wednesday')"
                                (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                [(ngModel)]="userHour['wednesday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-thursday"
                                (focus)="selectAll($event)" (keyup.enter)="nextRow(hour, userHour, index, 'thursday')"
                                (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                [(ngModel)]="userHour['thursday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-friday"
                                (focus)="selectAll($event)" (keyup.enter)="nextRow(hour, userHour, index, 'friday')"
                                (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                [(ngModel)]="userHour['friday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-saturday"
                                (focus)="selectAll($event)" (keyup.enter)="nextRow(hour, userHour, index, 'saturday')"
                                (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                [(ngModel)]="userHour['saturday']"></td>
                        <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-sunday"
                                (focus)="selectAll($event)" (keyup.enter)="nextRow(hour, userHour, index, 'sunday')"
                                (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                [(ngModel)]="userHour['sunday']"></td>
                        <td><b>{{userHour['total']}} uur</b></td>
                        <td>
                            <span (click)="confirmDelete(userHour)"
                                *ngIf="currentUser['role'] == 'ADMIN' || currentUser['role'] == 'MANAGER'"
                                class="me-3 row-icon delete2-icon">
                                <i class="bi bi-trash-fill"></i>
                            </span>

                            <span (click)="editHoursNote(userHour['id'],userHour['note'])" style="cursor: pointer;">
                                <i class="bi bi-pencil-square" [ngClass]="userHour['note'] == null ? 'empty' : 'notEmpty' "></i>
                            </span>
                        </td>
                    </tr>
                    </ng-container>
                    
                    <tr class="user-hour-row" [ngClass]="{'d-none': expendedKey !== hour['period']}">
                        <td></td>
                        <td colspan="11" (click)="addUserToPeriod(hour, hour['week'], hour['year'])"
                            class="cursor-pointer">+ Werknemer toevoegen</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </section>
</section>