<header class="page-header">
	<div class="row">
		<div class="col-8">
			<h1>Projecten</h1>
			<h6>Projecten / overzicht</h6>
		</div>
		<div class="col-4 text-end pt-3">
		</div>
	</div>
</header>
<app-table-guru 
	[refresh]="refresh"
	 [link]="link"
	 [mapping]="mapping"
	 [editPath]="editPath" 
></app-table-guru>