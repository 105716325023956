import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
	selector: 'app-edit-klic-note',
	templateUrl: './edit-klic-note.component.html',
	styleUrls: ['./edit-klic-note.component.scss']
})
export class EditKlicNote implements OnInit {
	@Input() public project;
	@Input() public target;
	@Input() public type;
	@Input() public currentUser;
	
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	public loaderOverlay = false;
	public statusOptions;
	public selectedStatus = 'NVT';
	public currentTab = 'info';

	constructor(
		public activeModal: NgbActiveModal,
		public appService: AppService,
		public api: ApiConnectionService,
		public config: ConfigService
	) {

	}

	ngOnInit(): void {
		
	}

	confirm() {
		let self = this;

		self.loaderOverlay = true;

		self.appService.setLoaderStatus(true);


		return this.api.save('projects/saveKlicNote/' + self.project['id'], {
			klicNote: self.project['klicNote'],
		}).then((response) => {
			self.loaderOverlay = false;

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageType('SUCCESS');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);

		}).catch(function (response) {
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);
		});
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}
}
