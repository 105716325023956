import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

import { ProjectHoursNotesComponent } from '../projects-detail/project-hours/project-hours-notes/project-hours-notes.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.scss']
})
export class HoursComponent implements OnInit {
  projectHours;
  weeks = [];
  years = [];

  startWeek;
  startYear;
  endWeek;
  endYear;

  public userDetailId;
  public userDetailPeriod;
  public userDetails;
  public expendedKey = '';

  public filterName = '';
  public activeFilter = '';
  public suggestionList = [];

  public isFiltered = false;

  constructor(
    private api: ApiConnectionService,
    private appService: AppService,
    public renderer: Renderer2,
    private modalService: NgbModal
  ) {
    let currentDate = new Date;
    currentDate.setDate(currentDate.getDate() - 7);

    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let tmp1 = 0;
    let tmp2 = 0;
    tmp1 = currentDate.getTime();
    tmp2 = startDate.getTime();

    var days = Math.floor((tmp1 - tmp2) /
      (24 * 60 * 60 * 1000));

    var weekNumber: any = Math.ceil(days / 7);
    if(weekNumber < 10) {
      if(weekNumber == 0) {
        weekNumber++;
      }

      weekNumber = "0" + weekNumber;
    }

    this.startWeek = weekNumber;
    this.startYear = currentDate.getFullYear();

    let currentDate2 = new Date;

    let startDate2 = new Date(currentDate2.getFullYear(), 0, 1);
    let tmp12 = 0;
    let tmp22 = 0;
    tmp12 = currentDate2.getTime();
    tmp22 = startDate2.getTime();

    var days2 = Math.floor((tmp12 - tmp22) /
      (24 * 60 * 60 * 1000));

    var weekNumber2: any = Math.ceil(days2 / 7);
    if(weekNumber2 < 10) {
      weekNumber2 = "0" + (weekNumber2 + 1);
    }

    this.endWeek = weekNumber2;
    this.endYear = currentDate2.getFullYear();

    for (let i = 1; i <= 53; i++) {
      let str = i.toString();
      if (i < 10) {
        str = '0' + i;
      }
      this.weeks.push(str);
    }
    var curr = new Date;
    let maxYear = curr.getFullYear();

    maxYear++;
    for (let i = maxYear; i > 2021; i--) {
      let str = i.toString();
      this.years.push(str);
    }

    this.renderer.listen('window', 'click', (evt) => {
      // if (this.filterName != '') {
        // this.filterName = '';
      // }
    });
  }

  editHoursNote(project, text) {
    let self = this;

    const filterModal = this.modalService.open(ProjectHoursNotesComponent, {
      size: "lg",
      windowClass: "second-modal",
      backdropClass: "second-modal-backdrop",
      backdrop: "static",
    });

    filterModal.componentInstance.project = project;
    filterModal.componentInstance.text = text;
    // filterModal.componentInstance.target = "" + self.fileType + "";
    // filterModal.componentInstance.projectFile = projectFile;
    filterModal.result.then((result) => { self.getHours(); }).catch((error) => {
      self.getHours();
    });
  }
  searchNames() {
    this.suggestionList = [];
    this.activeFilter = this.filterName;
    for (let period = 0; period < this.projectHours.length; period++) {
      for (let i = 0; i < this.projectHours[period].hours.length; i++) {
        const entry = this.projectHours[period].hours[i];
        if ((entry.userName).toLowerCase().includes(this.filterName.toLowerCase())) {
          if (!this.suggestionList.includes(entry.userName)) {
            this.suggestionList.push(entry.userName);
          }
        }
      }
    }
  }

  clearSearch() {
  }

  ngOnInit(): void {
    this.appService.setPageTitle('Urenregistratie');
    this.appService.setNavKey('hours');
    this.getHours();
  }

  canShow(username) {
    if (this.filterName != '') {
      if ((username).toLowerCase().includes(this.filterName.toLowerCase())) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  setFilter(name) {
    this.activeFilter = name;
    this.filterName = name;
    this.isFiltered = true;
  }

  tryReset() {
    if (this.isFiltered) {
      this.isFiltered = false;
      this.filterName = "";
      this.activeFilter = "";
    }
  }

  setExpended(key) {
    this.expendedKey = key;
    this.userDetailId = '';
    this.userDetailPeriod = '';
    this.userDetails = '';
  }

  selectAll(event) {
    event.target.select();
  }

  setUserDetailId(userId, period) {
    this.userDetailId = userId;
    this.userDetailPeriod = period;
    this.getUserDetails();
  }

  getUserDetails() {
    let self = this;
    this.api.read('projectHours/userWeek/' + self.userDetailId + '/' + self.userDetailPeriod + '').then((response) => {
      self.userDetails = response['items'];
    });
  }

  getHours() {
    let self = this;
    this.api.read('projectHours/list/' + self.startYear + '' + self.startWeek + '/' + self.endYear + '' + self.endWeek + '').then((response) => {
      self.projectHours = response['items'];
    });
  }

  clearUserDetailId() {
    this.userDetailId = '';
    this.userDetailPeriod = '';
  }

  dateString(week) {
    var curr = new Date; // get current date

    let y = this.startYear;
    var d = new Date("Jan 01, " + y + " 01:00:00");
    var dayMs = (24 * 60 * 60 * 1000);
    var offSetTimeStart = dayMs * (d.getDay() - 1);
    var w = d.getTime() + 604800000 * (week - 1) - offSetTimeStart; //reducing the offset here
    var n1 = new Date(w);
    var n2 = new Date(w + 518400000);

    let day = (n1.getDate() < 10 ? '0' + n1.getDate().toString() : n1.getDate().toString());
    let month = n1.getMonth();
    month = (month + 1);
    let monthStr = (month < 10 ? '0' + month.toString() : month.toString());

    var firstday = day + '-' + monthStr + '-' + n1.getFullYear();
    return firstday;
  }

}
