<div>
    <div class="modal-header">
        <h4 class="modal-title">Datum</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-5"> 
        <div class="row mb-3">
            <div class="col-12">
                Startdatum:<br/>
                <input class="form-control" type="date" placeholder="" [(ngModel)]="object['startAt']" />
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                Eindddatum:<br/>
                <input class="form-control" type="date" placeholder="" [(ngModel)]="object['endAt']" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                Omschrijving:<br/>
                <input class="form-control" type="text" placeholder="" [(ngModel)]="object['description']" />
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="object">
        <button (click)="confirm()" class="btn btn-success with-icon">
            Opslaan <i class="bi bi-arrow-right"></i>
        </button>
    </div>  
</div>