import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';
import { WeekFormComponent } from 'src/app/components/week-form/week-form.component';
import { AppService } from 'src/app/services/app.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-project-notes',
  templateUrl: './project-notes.component.html',
  styleUrls: ['./project-notes.component.scss']
})
export class ProjectNotesComponent implements OnInit {
  @Input() public project;
  items;

  currentUser = null;

  constructor(private api: ApiConnectionService,
    private _elementRef: ElementRef,
    private app: AppService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    const self = this;
    this.authenticationService.getCurrentUser().then(function (data) {
      self.authenticationService.data.subscribe(data => {
        if (Object.keys(data).length) {
          self.currentUser = data;
        } else {
          self.currentUser = false;
        }
      });

    });

    this.all();
    self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
			if (Object.keys(data).length) {
				self.currentUser = data;
			} else {
				self.currentUser = false;
			}
		}); 
  }

  update(item) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api.save('projectNotes/update/' + self.project['id'] + '', {
      item
    }).then(function (response) {
      item['id'] = response['item']['id'];
      item['createdAtText'] = response['item']['createdAtText'];
      item['createdByName'] = response['item']['createdByName'];
      self.app.setLoaderStatus(false);
      self.app.setSystemMessageLoading(true);
      self.app.setSystemMessageText('Wijzigingen opgeslagen');
      self.app.setSystemMessageType('SUCCESS');
    }).catch(function (error) {
      self.app.setLoaderStatus(false);
    });
  }

  addRow() {
    this.items.push({
      id: '',
      content: '',
      createdByName: this.currentUser.name
    });
  }

  confirmDelete(item) {
    let self = this;

    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message = 'Weet je zeker dat je dit element wilt verwijderen?';
    deleteModal.result.then((result) => {
      if (result) {
        this.delete(item);
      }
    });
  }

  delete(item) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api.delete('projectNotes/delete/' + item.id + '').then((response) => {
      self.app.setLoaderStatus(false);
      self.app.setSystemMessageLoading(true);
      self.app.setSystemMessageText('Regel verwijderd');
      self.app.setSystemMessageType('DANGER');
      self.all();
    }).catch(function (error) {
      self.app.setLoaderStatus(false);
    });
  }

  all() {
    let self = this;
    let id = self.project['id'];

    if (!id) {
      return;
    }

    this.api.read('projects/details/' + this.project['id'] + '/notes').then(function (response) {
      self.items = response['items'];
    });
  }

}
