<div class="col-8 mb-3">
    <h1>Bestandsviewer Applicatie</h1>
    <h6>Door de Kloens bestandsviewer applicatie te gebruiken kun je documenten vanuit het Kloens-portal rechtstreeks
        openen
        op je computer.</h6>
    <button class="btn btn-success with-icon">
        Download <i class="bi bi-save"></i>
    </button>
</div>
<div class="container-fluid" style="display: flex;">
    <div class="col-6">
        <ol>
            <li>
                Download de bestandsviewer applicatie door op de knop hierboven te klikken.
            </li>
            <li>
                Open de bestandsviewer, en ga naar het tabblad 'instellingen' rechtsboven.
            </li>
            <li>
                Er zijn hier twee opties die moeten worden aangepast, de API end-point en het basis pad.
            </li>
            <li>
                De API end-point is een URL dat de applicatie kan gebruiken om te praten met het Kloens-portal. Verander dit optieveld naar <span>{{api.configService.get('api_url')}}</span> en klik op <b>update</b>.
            </li>
            <li>Het basis-pad is het directory pad van de hardeschijf waar de Kloens bestanden op staan. Dit is waarschijnlijk je K-schijf.</li>
        </ol>
    </div>
    <div class="col-6" style="display: flex; align-items: center; flex-direction: column; justify-content: flex-end;">
        <img src="../assets/images/bestandsviewer.jpg" width="550px" alt="preview">
        <img src="../assets/images/bestandsviewer-settings.jpg" width="550px" alt="preview">
    </div>
</div>