<section [ngClass]="{'loader-overlay': loaderOverlay}">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Categorie toevoegen</h4>
		<button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
	</div>
	<div class="modal-body">
		<div class="row">
            <div class="col-12 p-5">
                Naam:<br/>
                <input class="form-control" type="text" placeholder="" [(ngModel)]="name" />
            </div>
        </div>
	</div>
	<div class="modal-footer">
		<button (click)="cancel()" type="submit" class="btn btn btn-link me-3" style="text-decoration:none ! important;color:#333333;">Annuleren</button>
		<button (click)="confirm()" type="submit" class="btn btn-success">Toevoegen</button>
	</div>
</section>