import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';
import { WeekFormComponent } from 'src/app/components/week-form/week-form.component';
import { AppService } from 'src/app/services/app.service';

import { FilePreviewComponent } from 'src/app/modals/file-preview/file-preview.component';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-project-journal-mobile',
  templateUrl: './project-journal-mobile.component.html',
  styleUrls: ['./project-journal-mobile.component.scss']
})
export class ProjectJournalMobileComponent implements OnInit {
  @Input() public project;
  journalItems;
  public expendedKey = '';

  files: File[] = [];
  projectFiles;
  selectedFiles = {};

  currentUser;

  constructor(
    private api: ApiConnectionService,
    private _elementRef: ElementRef,
    private app: AppService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService
  ) {

  }

  ngOnInit(): void {
    const self = this;

    self.authenticationService.getCurrentUser();
    self.authenticationService.data.subscribe(data => {
      if (Object.keys(data).length) {
        self.currentUser = data;
      } else {
        self.currentUser = false;
      }
    });
  }

  onSelect(event, id) {
    let self = this;
    this.files.push(...event.addedFiles);

    const formData = new FormData();

    for (var i = 0; i < this.files.length; i++) {
      formData.append("file[]", this.files[i]);
    }

    self.app.setLoaderStatus(true);
    this.api
      .saveFile(
        "projectPhotos" + "/upload/" + this.project["id"] + "&projectJournalId=" + id,
        formData
      )
      .then(function (response) {
        self.files = [];
        self.all();
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Bestanden zijn opgeslagen");
        self.app.setSystemMessageType("SUCCESS");
      });
  }

  addWeek() {
    let self = this;
    const modal = this.modalService.open(WeekFormComponent, {});
    modal.componentInstance.targetUrl = 'projectJournals/addWeek/' + this.project['id'];

    modal.result.then((result) => {
      if (result == false) { return; }
      self.expendedKey = result['period'];
      self.all();
    })
      .catch((error) => {

      });
  }

  confirmDeleteWeek(item, period) {
    let self = this;

    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message = 'Weet je zeker dat je dit element wilt verwijderen?';
    deleteModal.result.then((result) => {
      if (result) {
        this.deleteWeek(item.id, period);
      }
    });
  }

  deleteWeek(projectId, period) {
    let self = this;

    self.app.setLoaderStatus(true);
    this.api.delete('projectJournals/deletePeriod/' + projectId + '/' + period + '').then((response) => {
      self.app.setLoaderStatus(false);
      self.app.setSystemMessageLoading(true);
      self.app.setSystemMessageText('Week verwijderd');
      self.app.setSystemMessageType('DANGER');

      self.all();
    }).catch(function (error) {
      self.app.setLoaderStatus(false);
    });
  }

  ngOnChanges(changes: any) {
    this.all();
  }

  setExpended(key) {
    this.expendedKey = key;
  }

  updateJournal(journal) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api.save('projectJournals/update/' + journal.id + '', {
      journal
    }).then(function (response) {
      self.app.setLoaderStatus(false);
      self.app.setSystemMessageLoading(true);
      self.app.setSystemMessageText('Wijzigingen opgeslagen');
      self.app.setSystemMessageType('SUCCESS');
    }).catch(function (error) {
      self.app.setLoaderStatus(false);
    });
  }

  delete(object) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .delete("projectPhotos" + "/delete/" + object.id + "")
      .then((response) => {
        self.all();
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageType("DANGER");
        self.app.setSystemMessageText("Bestand verwijderd");
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });
  }

  confirmDelete2(object) {
    let self = this;

    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent, {
      windowClass: "second-modal",
      backdropClass: "second-modal-backdrop",
    });
    deleteModal.componentInstance.message =
      "Weet je zeker dat je bestanden wilt verwijderen?";
    deleteModal.result.then((result) => {
      if (result) {
        self.delete(object);
      }
    });
  }

  showPreview(file, fileList) {
    let link =
      '<iframe src="' +
      file["viewUrl"] +
      '" style="width:100%;height:600px;"></iframe>';
    if (file["isImage"]) {
      link =
        '<img src="' + file["viewUrl"] + '" style="width:100%;height:100%;">';
    }
    const modal = this.modalService.open(FilePreviewComponent, {
      size: "xl",
      backdropClass: 'second-modal-backdrop',
      windowClass: 'second-modal'
    });
    modal.componentInstance.message = link;
    modal.componentInstance.file = file;
    modal.componentInstance.fileList = fileList;
    modal.result.then((result) => { }).catch((error) => { });
  };

  all() {
    let self = this;
    let id = self.project['id'];

    if (!id) {
      return;
    }

    this.api.read('projects/details/' + this.project['id'] + '/journals').then(function (response) {
      self.journalItems = response['items'];
      self.journalItems.forEach(async (item) => {
        if (self.expendedKey === '') {
          self.expendedKey = item['period'];
        }
      });
    });
  }
}