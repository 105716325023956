<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title" style="font-size:16px;">E-mail P{{project['number']}}</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1">
        <div class="row">
            <div class="col-4 pe-0">
                <div  class="mt-2">
                    <div *ngFor="let mail of mails">
                        <div class="px-3 pt-1 pb-2 mb-1" (click)="setCurrentMail(mail)" style="cursor:pointer;border-bottom:1px solid #EEEEEE;">
                            <span *ngIf="mail['date'] != currentMail['date']"><small>{{mail['date']}}</small><br/>{{mail['subject']}}</span>
                            <strong *ngIf="mail['date'] == currentMail['date']"><small>{{mail['date']}}</small><br/>{{mail['subject']}}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8 ps-0">
                <div *ngIf="currentMail" style="border-left:1px solid #EEEEEE;padding-top:15px;padding-left:20px;padding-bottom:30px;">
                    Van: {{currentMail['authorName']}} <{{currentMail['authorEmail']}}><br/>
                    Aan: {{currentMail['to']}}<br/>
                    BCC: {{currentMail['bcc']}}<br/>
                    CC: {{currentMail['cc']}}<br/>
                    Offertebestand: {{ currentMail['fileNames'].join(', ') }}<br/><br/>
                    Onderwerp: {{currentMail['subject']}}<br/><br/>
                    <div [innerHTML]="currentMail['body']"></div>
                </div>
            </div>
        </div>
    </div>
</div>