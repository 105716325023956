<header class="page-header">
    <div class="row">
        <div class="col-6">
            <h1>Dashboard</h1>
            <h6>Welkom terug, {{currentUser['name']}}</h6>
        </div>
        <div class="col-6 relative">
            <section class="filter-container">
                <span class="btn btn-filter btn-filter-add btn-white with-icon icon-right me-2" style="font-weight:600;"
                    (click)="addFilter()"><i class="bi bi-plus text-success" style="font-weight:bold;"></i>
                    Filter</span>
                <span class="btn btn-filter py-0 me-2" style="font-weight:600;" *ngFor="let filter of filters">
                    <span class="edit-filter" (click)="editFilter(filter['key'])">
                        <i class="bi bi-sliders2 text-success with-icon icon-right me-2"></i> <span> {{filter['label']}}
                            <small class="text-muted">{{filter['valueText']}}</small></span>
                    </span>
                    <span class="remove-filter" (click)="removeFilter(filter['key'])"><i class="bi bi-trash"></i></span>
                </span>
            </section>
        </div>
    </div>
</header>

<div class="row manager user" *ngIf="currentUser['role'] === 'MANAGER' || currentUser['role'] === 'USER'">
    <div class="col-md-12 col-lg-6 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>In uitvoering</h2>
                        <h6>Projecten in uitvoering.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="inProgressProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{inProgressProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of inProgressProjects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div class="d-flex">
                            <p><small class="text-muted">Afgerond op</small></p>
                            <p *ngIf="project['endAt']">{{project['endAtText']}}</p>
                            <p *ngIf="!project['endAt']">Onbekend</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Open projecten</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-6 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Ingepland</h2>
                        <h6>Aankomende projecten.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="planProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{planProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of planProjects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div class="d-flex">
                            <p><small class="text-muted">Afgerond op</small></p>
                            <p *ngIf="project['endAt']">{{project['endAtText']}}</p>
                            <p *ngIf="!project['endAt']">Onbekend</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Open projecten</button>
        </div>
    </div>
</div>

<div class="row adriaan" *ngIf="currentUser['role'] === 'ADMIN' && currentUser['dashboardType'] === 'ADRIAAN'">
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Openstaand offertes</h2>
                        <h6>Offertes en offertes in behandeling.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="pendingQuotations.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{pendingQuotations.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let quote of pendingQuotations"
                    [routerLink]="['/projects/detail/'+quote['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{quote.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{quote['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div>
                            <p><small class="text-muted">Aangevraagd op</small></p>
                            <p *ngIf="quote['requestedAt']">{{quote['requestedAtText']}}</p>
                            <p *ngIf="!quote['requestedAt']">Onbekend</p>
                            <p [ngClass]="{
                                'text-danger': quote['quoteAge'] > 5
                            }">{{quote['quoteAgeText']}}</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/quotes']">Offertelijst bekijken</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Nog in te plannen</h2>
                        <h6>Niet ingeplande offertes</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="projects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{projects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of projects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div>
                            <p><small class="text-muted">Geaccepteerd op</small></p>
                            <p *ngIf="project['requestedAt']">{{project['quoteAcceptedAtText']}}</p>
                            <p *ngIf="!project['requestedAt']">Onbekend</p>
                            <p [ngClass]="{
                                'text-danger': project['quoteAcceptedAge'] > 5
                            }">{{project['quoteAcceptedAgeText']}}</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton d-none d-lg-inline-block" [routerLink]="['/calendar']">Open planning</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Technisch gereed</h2>
                        <h6>Projecten met status technisch gereed.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="finishedProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{finishedProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of finishedProjects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div class="d-flex">
                            <p><small class="text-muted">Afgerond op</small></p>
                            <p *ngIf="project['endAt']">{{project['endAtText']}}</p>
                            <p *ngIf="!project['endAt']">Onbekend</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Open projecten</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Kostenoverzicht verstuurd</h2>
                        <h6>Projecten met kosten verstuurd.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="costsProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{costsProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of costsProjects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div class="d-flex">
                            <p><small class="text-muted">Afgerond op</small></p>
                            <p *ngIf="project['endAt']">{{project['endAtText']}}</p>
                            <p *ngIf="!project['endAt']">Onbekend</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Open projecten</button>
        </div>
    </div>
</div>

<div class="row johan" *ngIf="currentUser['role'] === 'ADMIN' && currentUser['dashboardType'] === 'JOHAN'">
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Technisch gereed</h2>
                        <h6>Projecten met status technisch gereed.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="finishedProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{finishedProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of finishedProjects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div class="d-flex">
                            <p><small class="text-muted">Afgerond op</small></p>
                            <p *ngIf="project['endAt']">{{project['endAtText']}}</p>
                            <p *ngIf="!project['endAt']">Onbekend</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Open projecten</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Kostenoverzicht verstuurd</h2>
                        <h6>Projecten met kosten verstuurd.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="costsProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{costsProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of costsProjects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div class="d-flex">
                            <p><small class="text-muted">Afgerond op</small></p>
                            <p *ngIf="project['endAt']">{{project['endAtText']}}</p>
                            <p *ngIf="!project['endAt']">Onbekend</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Open projecten</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Termijnstaat</h2>
                        <h6>Termijnstaat verstuurd.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="periodProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{periodProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let quote of periodProjects"
                    [routerLink]="['/projects/detail/'+quote['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{quote.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{quote['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div>
                            <p><small class="text-muted">Aangevraagd op</small></p>
                            <p *ngIf="quote['requestedAt']">{{quote['requestedAtText']}}</p>
                            <p *ngIf="!quote['requestedAt']">Onbekend</p>
                            <p [ngClass]="{
                                'text-danger': quote['quoteAge'] > 5
                            }">{{quote['quoteAgeText']}}</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Naar projecten</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Factuur verstuurd</h2>
                        <h6>Factuur verstuurd</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="invoicedProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{invoicedProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of invoicedProjects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div>
                            <p><small class="text-muted">Geaccepteerd op</small></p>
                            <p *ngIf="project['requestedAt']">{{project['quoteAcceptedAtText']}}</p>
                            <p *ngIf="!project['requestedAt']">Onbekend</p>
                            <p [ngClass]="{
                                'text-danger': project['quoteAcceptedAge'] > 5
                            }">{{project['quoteAcceptedAgeText']}}</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Naar projecten</button>
        </div>
    </div>
</div>


<div class="row finance" *ngIf="currentUser['role'] === 'ADMIN' && currentUser['dashboardType'] === 'FINANCE'">
    <div class="col-md-12 col-lg-4 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Kostenoverzicht verstuurd</h2>
                        <h6>Projecten met kosten verstuurd.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="costsProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{costsProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of costsProjects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div class="d-flex">
                            <p><small class="text-muted">Afgerond op: </small></p>
                            <p *ngIf="project['endAt']">{{project['endAtText']}}</p>
                            <p *ngIf="!project['endAt']">Onbekend</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Open projecten</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-4 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Termijnstaat</h2>
                        <h6>Termijnstaat verstuurd.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="periodProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{periodProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let quote of periodProjects"
                    [routerLink]="['/projects/detail/'+quote['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{quote.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{quote['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div>
                            <p><small class="text-muted">Aangevraagd op</small></p>
                            <p *ngIf="quote['requestedAt']">{{quote['requestedAtText']}}</p>
                            <p *ngIf="!quote['requestedAt']">Onbekend</p>
                            <p [ngClass]="{
                                'text-danger': quote['quoteAge'] > 5
                            }">{{quote['quoteAgeText']}}</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Naar projecten</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-4 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Factuur verstuurd</h2>
                        <h6>Factuur verstuurd</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="invoicedProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{invoicedProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of invoicedProjects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div>
                            <p><small class="text-muted">Geaccepteerd op</small></p>
                            <p *ngIf="project['requestedAt']">{{project['quoteAcceptedAtText']}}</p>
                            <p *ngIf="!project['requestedAt']">Onbekend</p>
                            <p [ngClass]="{
                                'text-danger': project['quoteAcceptedAge'] > 5
                            }">{{project['quoteAcceptedAgeText']}}</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Naar projecten</button>
        </div>
    </div>
</div>

<div class="row normal" *ngIf="currentUser['role'] === 'ADMIN' && currentUser['dashboardType'] == 'NORMAL'">
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Openstaand offertes</h2>
                        <h6>Offertes en offertes in behandeling.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="pendingQuotations.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{pendingQuotations.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let quote of pendingQuotations"
                    [routerLink]="['/projects/detail/'+quote['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{quote.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{quote['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div>
                            <p><small class="text-muted">Aangevraagd op</small></p>
                            <p *ngIf="quote['requestedAt']">{{quote['requestedAtText']}}</p>
                            <p *ngIf="!quote['requestedAt']">Onbekend</p>
                            <p [ngClass]="{
                                'text-danger': quote['quoteAge'] > 5
                            }">{{quote['quoteAgeText']}}</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/quotes']">Offertelijst bekijken</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Nog in te plannen</h2>
                        <h6>Niet ingeplande offertes</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="projects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{projects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of projects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div>
                            <p><small class="text-muted">Geaccepteerd op</small></p>
                            <p *ngIf="project['requestedAt']">{{project['quoteAcceptedAtText']}}</p>
                            <p *ngIf="!project['requestedAt']">Onbekend</p>
                            <p [ngClass]="{
                                'text-danger': project['quoteAcceptedAge'] > 5
                            }">{{project['quoteAcceptedAgeText']}}</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton d-none d-lg-inline-block" [routerLink]="['/calendar']">Open planning</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>In uitvoering</h2>
                        <h6>Projecten met status in uitvoering.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="inProgressProjects.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{inProgressProjects.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of inProgressProjects"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div>
                            <p><small class="text-muted">Afgerond op</small></p>
                            <p *ngIf="project['endAt']">{{project['endAtText']}}</p>
                            <p *ngIf="!project['endAt']">Onbekend</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Open projecten</button>
        </div>
    </div>
    <div class="col-md-12 col-lg-3 mb-4 mb-lg-0">
        <div class="card">
            <header class="">
                <div class="row">
                    <div class="col-9">
                        <h2>Geen documenten</h2>
                        <h6>Ingepland zonder documenten.</h6>
                    </div>
                    <div class="col-3">
                        <span class="" *ngIf="noDocuments.length > 0"
                            style="height:50px;width:50px;border-radius:50%;float:right;line-height:50px;text-align:center;display:block;background:#d9f3ea;">
                            <span style="color:#00B074;font-weight:bold;">{{noDocuments.length}}</span>
                        </span>
                    </div>
                </div>
            </header>
            <section class="list-items">
                <div class="row itemContainer" *ngFor="let project of noDocuments"
                    [routerLink]="['/projects/detail/'+project['id']+'']">
                    <div class="col-sm-6 col-md-4 col-lg-12">
                        <strong class="d-block">{{project.name}}</strong>
                        <small class="text-primary"><i class="bi bi-people pe-1 d-inline-block"></i>
                            {{project['organisation']['name']}}</small>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-10 ">
                        <div>
                            <p><small class="text-muted">Afgerond op</small></p>
                            <p *ngIf="project['endAt']">{{project['endAtText']}}</p>
                            <p *ngIf="!project['endAt']">Onbekend</p>
                        </div>
                    </div>
                    <div class="col-sm-2 col-md-4 col-lg-2 center right ">
                        <i class="bi bi-chevron-right pe-2"></i>
                    </div>
                </div>
            </section>
            <button class="hyperlinkButton" [routerLink]="['/projects']">Open projecten</button>
        </div>
    </div>
</div>

<section class="d-none d-lg-block">
    <div class="row mt-5 d-md-flex">
        <div class="col-8">
            <h3>Week {{currentWeek}}, {{currentYear}}</h3>
        </div>
        <div class="col-4 d-flex d-lg-block flex-row-reverse">
            <span class="btn btn-light float-end" style="background-color:#EEEEEE;" (click)="next()"><i
                    class="bi bi-arrow-right"></i></span>
            <span class="btn btn-light float-end" style="background-color:#EEEEEE;" (click)="prev()"><i
                    class="bi bi-arrow-left"></i></span>
        </div>
    </div>

    <div class="row mt-3 mb-3 d-md-flex" id="weekContainer">
        <div class="col-6 col-lg mb-2 mb-lg-0 weekCard shadow-small" *ngFor="let day of weekOverview1">
            <div class="weekHead">
                {{getDutchWeekday(day.date)}}
                <small>{{dutchDateFormat(day.date)}}</small>
            </div>
            <div class="weekBody">
                <div class="weekEntry" *ngFor="let entry of day.data"
                    [routerLink]="['/projects/detail/'+entry['id']+'']" style="cursor:pointer;">
                    <span [ngStyle]="{'background-color': entry['color']}">{{entry.letter}}</span>
                    <p>{{entry.name}}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="row d-md-block">
        <h3>Week {{nextWeek}}, {{nextYear}}</h3>
    </div>

    <div class="row mt-1 mb-5 d-none d-lg-flex" id="weekContainer">
        <div class="col weekCard shadow-small" *ngFor="let day of weekOverview2">
            <div class="weekHead">
                {{getDutchWeekday(day.date)}}
                <small>{{dutchDateFormat(day.date)}}</small>
            </div>
            <div class="weekBody">
                <div class="weekEntry" *ngFor="let entry of day.data"
                    [routerLink]="['/projects/detail/'+entry['id']+'']" style="cursor:pointer;">
                    <span [ngStyle]="{'background-color': entry['color']}">{{entry.letter}}</span>
                    <p>{{entry.name}}</p>
                </div>
            </div>
        </div>
    </div>
</section>