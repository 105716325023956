import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { DomSanitizer} from '@angular/platform-browser';
import { AddFilterComponent } from '../../components/table-guru/modals/add-filter/add-filter.component';
import { EditFilterComponent } from '../../components/table-guru/modals/edit-filter/edit-filter.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';


@Component({
  selector: 'app-projects-maps',
  templateUrl: './projects-maps.component.html',
  styleUrls: ['./projects-maps.component.scss']
})
export class ProjectsMapsComponent implements OnInit {

	loading = true;
  mapsUrl;
  filterOptions = [];
  filters = [];
  path = '';
  limit = 5000;
  projectStatus = ['QUOTE_ACCEPTED', 'PLAN', 'IN_PROGRESS'];
  sortKey = 'userGroupName';
  sortDirection = 'ASC';
  keyword = '';
  lastUrl = '';
  projectIds = [];
  currentUser;

  constructor(
    public appService: AppService,
    public domSanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private apiService: ApiConnectionService,
    public config: ConfigService
  ) { 
    this.mapsUrl = this.transform(this.config.get('api_url') + 'projects/map');
    let self = this;
    self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
			if (Object.keys(data).length) {
				self.currentUser = data;
			} else {
				self.currentUser = false;
			}
		});
  }

  ngOnInit(): void {
	  this.appService.setPageTitle('Kaart');
	  this.appService.setNavKey('map');
    this.getProjects();
  }

  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  addFilter() {
    let self = this;

    const filterModal = this.modalService.open(AddFilterComponent);
    filterModal.componentInstance.filterOptions = self.filterOptions;
    filterModal.result.then((result) => {
      if (result && result.length > 0) {
        self.editFilter(result);
      }
    })
      .catch((error) => {
      });
  }

  getProjects() {
    let filterStorageItem = localStorage.getItem('filters');
    let filterStorage = {};
    if (filterStorageItem) {
      filterStorage = JSON.parse(filterStorageItem);
    }

    let url = "projects";
    url += '&data[limit]=' + this.limit;
    url += '&data[sort]=' + this.sortKey;
    url += '&data[direction]=' + this.sortDirection;
    url += '&origin=maps'

    let filterOption;
    if (this.keyword.length > 1) {
      url += '&data[keyword]=' + this.keyword;
    }

    let filterOptions = [];

    if (this.filters && this.filters.length > 0) {
      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i].values) {
          filterOptions.push('data[filters][' + this.filters[i].key + '][values]=' + encodeURIComponent(this.filters[i].values.join('||')) + '');
        }
      }
      filterOption = filterOptions.join('&');
      // url += '&' + filterOption;

      /*
      * TODO: storage function
      */
      filterStorage[this.path] = { filters: filterOption };

      localStorage.setItem('filters', JSON.stringify(filterStorage));
    }
    else if (filterStorage[this.path]) {
      let values = filterStorage[this.path]['filters'];
      filterOption = values;
    }

    let self = this;
    self.loading = true;

    self.lastUrl = url;

    this.apiService.save(url, filterOption).then((response) => {
      self.loading = false;

      self.limit = response['limit'];
      self.filterOptions = response['filterOptions'];

      if (self.keyword.length < 1) {
        self.keyword = response['keyword'];
      }

      // if (self.filters.length < 1)
      // {
      self.filters = response['filters'];
      // }

      self.sortKey = response['sort'];
      self.sortDirection = response['direction'];

      //code
      let projects = [];
      let items = response['items'];
      for (let x = 0; x < items.length; x++) {
        let project = items[x];
        self.projectIds.push(project['id']);
      }
      this.mapsUrl = this.transform(this.config.get('api_url') + 'projects/map&hash='+self.currentUser['publicHash']+'');
      self.loading = false;
    }).catch(function (error) {
    });
  }

  editFilter(filterKey) {
    let self = this;
    const filterModal = this.modalService.open(EditFilterComponent);
    filterModal.componentInstance.filterOptions = self.filterOptions;
    filterModal.componentInstance.filters = self.filters;
    filterModal.componentInstance.activeFilterKey = filterKey;

    filterModal.result.then((result) => {

      if (result && result['action'] === "remove") {
        this.removeFilter(result['key']);
      }
      else if (result) {
        self.pushFilter(result);
        self.getProjects();
      }
    })
      .catch((error) => {
      });
  }

  removeFilter(key) {
    let self = this;
    if (this.filters) {
      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];
        if (filter['key'] === key) {
          this.filters.splice(i, 1);
        }
      }
    }
    /*
    * TODO: handle in storage function
    */
    if (this.filters.length === 0) {
      let filterStorageItem = localStorage.getItem('filters');
      let filterStorage = {};
      if (filterStorageItem) {
        filterStorage = JSON.parse(filterStorageItem);
      }

      filterStorage[this.path] = {};

      localStorage.setItem('filters', JSON.stringify(filterStorage));
    }
    self.getProjects();
  }

  pushFilter(searchValues) {
    let key = searchValues['key'];
    let values = searchValues['values'];

    if (this.filters) {
      let filterOptions = [];
      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];
        if (filter['key'] === key) {
          this.filters[i] = {
            key: key,
            values: values,
          };

          return true;
        }
      }
    }
    this.filters.push({
      key: key,
      values: values,
    });
    return true;
  }
}
