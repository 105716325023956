import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { SettingsCalculationMutateModalComponent } from '../settings-calculation-mutate-modal/settings-calculation-mutate-modal.component';

@Component({
  selector: 'app-settings-calculation-form',
  templateUrl: './settings-calculation-form.component.html',
  styleUrls: ['./settings-calculation-form.component.scss']
})
export class SettingsCalculationFormComponent implements OnInit {
  item;

  constructor(
		private route: ActivatedRoute,
    private modalService: NgbModal,
    private appService: AppService,
    private api: ApiConnectionService
  ) { }

  ngOnInit(): void {
    this.fetchItem();
  }
	
	fetchItem () {
		let self = this;
		let id = this.route.snapshot.paramMap.get('id');

		this.api.read('calculationTemplates/details/'+id+'').then((response) => {
      this.appService.setPageTitle('Calculatietemplates');
      this.appService.setNavKey('settings');				

			self.item = response;
		});
	}
  
  edit () 
  {
  	let self = this;
    const modal = this.modalService.open(SettingsCalculationMutateModalComponent);
    modal.componentInstance.item = self.item;
    modal.result.then((result) => {
      if (result) {
        
      }
    });    
  }
}