import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-project-hours-notes',
	templateUrl: './project-hours-notes.component.html',
	styleUrls: ['./project-hours-notes.component.scss']
})
export class ProjectHoursNotesComponent implements OnInit {
	@Input() public project;
	@Input() public text;
	@Input() public target;
	@Input() public type;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	public loaderOverlay = false;
	public statusOptions;
	public selectedStatus = 'NVT';
	public currentTab = 'info';

	constructor(
		public activeModal: NgbActiveModal,
		public appService: AppService,
		public api: ApiConnectionService,
		public config: ConfigService,
		private modalService: NgbModal,
	) {

	}

	ngOnInit(): void {
	}

	editHoursNote(project, projectFile) {
		let self = this;

		const filterModal = this.modalService.open(ProjectHoursNotesComponent, {
			size: "lg",
			windowClass: "second-modal",
			backdropClass: "second-modal-backdrop",
			backdrop: "static",
		});

		filterModal.componentInstance.project = project;
		// filterModal.componentInstance.target = "" + self.fileType + "";
		// filterModal.componentInstance.projectFile = projectFile;
		filterModal.result.then((result) => { }).catch((error) => { });
	}

	confirm() {
		let self = this;

		self.loaderOverlay = true;

		self.appService.setLoaderStatus(true);

		return this.api.save('projectHours/updateNote/' + self.project, {
			text: self.text,
		}).then((response) => {
			self.loaderOverlay = false;

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageType('SUCCESS');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);

		}).catch(function (response) {
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);
		});
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}
}
