<header class="page-header">
    <div class="row">
        <div class="col-4">
            <h1>Urenregistratie</h1>
            <h6>Urenregistratie / overzicht</h6>
        </div>
        <div class="col-8 text-end pt-3">
            <div class="">
                <div style="display: inline; position: relative; margin-right: 5px;">
                    <input style="display: inline; width: 150px; margin-right: 5px;"
                        type="text" class="form-control ng-pristine ng-valid" placeholder="Zoeken op naam"
                        [(ngModel)]="filterName">
                    <!-- <input style="display: inline; width: 150px; margin-right: 5px;" [(ngModel)]="activeFilter" type="text"
                        class="form-control ng-pristine ng-valid" placeholder="Zoeken op naam" (input)="searchNames()"
                        [(ngModel)]="filterName">
                    <div class="auto-dropdown" *ngIf="filterName != '' && !isFiltered">
                        <div class="options">
                            <div class="option" *ngFor="let item of suggestionList" (click)="setFilter(item)">
                                {{item}}
                            </div>
                            <div class="option" *ngIf="suggestionList.length == 0">
                                Geen resultaten..
                            </div>
                        </div>
                    </div> -->
                </div>
                <select name="year" (change)="getHours()" [(ngModel)]="startYear" class="form-control"
                    style="display:inline-block;width:100px;margin-right:5px;">
                    <option value="{{year}}" *ngFor="let year of years">
                        {{year}}
                    </option>
                </select>
                <select name="week" (change)="getHours()" [(ngModel)]="startWeek" class="form-control"
                    style="display:inline-block;width:160px;">
                    <option value="{{week}}" *ngFor="let week of weeks">
                        Week {{week}} - ({{ dateString(week) }})
                    </option>
                </select>
                <div class="d-inline-block px-3 py-2">
                    tot
                </div>
                <select name="year" (change)="getHours()" [(ngModel)]="endYear" class="form-control"
                    style="display:inline-block;width:100px;margin-right:5px;">
                    <option value="{{year}}" *ngFor="let year of years">
                        {{year}}
                    </option>
                </select>
                <select name="week" (change)="getHours()" [(ngModel)]="endWeek" class="form-control"
                    style="display:inline-block;width:160px;">
                    <option value="{{week}}" *ngFor="let week of weeks">
                        Week {{week}} - ({{ dateString(week) }})
                    </option>
                </select>
            </div>
        </div>
    </div>
</header>
<section class="table-grid" *ngIf="projectHours && projectHours.length > 0">
    <section class="table-content">
        <table class="table table-vertical-align" style="margin-bottom:0;">
            <thead class="thead-dark">
                <tr>
                    <th width="140">Week</th>
                    <th>Werknemer</th>
                    <th width="100">Ma</th>
                    <th width="100">Di</th>
                    <th width="100">Wo</th>
                    <th width="100">Do</th>
                    <th width="100">Vr</th>
                    <th width="100">Za</th>
                    <th width="100">Zo</th>
                    <th width="100">Totaal</th>
                    <th width="110">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let hour of projectHours">
                    <tr class="hour-row">
                        <td>
                            <i (click)="setExpended('')" class="bi bi-chevron-up icon-pointer me-3 pt-1"
                                *ngIf="expendedKey == hour['period']"></i>
                            <i (click)="setExpended(hour['period'])" class="bi bi-chevron-down icon-pointer me-3 pt-1"
                                *ngIf="expendedKey !== hour['period']"></i>
                            <b>Week {{hour['name']}}</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>{{hour['total']}} uur</b></td>
                        <td>
                        </td>
                    </tr>
                    <ng-container *ngFor="let userHour of hour['hours']; let index = index; let last = last;">

                        <tr class="user-hour-row" *ngIf="canShow(userHour['userName'] + userHour['userFunction']) && userHour['total'] > 0"
                            [ngClass]="{'d-none': expendedKey !== hour['period'],  last: last}">
                            <td></td>
                            <td>
                                {{userHour['userName']}}
                                <br>
                                <small>{{userHour['userFunction']}}</small>
                                <span class="expend"
                                    *ngIf="userHour['userId'] == userDetailId && userHour['period'] == userDetailPeriod"
                                    style="cursor:pointer;" (click)="clearUserDetailId()"><i
                                        class="bi bi-chevron-up pe-2"></i></span>
                                <span class="expend"
                                    *ngIf="userHour['userId'] != userDetailId || userHour['period'] != userDetailPeriod"
                                    style="cursor:pointer;"
                                    (click)="setUserDetailId(userHour['userId'], userHour['period'])"><i
                                        class="bi bi-chevron-down pe-2"></i></span>
                            </td>
                            <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-monday"
                                    (focus)="selectAll($event)" readonly [(ngModel)]="userHour['monday']" [ngClass]="{'lowOpacity': userHour['monday'] == 0}"></td>
                            <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-tuesday"
                                    (focus)="selectAll($event)" readonly [(ngModel)]="userHour['tuesday']" [ngClass]="{'lowOpacity': userHour['tuesday'] == 0}"></td>
                            <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-wednesday"
                                    (focus)="selectAll($event)" readonly [(ngModel)]="userHour['wednesday']" [ngClass]="{'lowOpacity': userHour['wednesday'] == 0}"></td>
                            <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-thursday"
                                    (focus)="selectAll($event)" readonly [(ngModel)]="userHour['thursday']" [ngClass]="{'lowOpacity': userHour['thursday'] == 0}"></td>
                            <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-friday"
                                    (focus)="selectAll($event)" readonly [(ngModel)]="userHour['friday']" [ngClass]="{'lowOpacity': userHour['friday'] == 0}"></td>
                            <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-saturday"
                                    (focus)="selectAll($event)" readonly [(ngModel)]="userHour['saturday']" [ngClass]="{'lowOpacity': userHour['saturday'] == 0}"></td>
                            <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-sunday"
                                    (focus)="selectAll($event)" readonly [(ngModel)]="userHour['sunday']" [ngClass]="{'lowOpacity': userHour['sunday'] == 0}"></td>
                            <td><b>{{userHour['total']}} uur</b></td>
                            <td>
                            </td>
                        </tr>
                        <ng-container
                            *ngIf="userHour['userId'] == userDetailId && userHour['period'] == userDetailPeriod">
                            <tr *ngFor="let userDetail of userDetails; let index = index; let last = last;"
                                class="user-hour-row">
                                <td></td>
                                <td class="text-muted">
                                    <div class="ps-5"><a href="javacript:;"
                                            [routerLink]="'/projects/detail/'+userDetail['projectId']+''">{{userDetail['projectName']}}</a>
                                    </div>
                                </td>
                                <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-monday"
                                        (focus)="selectAll($event)" readonly [(ngModel)]="userDetail['monday']" [ngClass]="{'lowOpacity': userDetail['monday'] == 0}"></td>
                                <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-tuesday"
                                        (focus)="selectAll($event)" readonly [(ngModel)]="userDetail['tuesday']" [ngClass]="{'lowOpacity': userDetail['tuesday'] == 0}"></td>
                                <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-wednesday"
                                        (focus)="selectAll($event)" readonly [(ngModel)]="userDetail['wednesday']" [ngClass]="{'lowOpacity': userDetail['wednesday'] == 0}"></td>
                                <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-thursday"
                                        (focus)="selectAll($event)" readonly [(ngModel)]="userDetail['thursday']" [ngClass]="{'lowOpacity': userDetail['thursday'] == 0}"></td>
                                <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-friday"
                                        (focus)="selectAll($event)" readonly [(ngModel)]="userDetail['friday']" [ngClass]="{'lowOpacity': userDetail['friday'] == 0}"></td>
                                <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-saturday"
                                        (focus)="selectAll($event)" readonly [(ngModel)]="userDetail['saturday']" [ngClass]="{'lowOpacity': userDetail['saturday'] == 0}"></td>
                                <td><input type="text" id="hour-row-{{hour['period']}}-{{index}}-sunday"
                                        (focus)="selectAll($event)" readonly [(ngModel)]="userDetail['sunday']" [ngClass]="{'lowOpacity': userDetail['sunday'] == 0}"></td>
                                <td><b>{{userDetail['total']}} uur</b></td>
                                <td>
                                    <span (click)="editHoursNote(userDetail['id'],userDetail['note'])">
                                        <i [ngClass]="userDetail['note'] == null ? 'empty' : 'notEmpty' "
                                            class="bi bi-pencil-square"></i>
                                    </span>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </section>
</section>