<section class="table-content" *ngIf="loading">
    <div class="text-center py-5">
        <img style="width:140px;" src="/assets/images/loader.gif">
    </div>
</section>
<section *ngIf="!loading">
    <header class="page-header">
        <div class="row">
            <div class="col-12 col-md-8">
                <h1><span *ngIf="item['isQuote']">Offertes</span><span *ngIf="!item['isQuote']">Projecten</span> /
                    {{item['number']}} <small class="category">({{item.categoryName}})</small></h1>
                <h6><span *ngIf="item['isQuote']">Offertes</span><span *ngIf="!item['isQuote']">Projecten</span> /
                    {{item['name']}}</h6>
            </div>
            <div class="col-0 col-md-4 text-end pt-3">
                <a *ngIf="currentUser['role'] == 'ADMIN'" [href]="sanitize( item['folderUrl'] )"
                    class="btn btn-dark with-icon d-none d-lg-inline-block me-2">Open bestanden <i
                        class="bi bi-folder"></i></a>

                <div class="d-none d-md-inline-block dropdown mb-3 me-2" *ngIf="currentUser['role'] == 'ADMIN'">
                    <button class="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        (click)="toggleDropdown()">
                        Acties
                    </button>
                    <ul class="dropdown-menu" [ngClass]="{
                    'show': showDropDown == true
                    }" style="margin-top:0;margin-left:0;">
                        <li class="hide-on-modal"><a class="dropdown-item" target="_blank" href="{{downloadQuotePdf}}"
                                *ngIf="currentUser['role'] == 'ADMIN'">Offerte PDF</a></li>
                        <li class="hide-on-modal"><a class="dropdown-item" target="_blank"
                                href="{{downloadCalculationPdf}}" *ngIf="currentUser['role'] == 'ADMIN'">Bestek PDF</a>
                        </li>
                        <li class="hide-on-modal"><a class="dropdown-item" href="javascript:;"
                                (click)="generateQuote()">Genereer offerte</a></li>
                        <li class="hide-on-modal"><a class="dropdown-item" href="javascript:;"
                                (click)="sendQuote()">Verstuur offerte</a></li>
                        <li class="hide-on-modal"><a class="dropdown-item" href="javascript:;"
                                (click)="sendCosts()">Verstuur kostenoverzicht</a></li>
                        <li class="hide-on-modal d-block d-md-none"><a class="dropdown-item" href="javascript:;"
                                *ngIf="currentUser['role'] == 'ADMIN'" (click)="openForm(item['id'])">Bewerken</a></li>
                    </ul>
                </div>

                <button (click)="openForm(item['id'])" class="btn btn-success with-icon d-none d-sm-inline"
                    *ngIf="currentUser['role'] == 'ADMIN'">
                    Bewerken <i class="bi bi-pencil"></i>
                </button>

            </div>
        </div>
        <div *ngIf="mobileViewport" [innerHTML]="item['statusLabel']" (click)="toggleQuoteChange()"
            class="quote-change cursor-pointer d-sm-block mobileStatusButton"></div>
        <!-- <div class="d-block d-md-none" id="statusBox" *ngIf="quoteChange">
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-project-{{status['css']}}" (click)="changeStatus(status['value'])"
                        *ngFor="let status of statusOptions" style="width:100%">{{status['name']}}</button>
                </div>
            </div>
        </div> -->

        <div class="d-block d-md-none" id="statusBox" *ngIf="quoteChange">
            <div class="row mb-3">
                <div class="col-12">
                    <ng-container *ngFor="let status of item['allowedStatus']">
                        <button class="btn btn-project-{{status['css']}}" (click)="changeStatus(status['key'])"
                            style="width:100%">{{status['value']}}</button>
                    </ng-container>
                </div>
            </div>
        </div>
    </header>

    <div class="alert alert-warning d-flex align-items-center" role="alert"
        *ngIf="currentUser['role'] == 'ADMIN' && item['isInvalidPlanned'] && item['isInvalidQuoteDocuments'] == false">
        <i class="bi bi-exclamation-circle me-2"></i>
        <div>
            Let op: Dit project is ingepland zonder de vereiste documenten.
        </div>
    </div>

    <div class="alert alert-warning d-flex align-items-center" role="alert"
        *ngIf="currentUser['role'] == 'ADMIN' && item['isInvalidQuoteDocuments']">
        <i class="bi bi-exclamation-circle me-2"></i>
        <div>
            Let op: Dit project mist vereiste documenten.
        </div>
    </div>

    <div id="detailContainer" class="mb-3 shadow-small" *ngIf="tabs['info'] || !mobileViewport">
        <div class="row ps-3">
            <div class="col-sm-6">
                <h5>{{item['name']}}</h5>
                <p *ngIf="item['longName']" class="pt-0 pb-3 mb-0" style="margin-top:-6px;">
                    <span class="text-muted">{{item['longName']}}</span>
                    <span class="text-muted" *ngIf="item['externalReference']"> - Externe referentie:
                        {{item['externalReference']}}</span>
                </p>
                <p class="mb-4 dateWrapper">
                    <span clas="d-block d-md-inline-block"><i class="bi bi-calendar3"
                            style="position:relative;top:1px;margin-right:5px;"></i> Aangevraagd op:
                        {{item['requestedAtText']}}</span>
                    <span clas="d-block d-md-inline-block"><i class="bi bi-calendar3 ms-3"
                            style="position:relative;top:1px;margin-right:5px;"></i> Uitvoering op:
                        {{item['startAtText']}} <span *ngIf="item['endAt']">- {{item['endAtText']}}</span></span>
                    <span (click)="quickViewAuthor()" class="d-none d-lg-inline-block ms-3">Aanmaker:
                        <span>{{item['authorName']}}</span></span>
                </p>
            </div>
            <div class="col-sm-6 align-right w100 columnMobile">
                <div style="position: relative; display: flex;" id="statusTop" class="w100"
                    *ngIf="currentUser['role'] == 'ADMIN'  || currentUser['role'] == 'MANAGER'">

                    <div class="contactDetails"
                        style="height: fit-content; padding: 0; margin-right: 10px; margin-left: 10px; max-width: 240px;">
                        <div class="contactWrapper" [ngClass]="{
                            'is-button': item['userGroupId']
                        }">
                            <span class="contact-details-button" *ngIf="item['userGroupId']"
                                (click)="openFormProject()"></span>
                            <span class="contact-details-button" *ngIf="!item['userGroupId']"
                                (click)="openFormProject()"></span>
                            <div style="background-color: #E0F0FA; color: #2D9CDB; border-radius: 5px; padding: 10px;">
                                <p *ngIf="!item['userGroupName']">Onbekend</p>
                                <p *ngIf="item['userGroupName']">Ploeg {{item['userGroupShortName']}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div [innerHTML]="item['statusLabel']" (click)="toggleQuoteChange()"
                        class="quote-change cursor-pointer visible-for-manager d-sm-block mobileStatusButton"></div>
                    <div id="statusBox" class="visible-for-manager" *ngIf="quoteChange">
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-project-{{status['css']}}"
                                    (click)="changeStatus(status['value'])" *ngFor="let status of statusOptions"
                                    style="width:100%">{{status['name']}}</button>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div style="position: relative;" id="quote-wrapper" *ngIf="!mobileViewport">
                    <div [innerHTML]="item['statusLabel']" class="quote-change" (click)="toggleQuoteChange()"></div>
                    <div id="statusBox" *ngIf="quoteChange">
                        <div class="row mb-3">
                            <div class="col-12">
                                <ng-container *ngFor="let status of item['allowedStatus']">
                                    <button class="btn btn-project-{{status['css']}}"
                                        (click)="changeStatus(status['key'])"
                                        style="width:100%">{{status['value']}}</button>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- <div style="position: relative;" id="quote-wrapper" class="visible-for-manager"
                    *ngIf="currentUser['role'] != 'ADMIN' && currentUser['role'] != 'MANAGER'">
                    <div [innerHTML]="item['statusLabel']" class="quote-change" (click)="toggleQuoteChange()"></div>
                    <div id="statusBox" *ngIf="quoteChange">
                        <div class="row mb-3">
                            <div class="col-12">
                                <ng-container *ngFor="let status of statusOptions">
                                    <button class="btn btn-project-{{status['css']}}"
                                        (click)="changeStatus(status['value'])"
                                        *ngIf="status['name'] == 'Offerte geaccepteerd'"
                                        style="width:100%">{{status['name']}}</button>
                                    <button class="btn btn-project-{{status['css']}}"
                                        (click)="changeStatus(status['value'])" *ngIf="status['name'] == 'Ingepland'"
                                        style="width:100%">{{status['name']}}</button>
                                    <button class="btn btn-project-{{status['css']}}"
                                        (click)="changeStatus(status['value'])"
                                        *ngIf="status['name'] == 'In uitvoering'"
                                        style="width:100%">{{status['name']}}</button>
                                    <button class="btn btn-project-{{status['css']}}"
                                        (click)="changeStatus(status['value'])"
                                        *ngIf="status['name'] == 'Technisch gereed'"
                                        style="width:100%">{{status['name']}}</button>
                                    <button class="btn btn-project-{{status['css']}}"
                                        (click)="changeStatus(status['value'])"
                                        *ngIf="status['name'] == 'Ter beoordeling'"
                                        style="width:100%">{{status['name']}}</button>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                </div> -->
            </div>
        </div>

        <div class="d-none project-contact-wrapper d-sm-flex">
            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <span class="contact-details-button" *ngIf="item['organisationId']"
                        (click)="quickViewClient()"></span>
                    <span class="icon-button" style="background-color: #FFE7E7;color: #ff7e7e;"><i
                            class="bi bi-briefcase"></i></span>
                    <div>
                        <p>Klant</p>
                        <p>{{item['organisationName']}}</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>

            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <span class="contact-details-button" *ngIf="item['organisationId']"
                        (click)="quickViewContact(item['contactPersonId'])"></span>
                    <span class="contact-details-button" *ngIf="!item['organisationId']" (click)="openForm({})"></span>
                    <span class="icon-button" style="background-color: #FCE9D9;color: #f3a45e;"><i
                            class="bi bi-person-square"></i></span>
                    <div>
                        <p>Contactpersoon 1</p>
                        <p>{{item['contactPersonName']}} &nbsp;</p>
                        <p>{{item['contactPersonFunction']}}&nbsp;</p>
                    </div>
                </div>
            </div>

            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <span class="contact-details-button" *ngIf="item['organisationId']"
                        (click)="quickViewContact(item['contactPerson2Id'])"></span>
                    <span class="contact-details-button" *ngIf="!item['organisationId']" (click)="openForm({})"></span>
                    <span class="icon-button" style="background-color: #FCE9D9;color: #f3a45e;"><i
                            class="bi bi-person-square"></i></span>
                    <div>
                        <p>Contactpersoon 2</p>
                        <p>{{item['contactPerson2Name']}} &nbsp;</p>
                        <p>{{item['contactPerson2Function']}} &nbsp;</p>
                    </div>
                </div>
            </div>

            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <span class="contact-details-button" *ngIf="item['organisationId']"
                        (click)="quickViewContact(item['contactPerson3Id'])"></span>
                    <span class="contact-details-button" *ngIf="!item['organisationId']" (click)="openForm({})"></span>
                    <span class="icon-button" style="background-color: #FCE9D9;color: #f3a45e;"><i
                            class="bi bi-person-square"></i></span>
                    <div>
                        <p>Contactpersoon 3</p>
                        <p>{{item['contactPerson3Name']}} &nbsp;</p>
                        <p>{{item['contactPerson3Function']}}&nbsp;</p>
                    </div>
                </div>
            </div>


            <div class="contactDetails" style="height: fit-content; padding: 0; margin: 0;">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['siteManagerId']
                }">
                    <span class="contact-details-button" *ngIf="item['siteManagerId']"
                        (click)="quickViewSiteManager()"></span>
                    <span class="contact-details-button" *ngIf="!item['siteManagerId']"
                        (click)="openFormProject()"></span>
                    <span class="icon-button" style="background-color:#D9F3EA;color: #47C69A;"><i
                            class="bi bi-person"></i></span>
                    <div>
                        <p>Uitvoerder</p>
                        <p>{{item['siteManagerName']}} &nbsp;</p>
                        <p>{{item['siteManagerFunction']}}&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex project-contact-wrapper d-sm-none mobileContactWrapper">
            <div class="contactDetails">
                <div class="contactWrapper" (click)="quickViewClient()" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <p><i class="bi bi-briefcase"></i> {{item['organisationName']}}</p>
                </div>
            </div>

            <div class="contactDetails" *ngIf="currentUser['role'] != 'USER'">
                <div (click)="quickViewContact(item['contactPersonId'])" class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <p><i class="bi bi-person-square"></i> {{item['contactPersonName']}}</p>
                </div>
            </div>

            <div class="contactDetails" *ngIf="currentUser['role'] != 'USER' && item['contactPerson2Name'] != null">
                <div (click)="quickViewContact(item['contactPersonId'])" class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <p><i class="bi bi-person-square"></i> {{item['contactPerson2Name']}}</p>
                </div>
            </div>

            <div class="contactDetails" *ngIf="currentUser['role'] != 'USER' && item['contactPerson3Name'] != null">
                <div (click)="quickViewContact(item['contactPersonId'])" class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <p><i class="bi bi-person-square"></i> {{item['contactPerson3Name']}}</p>
                </div>
            </div>

            <div class="contactDetails" *ngIf="currentUser['role'] == 'USER'">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <p><i class="bi bi-person-square"></i> {{item['contactPersonName']}}</p>
                </div>
            </div>

            <div class="contactDetails" *ngIf="currentUser['role'] == 'USER' && item['contactPerson2Name'] != null">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <p><i class="bi bi-person-square"></i> {{item['contactPerson2Name']}}</p>
                </div>
            </div>

            <div class="contactDetails" *ngIf="currentUser['role'] == 'USER' && item['contactPerson3Name'] != null">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['organisationId']
                }">
                    <p><i class="bi bi-person-square"></i> {{item['contactPerson3Name']}}</p>
                </div>
            </div>

            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['userGroupId']
                }">
                    <span class="contact-details-button" *ngIf="item['userGroupId']"
                        (click)="quickViewUserGroup()"></span>
                    <span class="contact-details-button" *ngIf="!item['userGroupId']"
                        (click)="openFormProject()"></span>
                    <div>
                        <p *ngIf="!item['userGroupName']"><i class="bi bi-person-gear"></i> Onbekend</p>
                        <p *ngIf="item['userGroupName']"><i class="bi bi-person-gear"></i> {{item['userGroupName']}}</p>
                    </div>
                </div>
            </div>
            <div class="contactDetails">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['siteManagerId']
                }">
                    <span class="contact-details-button" *ngIf="item['siteManagerId']"
                        (click)="quickViewSiteManager()"></span>
                    <span class="contact-details-button" *ngIf="!item['siteManagerId']"
                        (click)="openFormProject()"></span>
                    <p><i class="bi bi-person"></i> {{item['siteManagerName']}}</p>
                </div>
            </div>
            <div class="contactDetails d-none d-lg-block">
                <div class="contactWrapper" [ngClass]="{
                    'is-button': item['authorId']
                }">
                    <span class="contact-details-button" *ngIf="item['authorId']" (click)="quickViewAuthor()"></span>
                    <span class="contact-details-button" *ngIf="!item['authorId']" (click)="openForm({})"></span>
                    <p *ngIf="!item['authorName']"><i class="bi bi-pencil-square"></i> Onbekend</p>
                    <p *ngIf="item['authorName']"><i class="bi bi-pencil-square"></i> {{item['authorName']}}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-3 shadow-small d-none d-sm-flex" id="subNav">
        <ul>
            <li (click)="changeTab('info')" [ngClass]="{'active' : tabs['info'] == true}">
                <i class="bi bi-info-circle"></i>
                Details
            </li>
            <li (click)="changeTab('files')" [ngClass]="{'active' : tabs['files'] == true}">
                <i class="bi bi-file-earmark-text"></i>
                Bestanden
            </li>
            <li (click)="changeTab('abc')" [ngClass]="{'active' : tabs['abc'] == true}">
                <i class="bi bi-text-paragraph" [ngClass]="{'hasNote': hasNotes == true}"></i>
                Notities
            </li>
            <li class="d-none d-sm-flex" *ngIf="currentUser['role'] == 'ADMIN'" (click)="changeTab('bestek')"
                [ngClass]="{'active' : tabs['bestek'] == true}">
                <i class="bi bi-calculator"></i>
                Calculatie
            </li>
            <li (click)="changeTab('photos')" [ngClass]="{'active' : tabs['photos'] == true}">
                <i class="bi bi-image"></i>
                Foto's
            </li>
            <li (click)="changeTab('diary')"
                *ngIf="currentUser['role'] == 'MANAGER' || currentUser['role'] == 'ADMIN' || item['canManageJournals']"
                [ngClass]="{'active' : tabs['diary'] == true}">
                <i class="bi bi-journals"></i>
                Dagboek
            </li>
            <li (click)="changeTab('hours')" class="visible-for-manager" [ngClass]="{'active' : tabs['hours'] == true}">
                <i class="bi bi-alarm"></i>
                Urenregistratie
            </li>
        </ul>
    </div>

    <div class="shadow-large d-flex d-sm-none" id="subNavMobile" [ngClass]="{'active' : mobileNav}"
        (click)="toggleMobileNav()">
        <div class="activeTab">
            <i class="bi bi-arrow-bar-up" [ngClass]="{'active' : mobileNav}"></i>
            <p *ngIf="tabs['info'] == true">Details</p>
            <p *ngIf="tabs['files'] == true">Bestanden</p>
            <p *ngIf="tabs['abc'] == true">Notities</p>
            <p *ngIf="tabs['photos'] == true">Foto's</p>
            <p *ngIf="tabs['diary'] == true">Dagboek</p>
            <p *ngIf="tabs['hours'] == true">Urenregistratie</p>
        </div>
        <div>
            <ul>
                <li style="border-bottom: 1px solid #F2F2F2;" (click)="changeTab('info')"
                    [ngClass]="{'active' : tabs['info'] == true}">
                    <i class="bi bi-info-circle"></i>
                    Details
                </li>
                <li style="border-bottom: 1px solid #F2F2F2; border-left: 1px solid #F2F2F2; border-right: 1px solid #F2F2F2;"
                    (click)="changeTab('files')" [ngClass]="{'active' : tabs['files'] == true}">
                    <i class="bi bi-file-earmark-text"></i>
                    Bestanden
                </li>
                <li style="border-bottom: 1px solid #F2F2F2;" (click)="changeTab('abc')"
                    [ngClass]="{'active' : tabs['abc'] == true}">
                    <i class="bi bi-text-paragraph"></i>
                    Notities
                </li>
                <li (click)="changeTab('photos')" [ngClass]="{'active' : tabs['photos'] == true}">
                    <i class="bi bi-image"></i>
                    Foto's
                </li>
                <li style="border-bottom: 1px solid #F2F2F2; border-left: 1px solid #F2F2F2; border-right: 1px solid #F2F2F2;"
                    (click)="changeTab('diary')"
                    *ngIf="currentUser['role'] == 'MANAGER' || currentUser['role'] == 'ADMIN' || item['canManageJournals']"
                    [ngClass]="{'active' : tabs['diary'] == true}">
                    <i class="bi bi-journals"></i>
                    Dagboek
                </li>
                <li (click)="changeTab('hours')" class="visible-for-manager"
                    [ngClass]="{'active' : tabs['hours'] == true}">
                    <i class="bi bi-alarm"></i>
                    Urenregistratie
                </li>
            </ul>
        </div>

        <!-- <ul>
            <li (click)="changeTab('info')" [ngClass]="{'active' : tabs['info'] == true}">
                <i class="bi bi-info-circle"></i>
                Details
            </li>
            <li (click)="changeTab('files')" [ngClass]="{'active' : tabs['files'] == true}">
                <i class="bi bi-file-earmark-text"></i>
                Bestanden
            </li>
            <li (click)="changeTab('abc')" [ngClass]="{'active' : tabs['abc'] == true}">
                <i class="bi bi-text-paragraph"></i>
                Notities
            </li>
            <li class="d-none d-sm-flex only-for-admin" *ngIf="currentUser['role'] == 'ADMIN'" (click)="changeTab('bestek')"
                [ngClass]="{'active' : tabs['bestek'] == true}">
                <i class="bi bi-calculator"></i>
                Calculatie
            </li>
            <li (click)="changeTab('photos')" [ngClass]="{'active' : tabs['photos'] == true}">
                <i class="bi bi-image"></i>
                Foto's
            </li>
            <li (click)="changeTab('diary')" class="visible-for-manager" [ngClass]="{'active' : tabs['diary'] == true}">
                <i class="bi bi-journals"></i>
                Dagboek
            </li>
            <li (click)="changeTab('hours')" class="visible-for-manager" [ngClass]="{'active' : tabs['hours'] == true}">
                <i class="bi bi-alarm"></i>
                Urenregistratie
            </li>
        </ul> -->
    </div>
    <section class="tab-content mb-5">

        <div *ngIf="tabs['files'] == true">
            <app-project-files-tree [project]="item" target="GENERAL"></app-project-files-tree>
        </div>

        <div *ngIf="tabs['abc'] == true">
            <app-project-notes [project]="item"></app-project-notes>
        </div>

        <div *ngIf="tabs['bestek'] == true">
            <app-calculation-items [project]="item"></app-calculation-items>
        </div>

        <div *ngIf="tabs['hours'] == true">
            <app-project-hours *ngIf="mobileViewport === false" [project]="item"></app-project-hours>

            <!-- display mobile version -->
            <app-project-hours-mobile *ngIf="mobileViewport === true" [project]="item"></app-project-hours-mobile>
        </div>

        <div *ngIf="tabs['quality'] == true">

        </div>

        <div *ngIf="tabs['diary'] == true">
            <app-project-journal [project]="item" *ngIf="mobileViewport === false"></app-project-journal>
            <app-project-journal-mobile [project]="item" *ngIf="mobileViewport === true"></app-project-journal-mobile>
        </div>

        <div *ngIf="tabs['photos'] == true">
            <app-project-files [project]="item" [isPictureTable]="true"
                [fileType]="'projectPhotos'"></app-project-files>
        </div>

        <div class="detail" *ngIf="tabs['info'] == true" style="overflow-x: scroll;">
            <table style="overflow-x: scroll;">
                <tbody>
                    <tr>
                        <th width="300">Onderwerp</th>
                        <th>Beschrijving</th>
                        <td class="d-none d-md-block"></td>
                    </tr>
                    <tr *ngIf="currentUser['role'] == 'ADMIN'">
                        <td>Offerte</td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] == 'ADMIN'">
                            <div class="cursor-pointer d-inline-block quote-status-button"
                                (click)="openAcceptedStatus ($event, item)">
                                <span class="badge badge-xl statusFlag gray"
                                    *ngIf="item['quoteAccepted'] == ''">n.v.t.</span>
                                <span class="badge badge-xl statusFlag blue"
                                    *ngIf="item['quoteAccepted'] == 'OPEN'">Open</span>
                                <span class="badge badge-xl statusFlag green"
                                    *ngIf="item['quoteAccepted'] == 'ACCEPTED'">Geaccepteerd</span>
                                <span class="badge badge-xl statusFlag red"
                                    *ngIf="item['quoteAccepted'] == 'REJECTED'">Niet geaccepteerd</span>
                            </div>
                            <button class="btn btn-light ms-2 px-2" style="margin-top:-2px;background:#E8E8E8;"
                                (click)="showMails()" *ngIf="item['quoteMailCount'] > 0"><i
                                    class="bi bi-envelope-at"></i> {{item['quoteMailCount']}}</button>
                        </td>
                        <td class="d-block d-md-none">
                            <div class="cursor-pointer d-inline-block quote-status-button">
                                <span class="badge badge-xl statusFlag gray"
                                    *ngIf="item['quoteAccepted'] == ''">n.v.t.</span>
                                <span class="badge badge-xl statusFlag blue"
                                    *ngIf="item['quoteAccepted'] == 'OPEN'">Open</span>
                                <span class="badge badge-xl statusFlag green"
                                    *ngIf="item['quoteAccepted'] == 'ACCEPTED'">Geaccepteerd</span>
                                <span class="badge badge-xl statusFlag red"
                                    *ngIf="item['quoteAccepted'] == 'REJECTED'">Niet geaccepteerd</span>
                            </div>
                            <app-project-files [mobile]="true" class="hiddenButton" [project]="item" type="quick"
                                (reloadAction)="reload($event)" [reload]="refresh" target="QUOTE"></app-project-files>
                        </td>
                        <td class="d-none d-md-table-cell">
                            <app-project-files [project]="item" type="quick" (reloadAction)="reload($event)"
                                [reload]="refresh" target="QUOTE"></app-project-files>
                        </td>
                    </tr>
                    <tr>
                        <td>Werkomschrijving</td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] == 'ADMIN'"><span
                                [innerHTML]="item['werkomschrijvingStatusLabel']" class="cursor-pointer"
                                (click)="editPageProjectPermitStatus(item, 'Werkomschrijving')"></span></td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] != 'ADMIN'"><span
                                [innerHTML]="item['werkomschrijvingStatusLabel']"></span>
                        </td>
                        <td class="d-block d-md-none">
                            <div [innerHTML]="item['werkomschrijvingStatusLabel']"></div>
                            <app-project-files class="hiddenButton" [mobile]="true" [project]="item" type="quick"
                                (reloadAction)="reload($event)" [reload]="refresh"
                                target="Werkomschrijving"></app-project-files>
                        </td>
                        <td class="d-none d-md-table-cell">
                            <app-project-files [project]="item" type="quick" (reloadAction)="reload($event)"
                                [reload]="refresh" target="Werkomschrijving"></app-project-files>
                        </td>
                    </tr>
                    <tr>
                        <td>Werktekening</td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] == 'ADMIN'"><span
                                class="cursor-pointer" [innerHTML]="item['werktekeningStatusLabel']"
                                (click)="editPageProjectPermitStatus(item, 'WERKTEKENING')"></span></td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] != 'ADMIN'"><span
                                [innerHTML]="item['werktekeningStatusLabel']"></span></td>
                        <td class="d-block d-md-none">
                            <div [innerHTML]="item['werktekeningStatusLabel']"></div>
                            <app-project-files [mobile]="true" class="hiddenButton" [project]="item" type="quick"
                                (reloadAction)="reload($event)" [reload]="refresh"
                                target="WERKTEKENING"></app-project-files>
                        </td>
                        <td class="d-none d-md-table-cell">
                            <app-project-files [project]="item" type="quick" (reloadAction)="reload($event)"
                                [reload]="refresh" target="WERKTEKENING"></app-project-files>
                        </td>
                    </tr>
                    <tr>
                        <td>KLIC</td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] == 'ADMIN'"><span
                                class="cursor-pointer" [innerHTML]="item['klicStatusLabel']"
                                (click)="editPageProjectPermitStatus(item, 'KLIC')"></span>
                            <button class="btn btn-light" (click)="editKlicNote()"
                                [ngClass]="{'grayed': item.klicNote == null}"><i
                                    class="bi bi-card-text me-1"></i><span>Notitie</span></button>
                        </td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] != 'ADMIN'"><span
                                [innerHTML]="item['klicStatusLabel']"></span><button class="btn btn-light"
                                [ngClass]="{'grayed': item.klicNote == null}" (click)="editKlicNote()"><i
                                    class="bi bi-card-text me-1"></i><span>Notitie</span></button>
                        </td>
                        <td class="d-block d-md-none">
                            <div [innerHTML]="item['klicStatusLabel']"></div>
                            <app-project-files [mobile]="true" class="hiddenButton" [project]="item" type="quick"
                                (reloadAction)="reload($event)" [reload]="refresh"
                                target="KLIC"></app-project-files><button class="btn btn-light"
                                [ngClass]="{'grayed': item.klicNote == null}" (click)="editKlicNote()"><i
                                    class="bi bi-card-text me-1"></i><span>Notitie</span></button>
                        </td>
                        <td class="d-none d-md-table-cell">
                            <app-project-files [project]="item" type="quick" (reloadAction)="reload($event)"
                                [reload]="refresh" target="KLIC"></app-project-files>
                        </td>
                    </tr>
                    <tr>
                        <td>LIS</td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] == 'ADMIN'"><span
                                class="cursor-pointer" [innerHTML]="item['lisStatusLabel']"
                                (click)="editPageProjectPermitStatus(item, 'LIS')"></span></td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] != 'ADMIN'"><span
                                [innerHTML]="item['lisStatusLabel']"></span>
                        </td>
                        <td class="d-block d-md-none">
                            <div [innerHTML]="item['lisStatusLabel']"></div>
                            <app-project-files [mobile]="true" class="hiddenButton" [project]="item" type="quick"
                                (reloadAction)="reload($event)" [reload]="refresh" target="LIS"></app-project-files>
                        </td>
                        <td class="d-none d-md-table-cell">
                            <app-project-files [project]="item" type="quick" (reloadAction)="reload($event)"
                                [reload]="refresh" target="LIS"></app-project-files>
                        </td>
                    </tr>
                    <tr>
                        <td>Verkeersplan</td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] == 'ADMIN'"><span
                                class="cursor-pointer" [innerHTML]="item['verkeersplanStatusLabel']"
                                (click)="editPageProjectPermitStatus(item, 'VERKEERSPLAN')"></span></td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] != 'ADMIN'"><span
                                [innerHTML]="item['verkeersplanStatusLabel']"></span></td>
                        <td class="d-block d-md-none">
                            <div [innerHTML]="item['verkeersplanStatusLabel']"></div>
                            <app-project-files [mobile]="true" class="hiddenButton" [project]="item" type="quick"
                                (reloadAction)="reload($event)" [reload]="refresh"
                                target="VERKEERSPLAN"></app-project-files>
                        </td>
                        <td class="d-none d-md-table-cell">
                            <app-project-files [project]="item" type="quick" (reloadAction)="reload($event)"
                                [reload]="refresh" target="VERKEERSPLAN"></app-project-files>
                        </td>
                    </tr>
                    <tr>
                        <td>TVM</td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] == 'ADMIN'"><span
                                [innerHTML]="item['tvmStatusLabel']" class="cursor-pointer"
                                (click)="editPageProjectPermitStatus(item, 'TVM')"></span></td>
                        <td class="d-none d-md-block" *ngIf="currentUser['role'] != 'ADMIN'"><span
                                [innerHTML]="item['tvmStatusLabel']"></span>
                        </td>
                        <td class="d-block d-md-none">
                            <div [innerHTML]="item['tvmStatusLabel']"></div>
                            <app-project-files [mobile]="true" class="hiddenButton" [project]="item" type="quick"
                                (reloadAction)="reload($event)" [reload]="refresh" target="TVM"></app-project-files>
                        </td>
                        <td class="d-none d-md-table-cell">
                            <app-project-files [project]="item" type="quick" (reloadAction)="reload($event)"
                                [reload]="refresh" target="TVM"></app-project-files>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</section>