import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter, ViewChild, Renderer2 } from '@angular/core';
import { AddFilterComponent } from '../../components/table-guru/modals/add-filter/add-filter.component';
import { EditFilterComponent } from '../../components/table-guru/modals/edit-filter/edit-filter.component';
import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { AppService } from 'src/app/services/app.service';

import { ProjectInfoFormComponent } from 'src/app/components/projects/project-info-form/project-info-form.component';
import { ProjectInfoFilesComponent } from 'src/app/components/projects/project-info-files/project-info-files.component';
import { CalendarPdfComponent } from './calendar-pdf/calendar-pdf.component';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'calendar-index',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;

  link = 'calendar';
  refresh = 0;
  keyword = '';
  editPath = 'calendar';
  active;
  ganttMonth = "2023-02-13";

  filterOptions = [];
  filters = [];
  path = '';
  limit = 5000;

  startWeek;
  endWeek;

  startDate;
  endDate;

  loading = false;

  ganttFourWeekDates = [];
  ganttHeadList = [];
  ganttDayList = [];

  projects;
  pendingProjects;
  projectStatus = ['QUOTE_ACCEPTED', 'PLAN', 'IN_PROGRESS'];
  userGroups;
  ganttData;
  daysOff = [];
  today;
  rollbackData: any = null;

  numberOfWeeks = 6;
  lastUrl = '';

  sortKey = 'userGroupName';
  sortDirection = 'ASC';

  showDropDown = false;

  isManager = false;
  currentUser = null;

  public calendarSwitch = false;


  constructor(
    public apiService: ApiConnectionService,
    private appService: AppService,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private ngxLoader: NgxUiLoaderService,
    private authenticationService: AuthenticationService
  ) {
    const currentDate = new Date();

    // Get the current year, month, and day
    const year = currentDate.getFullYear();
    // Note: In JavaScript, the month is zero-based, so we need to add 1 to get the correct month
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    // Combine the year, month, and day using the desired format (yyyy-mm-dd)
    this.today = `${year}-${month}-${day}`;


    this.setPageDetails();
    // this.fetchGanttData(this.ganttMonth);

    this.renderer.listen('window', 'click', (evt) => {
      let self = this;
      if (!evt.target.classList.contains('dd-sort')) {
        self.showDropDown = false;
      }
    });

    const self = this;

    this.authenticationService.getCurrentUser();
    this.authenticationService.data.subscribe(data => {
      if (Object.keys(data).length) {
        self.currentUser = data;
        if (self.currentUser.role == "MANAGER") {
          this.isManager = true;
        }
      }
    });
  }

  toggleDropdown() {
    this.showDropDown = !this.showDropDown;
  }

  public switchCalendar() {
    this.calendarSwitch = !this.calendarSwitch;
  }

  public fetchGanttData(e) {

    if (e != null) {
      e = new Date(e);
    }

    this.ganttFourWeekDates = this.getNextFourWeekDates(e);
    this.getNextFourWeeks(e);
    this.ganttDayList = this.getNextFourWeekDays(e);

    let dt1 = new Date(this.ganttMonth);

    let monthStr = (dt1.getMonth() + 1);
    let mnthPrefix = '';
    if (monthStr < 10) {
      mnthPrefix = '0';
    }

    this.startWeek = dt1.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + dt1.getDate();
    this.startDate = dt1.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + (dt1.getDate() < 10 ? '0' : '') + dt1.getDate();

    let dt2 = new Date(this.ganttMonth);
    dt2.setDate(dt2.getDate() + 6);

    monthStr = (dt2.getMonth() + 1);
    mnthPrefix = '';
    if (monthStr < 10) {
      mnthPrefix = '0';
    }

    this.endWeek = dt2.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + dt2.getDate();
    this.endDate = dt2.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + (dt2.getDate() < 10 ? '0' : '') + dt2.getDate();

    localStorage.setItem("calendarStartDate", this.startDate);
    localStorage.setItem("calendarEndDate", this.endDate);
  }

  ngOnInit(): void {
    this.getDaysOff();
    this.getProjects();
    this.currentDate();
  }

  getDaysOff() {
    const self = this;

    this.apiService.read("specialDates/list").then((dates: any) => {
      self.daysOff = dates;
    })
  }

  isHoliday(date) {
    if(this.daysOff.includes(date)) {
      return true;
    } else {
      return false;
    }
  }

  getProjects() {
    let filterStorageItem = localStorage.getItem('filters');
    let filterStorage = {};
    if (filterStorageItem) {
      filterStorage = JSON.parse(filterStorageItem);
    }

    let url = "projects";
    url += '&data[limit]=' + this.limit;
    url += '&data[sort]=' + this.sortKey;
    url += '&data[direction]=' + this.sortDirection;
    url += '&origin=calendar'

    let filterOption;
    if (this.keyword.length > 1) {
      url += '&data[keyword]=' + this.keyword;
    }

    let filterOptions = [];
    filterOptions.push('data[filters][status][values]' + 'PLAN||QUOTE_ACCEPTED||IN_PROGRESS');

    if (this.filters && this.filters.length > 0) {
      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i].values) {
          filterOptions.push('data[filters][' + this.filters[i].key + '][values]=' + encodeURIComponent(this.filters[i].values.join('||')) + '');
        }
      }
      filterOption = filterOptions.join('&');
      // url += '&' + filterOption;

      /*
      * TODO: storage function
      */
      filterStorage[this.path] = { filters: filterOption };

      localStorage.setItem('filters', JSON.stringify(filterStorage));
    }
    else if (filterStorage[this.path]) {
      let values = filterStorage[this.path]['filters'];
      filterOption = values;
    }

    let self = this;
    self.loading = true;

    self.lastUrl = url;
    this.apiService.save(url, filterOption).then((response) => {
      self.loading = false;


      self.limit = response['limit'];
      self.filterOptions = response['filterOptions'];

      if (self.keyword.length < 1) {
        self.keyword = response['keyword'];
      }

      // if (self.filters.length < 1)
      // {
      self.filters = response['filters'];
      // }

      self.sortKey = response['sort'];
      self.sortDirection = response['direction'];


      //code
      let projects = [];
      let items = response['items'];
      for (let x = 0; x < items.length; x++) {
        let project = items[x];
        project['calendarStartAt'] = project['startAt'];
        if (project['endAt']) {
          let startAt = project['startAt'].replaceAll('-', '');
          let endAt = project['endAt'].replaceAll('-', '');
          let startDate = self.startDate.replaceAll('-', '');
          let endDate = self.endDate.replaceAll('-', '');
          if (startAt <= startDate && endAt >= startDate) {
            project['calendarStartAt'] = self.startDate;
          }
        }
        projects.push(project);
      }
      self.projects = projects;
      self.pendingProjects = projects;
      self.loading = false;
    }).catch(function (error) {
    });













  }

  public getNextFourWeeks(startDate) {
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const result = [];

    for (let i = 0; i < this.numberOfWeeks; i++) {
      const endDate = new Date(startDate.getTime() + (oneDay * 6)); // add 6 days to get end date
      const startMonth = startDate.toLocaleString('default', { month: 'short' }); // get short month name
      const endMonth = endDate.toLocaleString('default', { month: 'short' });
      const startDay = startDate.getDate();
      const endDay = endDate.getDate();
      const weekString = `${startDay} ${startMonth} - ${endDay} ${endMonth}`; // create week string
      result.push(weekString);
      startDate = new Date(endDate.getTime() + oneDay); // set start date to the day after end date
    }

    this.ganttHeadList = result;
  }

  public getNextFourWeekDays(startDate) {
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const result = [];

    for (let i = 0; i < (this.numberOfWeeks * 7); i++) {
      const currentDate = new Date(startDate.getTime() + (oneDay * i)); // add i days to get current date
      result.push(currentDate.getDate());
    }

    return result;
  }

  public getNextFourWeekDates(startDate) {
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const result = [];

    for (let i = 0; i < (this.numberOfWeeks * 7); i++) {
      const currentDate = new Date(startDate.getTime() + (oneDay * i)); // add i days to get current date
      result.push(currentDate.toISOString().slice(0, 10));
    }

    return result;
  }

  addedEntries = [];
  addedEntriesDate = [];

  public isDateBetween(dateToCheck, startDate, endDate, id) {
    const checkDate = new Date(dateToCheck);
    const start = new Date(startDate);
    const end = new Date(endDate);

    return checkDate >= start && checkDate <= end;
  }

  private setPageDetails() {
    this.appService.setPageTitle('Planning');
    this.appService.setNavKey('calendar');
  }

  public getDayOfDate(date) {
    const day = new Date(date).getDate();
    return day;
  }

  private dateDiffInDays(date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);

    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in a day
    const diffInTime = date2.getTime() - date1.getTime(); // difference in milliseconds
    const diffInDays = Math.floor(diffInTime / oneDay); // convert to days and round down
    return diffInDays;
  }

  public calculateGanttEntryWidth(startDate, endDate) {
    const projectLength = this.dateDiffInDays(startDate, endDate) + 1;
    const slotWidth = document.querySelector<HTMLElement>(".ganttSlot").offsetWidth;
    return projectLength * slotWidth;
  }

  dropPending(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pendingProjects, event.previousIndex, event.currentIndex);
    let positions = [];

    for (let i = 0; i < this.pendingProjects.length; i++) {
      positions.push({
        index: (i + 1),
        projectId: this.pendingProjects[i]['id']
      });
    }

    let self = this;
    self.appService.setLoaderStatus(true);

    self.apiService.save('projects/savePositions', {
      items: positions
    }).then(function () {
      self.appService.setLoaderStatus(false);
      self.appService.setSystemMessageLoading(true);
      self.appService.setSystemMessageType('SUCCESS');
      self.appService.setSystemMessageText('Volgorde opgeslagen');
    });
    return;
  }

  rollbackChange() {
    const self = this;

    if (this.rollbackData != null) {

      this.loading = true;
      self.appService.setLoaderStatus(true);
      self.apiService.save('projects/changeDate/' + self.rollbackData['id'] + '', {
        start: self.rollbackData['index']
      }).then(function () {
        self.appService.setLoaderStatus(false);
        self.appService.setSystemMessageLoading(true);
        self.appService.setSystemMessageType('SUCCESS');
        self.appService.setSystemMessageText('Datum opgeslagen');
        self.getProjects();
        self.rollbackData = null;
        this.loading = false;
      }).catch(function () {
        self.getProjects();
      });
    }
  }

  drop(event: CdkDragDrop<string[]>, entry) {
    this.rollbackData = {
      id: event.item.data.id,
      index: this.ganttFourWeekDates[event.previousIndex]
    }

    let date = this.ganttFourWeekDates[event.currentIndex];
    let self = this;

    this.loading = true;

    self.appService.setLoaderStatus(true);
    self.apiService.save('projects/changeDate/' + event.item.data.id + '', {
      start: date
    }).then(function () {
      self.appService.setLoaderStatus(false);
      self.appService.setSystemMessageLoading(true);
      self.appService.setSystemMessageType('SUCCESS');
      self.appService.setSystemMessageText('Datum opgeslagen');
      self.getProjects();
    }).catch(function () {
      self.getProjects();
    });
  }

  currentDate() {
    var curr = new Date;
    var first = curr.getDate() - curr.getDay() + 1;
    var last = first + 6;

    let calendarStartDate = localStorage.getItem("calendarStartDate");
    let calendarEndDate = localStorage.getItem("calendarEndDate");

    if (calendarStartDate) {
      this.ganttMonth = calendarStartDate;
      var firstday = new Date(this.ganttMonth);
      this.fetchGanttData(firstday);
      return;
    }

    var firstday = new Date(curr.setDate(first));
    var lastday = new Date(curr.setDate(last)).toUTCString();

    let monthStr = (firstday.getMonth() + 1);
    let mnthPrefix = '';
    if (monthStr < 10) {
      mnthPrefix = '0';
    }
    this.ganttMonth = firstday.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + (firstday.getDate() < 10 ? '0' : '') + firstday.getDate();
    this.fetchGanttData(firstday);

    return firstday;
  }

  downloadPdf() {

    let self = this;

    const filterModal = this.modalService.open(CalendarPdfComponent, {
      size: 'lg'
    });

    filterModal.componentInstance.url = this.lastUrl;
    filterModal.componentInstance.startWeek = this.startWeek;
    filterModal.componentInstance.endWeek = this.endWeek;

    filterModal.result.then((result) => {
      self.getProjects();
    })
      .catch((error) => {
      });
  }

  openForm(id) {
    let self = this;

    const filterModal = this.modalService.open(ProjectInfoFormComponent, {
      windowClass: 'modal-right',
      size: 'lg'
    });

    filterModal.componentInstance.id = id;
    filterModal.componentInstance.currentTab = 'status';
    filterModal.componentInstance.fullLink = true;

    filterModal.result.then((result) => {
      self.getProjects();
    })
      .catch((error) => {
      });
  }

  printCalendar() {

  }

  changeSort(key, direction) {
    this.sortKey = key;
    this.sortDirection = direction;
    this.getProjects();
    this.showDropDown = false;
    return false;
  }

  isOffDay(dayVal) {
    const date = new Date(dayVal);
    const day = date.getDay();

    let offDay = false;
    if (day === 0 || day === 6 || this.daysOff.includes(dayVal)) {
      offDay = true;
    }

    return offDay;
  }

  next() {
    let dt1 = new Date(this.ganttMonth);
    dt1.setDate((dt1.getDate() + (1 * 7)));

    let monthStr = (dt1.getMonth() + 1);
    let mnthPrefix = '';
    if (monthStr < 10) {
      mnthPrefix = '0';
    }


    let day = dt1.getDate();
    let dayStr = day.toString();
    if (dt1.getDate() < 10) {
      dayStr = '0' + dayStr;
    }

    this.ganttMonth = dt1.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + dayStr;
    this.fetchGanttData(dt1);
    this.getProjects();
  }

  prev() {
    let dt1 = new Date(this.ganttMonth);
    dt1.setDate((dt1.getDate() - (1 * 7)));

    let monthStr = (dt1.getMonth() + 1);
    let mnthPrefix = '';
    if (monthStr < 10) {
      mnthPrefix = '0';
    }

    let day = dt1.getDate();
    let dayStr = day.toString();
    if (dt1.getDate() < 10) {
      dayStr = '0' + dayStr;
    }

    this.ganttMonth = dt1.getFullYear() + '-' + mnthPrefix + '' + monthStr.toString() + '-' + dayStr;
    this.fetchGanttData(dt1);
    this.getProjects();
  }

  dateString(week, year) {
    var curr = new Date; // get current date

    let y = year;
    var d = new Date("Jan 01, " + y + " 01:00:00");
    var dayMs = (24 * 60 * 60 * 1000);
    var offSetTimeStart = dayMs * (d.getDay() - 1);
    var w = d.getTime() + 604800000 * (week - 1) - offSetTimeStart; //reducing the offset here
    var n1 = new Date(w);
    var n2 = new Date(w + 518400000);

    let day = (n1.getDate() < 10 ? '0' + n1.getDate().toString() : n1.getDate().toString());
    let month = n1.getMonth();
    month = (month + 1);
    let monthStr = (month < 10 ? '0' + month.toString() : month.toString());

    var firstday = day + '-' + monthStr + '-' + n1.getFullYear();
    return firstday;
  }

  addFilter() {
    let self = this;

    const filterModal = this.modalService.open(AddFilterComponent);
    filterModal.componentInstance.filterOptions = self.filterOptions;
    filterModal.result.then((result) => {
      if (result && result.length > 0) {
        self.editFilter(result);
      }
    })
      .catch((error) => {
      });
  }

  editFilter(filterKey) {
    let self = this;
    const filterModal = this.modalService.open(EditFilterComponent);
    filterModal.componentInstance.filterOptions = self.filterOptions;
    filterModal.componentInstance.filters = self.filters;
    filterModal.componentInstance.activeFilterKey = filterKey;

    filterModal.result.then((result) => {

      if (result && result['action'] === "remove") {
        this.removeFilter(result['key']);
      }
      else if (result) {
        self.pushFilter(result);
        self.getProjects();
      }
    })
      .catch((error) => {
      });
  }

  removeFilter(key) {
    let self = this;
    if (this.filters) {
      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];
        if (filter['key'] === key) {
          this.filters.splice(i, 1);
        }
      }
    }
    /*
    * TODO: handle in storage function
    */
    if (this.filters.length === 0) {
      let filterStorageItem = localStorage.getItem('filters');
      let filterStorage = {};
      if (filterStorageItem) {
        filterStorage = JSON.parse(filterStorageItem);
      }

      filterStorage[this.path] = {};

      localStorage.setItem('filters', JSON.stringify(filterStorage));
    }
    self.getProjects();
  }

  getCalendarUrl() {
    let self = this;
    let url = this.lastUrl;
    return url.replace('projects', 'calendarPdf') + '&startWeek=' + self.startWeek + '&' + 'endWeek=' + self.endWeek + '&';
  }

  pushFilter(searchValues) {
    let key = searchValues['key'];
    let values = searchValues['values'];

    if (this.filters) {
      let filterOptions = [];
      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];
        if (filter['key'] === key) {
          this.filters[i] = {
            key: key,
            values: values,
          };

          return true;
        }
      }
    }
    this.filters.push({
      key: key,
      values: values,
    });
    return true;
  }
}