import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UsersService } from '../../services/users.service';
import { CollectionManagerService } from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';

import { AppService } from 'src/app/services/app.service';
import { UserEditModalComponent } from 'src/app/components/users/user-edit-modal/user-edit-modal.component';
import { UserGroupsIndexComponent } from '../user-groups-index/user-groups-index.component';

import { CategoriesIndexComponent } from '../categories-index/categories-index.component';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
	selector: 'app-users-index',
	templateUrl: './users-index.component.html',
	styleUrls: ['./users-index.component.scss']
})

export class UsersIndexComponent implements OnInit {
	organisations: any = [];
	refresh: any = 0;
	users: any = [];
	link = 'users';
	editPath = 'users/form/';
	mapping = [
		{ key: 'name', label: 'Naam', sort: true },
		{ key: 'companyName', label: 'Bedrijf', sort: true },
		{ key: 'function', label: 'Functie', sort: true },
		{ key: 'email', label: 'E-mail', sort: true },
		{ key: 'roleText', label: 'Rol', sort: true },
		{ key: 'groupLabels', label: 'Ploegen', sort: true },
		{ key: 'lastLoginText', label: 'Laatst ingelogd op', sort: true },
		{ key: 'activeLabel', label: 'Actief', sort: true }
	];

	currentUser;

	constructor(
		public usersService: UsersService,
		public collectionManager: CollectionManagerService,
		private modalService: NgbModal,
		private appService: AppService,
		private authenticationService: AuthenticationService,
		private ngxLoader: NgxUiLoaderService,
	) {
		let self = this;
		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
			if (Object.keys(data).length) {
				self.currentUser = data;
			} else {
				self.currentUser = false;
			}
		});
	}

	ngOnInit(): void {
		this.all();

		this.appService.setPageTitle('Medewerkers');
		this.appService.setNavKey('users');
	}

	editRow(item) {
		this.openForm({ id: item['id'] });
	}

	deleteRow(item) {
		this.confirmDelete(item);
	}

	all() {

	}

	delete(user) {
		let self = this;
		self.appService.setLoaderStatus(true);
		this.usersService.delete('users/delete/' + user.id + '').then((response) => {
			self.appService.setLoaderStatus(false);

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_DELETED');
			self.appService.setSystemMessageType('DANGER');

			self.refresh = Math.random();
		}).catch(function (error) {
			self.appService.setLoaderStatus(false);

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
		});
	}

	confirmDelete(user) {
		let self = this;

		if (self.currentUser['role'] != 'ADMIN' && user['role'] == 'ADMIN') {
			alert('Je hebt geen rechten om deze gebruiker te verwijderen');
			return;
		}

		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je ' + user.name + ' wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				this.delete(user);
			}
		});
	}

	openGroups() {
		let self = this;

		const filterModal = this.modalService.open(UserGroupsIndexComponent, {
			windowClass: 'modal-right',
			backdrop: 'static',
			size: 'lg'
		});

		filterModal.result.then((result) => {
			self.all();
			self.refresh = Math.random();
		})
			.catch((error) => {
			});
	}

	openCategories() {
		let self = this;

		const filterModal = this.modalService.open(CategoriesIndexComponent, {
			windowClass: 'modal-right',
			backdrop: 'static',
			size: 'lg'
		});

		filterModal.result.then((result) => {
			self.all();
			self.refresh = Math.random();
		})
			.catch((error) => {
			});
	}

	openForm(data) {
		let self = this;

		const filterModal = this.modalService.open(UserEditModalComponent, {
			windowClass: 'modal-right',
			backdrop: 'static',
			size: 'lg'
		});

		filterModal.componentInstance.id = data['id'];
		filterModal.componentInstance.closeAfterSave = true;
		filterModal.componentInstance.organisationId = data['organisationId'];
		filterModal.componentInstance.roleKey = data['role'];
		filterModal.result.then((result) => {
			self.all();
			self.refresh = Math.random();
		})
			.catch((error) => {
			});
	}
}
