<section class="p-5" *ngIf="journalItems && journalItems.length < 1">
    <p class="text-center text-muted">Geen dagboek beschikbaar</p>
    <p class="text-center">
        <button type="button" class="btn btn-success" (click)="addWeek()">
            <i class="bi bi-plus-lg text-white me-2"></i> Week toevoegen
        </button>
    </p>
</section>
<section class="p-5" *ngIf="!journalItems">
    laden
</section>
<section class="table-grid" *ngIf="journalItems && journalItems.length > 0">
    <section class="table-content">
        <table class="table table-vertical-align-top" style="margin-bottom:0;">
            <thead class="thead-dark">
                <tr>
                    <th width="140">Week</th>
                    <th width="180">Datum</th>
                    <th>Omschrijving</th>
                    <th width="140"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let journal of journalItems">
                    <tr class="hour-row">
                        <td>
                            <i (click)="setExpended('')" class="bi bi-chevron-up icon-pointer me-3 pt-1"
                                *ngIf="expendedKey == journal['period']"></i>
                            <i (click)="setExpended(journal['period'])"
                                class="bi bi-chevron-down icon-pointer me-3 pt-1"
                                *ngIf="expendedKey !== journal['period']"></i>
                            <b>Week {{journal['week']}}</b>
                            <span class="text-danger" *ngIf="journal['hasComment']"> <i
                                    class="d-inline-block ms-1 bi bi-exclamation-diamond-fill"></i></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                            <a href="https://api-stg.kloens.app/projectJournals/pdf/{{project['id']}}/{{journal['period']}}&hash={{currentUser['publicHash']}}"
                                target="_blank" class="d-inline-block me-3"><i class="bi bi-file-earmark-pdf"></i></a>
                            <span (click)="confirmDeleteWeek(project, journal['period'])"
                                class="me-3 row-icon delete2-icon">
                                <i class="bi bi-trash-fill"></i>
                            </span>
                            <span (click)="addWeek()">
                                <i class="bi bi-plus-lg add2-icon row-icon"></i>
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let journalItem of journal['items']; let index = index; let last = last;"
                        class="user-hour-row"
                        [ngClass]="{'d-none': expendedKey !== journalItem['period'],  last: last}">
                        <td></td>
                        <td>
                            {{journalItem['dayText']}}
                            <br /><small style="display:inline-block;font-size:80%;">{{journalItem['dateText']}}</small>
                        </td>
                        <td>
                            <textarea [(ngModel)]="journalItem['description']"
                                placeholder="Omschrijving van de werkzaamheden" (blur)="updateJournal(journalItem)"
                                class="form-control"
                                style="width:100%;height: 100px;">{{journalItem['description']}}</textarea>
                            <div class="pt-2">
                                <div class="py-1 comment-label" [ngClass]="{
                                'comment-label-highlight': journalItem['comments']
                            }"><i class="bi bi-exclamation-octagon"></i>&nbsp;&nbsp;&nbsp;&nbsp;Opmerkingen (meerwerk,
                                    uitzonderingen etc ... )</div>
                                <textarea [ngClass]="{
                                'comment-textarea-highlight': journalItem['comments']
                            }" [(ngModel)]="journalItem['comments']"
                                    placeholder="Omschrijf de uitzonderlijke werkzaamheden"
                                    (blur)="updateJournal(journalItem)"
                                    class="comment-textarea form-control">{{journalItem['comments']}}</textarea>
                                <div class="mt-2">
                                    <div class="d-none">
                                        <ngx-dropzone #drop (change)="onSelect($event, journalItem['id'])" class="dropzone"></ngx-dropzone>
                                    </div>
                                    <button (click)="drop.showFileSelector()" class="btn btn-dark mt-3 mb-4">
                                        <i class="bi bi-file-earmark-arrow-up me-1"></i><span>Upload foto's</span>
                                    </button>
                                </div>

                                <div style="display: flex; flex-wrap: wrap;">
                                    <div *ngFor="let photo of journalItem['photos']" class="photoContainer">
                                        <div>
                                            <div class="actionButtons">
                                                <span (click)="showPreview(photo, journalItem['photos'])" class=""><i
                                                        class="bi bi-search"></i></span>
                                                <span
                                                    (click)="confirmDelete2(photo)" class=""><i
                                                        class="bi bi-trash"></i></span>
                                            </div>
                                            <img loading="lazy" [src]="photo['thumbUrl']"
                                                [style]="{'width': '200px', 'height': '200px', 'border-radius': '10px', 'object-fit': 'contain'}">
                                            <div>
                                                <p style="margin: 0; margin-top: 5px; font-size: 12px;">
                                                    {{photo['createdByName']}}</p>
                                                <p style="margin: 0; font-size: 12px;">{{photo['createdAtText']}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </section>
</section>