<section [ngClass]="{'loader-overlay': loaderOverlay}"
	*ngIf="currentUser['role'] != 'ADMIN' && item['role'] == 'ADMIN'">
	<div class="modal-header">
		<h5 class="modal-title">
			<span *ngIf="!item['id']"><span *ngIf="item['role'] == 'CONTACT'">Contact</span><span
					*ngIf="item['role'] != 'CONTACT'">Gebruiker</span> toevoegen</span>
			<span *ngIf="item['id']"><span *ngIf="item['role'] == 'CONTACT'">Contact</span><span
					*ngIf="item['role'] != 'CONTACT'">Gebruiker</span> wijzigen</span>
		</h5>
		<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
	</div>
	<div class="modal-body" id="user-form">
		<p class="text-muted py-5 my-5 text-center">Je hebt geen rechten om deze gebruiker te bewerken</p>
	</div>
</section>
<section [ngClass]="{'loader-overlay': loaderOverlay}"
	*ngIf="currentUser['role'] == 'ADMIN' || (currentUser['role'] != 'ADMIN' && item['role'] != 'ADMIN')">
	<div class="modal-header">
		<h5 class="modal-title">
			<span *ngIf="!item['id']"><span *ngIf="item['role'] == 'CONTACT'">Contact</span><span
					*ngIf="item['role'] != 'CONTACT'">Gebruiker</span> toevoegen</span>
			<span *ngIf="item['id']"><span *ngIf="item['role'] == 'CONTACT'">Contact</span><span
					*ngIf="item['role'] != 'CONTACT'">Gebruiker</span> wijzigen</span>
		</h5>
		<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
	</div>
	<div class="modal-body" id="user-form" *ngIf="item">
		<section class="user-form" *ngIf="item">
			<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item.id" name="id">

			<div class="form-group mb-3" [ngClass]="{'d-none': roleKey === 'CONTACT'}">
				<label for="role" class="pb-1">Rol</label>
				<select name="role" [(ngModel)]="item.role" class="form-control">
					<ng-container *ngFor="let role of roles">
						<option value="{{role.value}}" *ngIf="role.value != 'ADMIN' || currentUser['role'] == 'ADMIN'">
							{{role.name}}
						</option>
					</ng-container>
				</select>
				<div class="error" *ngIf="errors['role']">{{errors['role']}}</div>
			</div>

			<div class="row pb-3" *ngIf="item['role'] == 'MATERIAL'">
				<div class="col-12">
					<label for="siteManagerId" class="main-label">Materiaal van medewerker</label>
				</div>
				<div class="col-12">
					<div style="position:relative;">
						<input type="hidden" class="form-control" [(ngModel)]="item['parentMaterialId']"
							name="parentMaterialId">
						<div style="position:relative;">
							<input type="text" (focus)="showParentUserDD()" class="form-control dd-input"
								[ngClass]="{'dd-active': parentUserDDVisible == true}" required
								[(ngModel)]="parentUserDDKeyword" style="position:absolute;top:0;left:0;width:100%;">
							<input type="text" class="form-control" placeholder="Bijvoorbeeld: Jan Jansen"
								[(ngModel)]="item['materialParentName']">
						</div>
						<div class="auto-dropdown" *ngIf="parentUserDDVisible == true">
							<div class="options">
								<div class="option" *ngFor="let item of searchParentUsers()"
									(click)="setParentUser(item)">
									{{item['name']}}
									<br />
									<small class="text-muted">{{item['roleText']}}</small>
								</div>
							</div>
						</div>
						<div class="error" *ngIf="errors['parentMaterialId']">{{errors['parentMaterialId']}}</div>
					</div>
				</div>
			</div>

			<div class="form-group mb-3" *ngIf="item['role'] != 'MATERIAL'">
				<label for="name" class="pb-1">Aanhef</label>
				<div class="row">
					<div class="col-4 col-lg-2">
						<div class="form-check form-check-icon pb-0 mb-0 pt-2" style="height:30px;overflow:hidden;">
							<input class="form-check-input" [(ngModel)]="item['salutation']" type="radio" id="salheer"
								value="Heer">
							<label class="form-check-label" for="salheer">Heer</label>
						</div>
					</div>
					<div class="col-4 col-lg-2">
						<div class="form-check form-check-icon pb-0 mb-0 pt-2" style="height:30px;overflow:hidden;">
							<input class="form-check-input" [(ngModel)]="item['salutation']" id="salmevrouw"
								type="radio" value="Mevrouw">
							<label class="form-check-label" for="salmevrouw">Mevrouw</label>
						</div>
					</div>
				</div>

				<div class="error" *ngIf="errors['initials']">{{errors['initials']}}</div>
			</div>

			<div class="form-group mb-3" *ngIf="item['role'] != 'MATERIAL'">
				<label for="companyName" class="pb-1">Bedrijf</label>
				<input type="text" class="form-control" id="companyName" required [(ngModel)]="item.companyName"
					name="companyName">
				<div class="error" *ngIf="errors['companyName']">{{errors['companyName']}}</div>
			</div>

			<div class="form-group mb-3" *ngIf="item['role'] != 'MATERIAL'">
				<label for="name" class="pb-1">Voornaam</label>
				<input type="text" class="form-control" id="firstname" required [(ngModel)]="item.firstname"
					name="firstname">
				<div class="error" *ngIf="errors['firstname']">{{errors['firstname']}}</div>
			</div>
			<div class="form-group mb-2">
				<label for="name" class="pb-1" *ngIf="item['role'] == 'MATERIAL'">Naam</label>
				<label for="name" class="pb-1" *ngIf="item['role'] != 'MATERIAL'">Achternaam</label>
				<input type="text" class="form-control" id="lastname" required [(ngModel)]="item.lastname"
					name="lastname">
				<div class="error" *ngIf="errors['lastname']">{{errors['lastname']}}</div>
			</div>
			<div class="form-group row mb-3">
				<div class="col-12">
					<label class="col-form-label pb-1">Actief:</label>
				</div>
				<div class="col-12">
					<div class="form-check form-switch">
						<input type="checkbox" class="form-check-input" id="active" role="switch" name="active"
							[ngModelOptions]="{standalone: true}" [(ngModel)]="item.active">
						<label class="form-check-label" for="active"></label>
					</div>
				</div>
			</div>
			<div class="form-group mb-3" *ngIf="item['role'] != 'MATERIAL'">
				<label for="email" class="pb-1">E-mail</label>
				<input type="text" class="form-control" id="email" required [(ngModel)]="item.email" name="email">
				<div class="error" *ngIf="errors['email']">{{errors['email']}}</div>
			</div>
			<div class="form-group mb-3" *ngIf="item['role'] != 'MATERIAL'">
				<label for="phone" class="pb-1">Telefoon</label>
				<input type="text" class="form-control" id="phone" required [(ngModel)]="item.phone" name="phone">
				<div class="error" *ngIf="errors['phone']">{{errors['phone']}}</div>
			</div>
			<div class="form-group mb-3" *ngIf="item['role'] != 'MATERIAL'">
				<label for="function" class="pb-1">Functie</label>
				<input type="text" class="form-control" id="function" required [(ngModel)]="item.function"
					name="function">
				<div class="error" *ngIf="errors['function']">{{errors['function']}}</div>
			</div>
			<div class="form-group mb-3 d-none">
				<label for="organisationId" class="pb-1">Organisatie</label>
				<select name="organisationId" [(ngModel)]="item.organisationId" class="form-control">
					<option [selected]="item['organisationId'] == organisation['id']" value="{{organisation['id']}}"
						*ngFor="let organisation of organisations">
						{{organisation['longName']}}
					</option>
				</select>
				<div class="error" *ngIf="errors['organisationId']">{{errors['organisationId']}}</div>
			</div>
			<section *ngIf="item['role'] != 'MATERIAL' && currentUser['role'] == 'ADMIN'">
				<div class="form-group row mb-3" [ngClass]="{'d-none': item.role === 'CONTACT'}">
					<span *ngIf="!item['id']">
						<div class="col-12">
							<label class="col-form-label pb-1">Welkomstmail:</label>
						</div>
						<div class="col-10">
							<div class="form-check form-switch">
								<input [(ngModel)]="item['welcomeEmail']" type="checkbox" class="form-check-input"
									id="welcomeEmail" role="switch" name="welcomeEmail">
								<label class="form-check-label" for="welcomeEmail">Versturen</label>
							</div>
						</div>
					</span>

					<div class="col-12">
						<label class="col-form-label pb-1">Wachtwoord instellen:</label>
					</div>
					<div class="col-10">
						<div class="form-check form-switch">
							<input type="checkbox" class="form-check-input" id="changepassword" role="switch"
								name="changepassword" (change)="checkChangepassword($event)">
							<label class="form-check-label" for="changepassword">Wachtwoord instellen</label>
						</div>
					</div>
				</div>

				<div class="changepasswordContainer" *ngIf="changepassword == true">
					<div class="form-group">
						<label for="adminpassword">Uw wachtwoord</label>
						<input type="password" class="form-control" id="adminpassword" required
							[(ngModel)]="item['adminpassword']">
						<div class="error" *ngIf="errors['adminpassword']">{{errors['adminpassword']}}</div>
					</div>
					<div class="form-group">
						<label for="newpassword">Nieuw wachtwoord</label>
						<input type="password" class="form-control" id="newpassword" required
							[(ngModel)]="item['newpassword']">
						<div class="error" *ngIf="errors['newpassword']">{{errors['newpassword']}}</div>
					</div>
					<div class="form-group">
						<label for="repeatpassword">Herhaal wachtwoord</label>
						<input type="password" class="form-control" id="repeatpassword" required
							[(ngModel)]="item['repeatpassword']">
						<div class="error" *ngIf="errors['repeatpassword']">{{errors['repeatpassword']}}</div>
					</div>
				</div>
			</section>

			<div class="user-group-users pb-3" *ngIf="item['role'] != 'CONTACT' && item['role'] != 'MANAGER'">
				<div class="row">
					<div class="col-12"
						style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:15px; display: flex; justify-content: space-between;">
						<small>Ploegen</small>
					</div>
				</div>
				<div class="row" *ngFor="let group of groups">
					<div class="col-12 option-row" [ngClass]="selectedItems[group['id']] ? 'active' : ''"
						style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
						<div (click)="toggleList(group['id'])" style="cursor:pointer;">
							<i *ngIf="selectedItems[group['id']]" class="bi bi-check-square"
								style="position:relative;top:2px;margin-right:5px;"></i>
							<i *ngIf="!selectedItems[group['id']]" class="bi bi-square"
								style="position:relative;top:2px;margin-right:5px;opacity:0.5;"></i>
							{{group['name']}} <span class="text-muted">({{group['shortName']}})</span>
						</div>
					</div>
				</div>
			</div>

			<div class="user-group-users pb-3" *ngIf="item['role'] != 'CONTACT' && item['role'] != 'MANAGER'">
				<div class="row">
					<div class="col-12"
						style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:15px; display: flex; justify-content: space-between;">
						<small>Categorieën</small>
					</div>
				</div>
				<div class="row" *ngFor="let cat of categories">
					<div class="col-12 option-row" [ngClass]="selectedCategories[cat['id']] ? 'active' : ''"
						style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
						<div (click)="toggleCat(cat['id'])" style="cursor:pointer;">
							<i *ngIf="selectedCategories[cat['id']]" class="bi bi-check-square"
								style="position:relative;top:2px;margin-right:5px;"></i>
							<i *ngIf="!selectedCategories[cat['id']]" class="bi bi-square"
								style="position:relative;top:2px;margin-right:5px;opacity:0.5;"></i>
							{{cat['name']}}
						</div>
					</div>
				</div>
			</div>

			<div class="form-group mb-3" *ngIf="item['role'] == 'ADMIN'">
				<label for="autograph" class="pb-1">Handtekening</label>
				<div class="autograph" [ngStyle]="{'background-image':'url(' + autograph + ')'}"
					style="border:1px dotted #DDDDDD">
					<input type="file" (change)="readURL($event);" />
				</div>
				<div class="d-none"><textarea [(ngModel)]="item['autograph']"></textarea></div>
				<div class="pt-2" *ngIf="autograph.length > 10"><span class="cursor-pointer text-danger"
						(click)="removeAutograph()">Verwijder</span></div>
			</div>

			<div class="mb-5 pb-5"></div>
		</section>
	</div>
	<div class="modal-footer" *ngIf="item">
		<button (click)="save()" class="btn btn-success">
			<span *ngIf="!item.id"><i class="bi bi-sd-card pe-2"></i> Toevoegen</span>
			<span *ngIf="item.id"><i class="bi bi-sd-card pe-2"></i> Opslaan</span>
		</button>
	</div>
</section>